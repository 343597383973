import RestoreIcon from '@mui/icons-material/Restore';
import CircleIcon from '@mui/icons-material/Circle';
import './audit_trail.scss'

import React from 'react'

const AuditTrail = ({dataInfo}) => {

  return (
    <div className='audit-trail'>
      <div className='audit-trail-header'>
        <RestoreIcon />
        <h4>Audit Trail</h4>
      </div>
      <div className='audit-trail-page'>
        <div >
          {dataInfo.map((data, index) => {
            return (<div key={index} className='audit-trail-progress'>
             <div className='audit-trail-icon-wrapper'> <CircleIcon /></div>
              {index !== (dataInfo.length - 1) && <hr></hr>}
            </div>)
          })
          }
        </div>
        <div>
          {dataInfo.map((data, index) => {
            return (
              <div key={index}  className='audit-trail-record'>
                <h4 className='audit-trail-name'>{data.name}</h4>
                <p className='audit-trail-info'>{data.info}</p>
                <p className='audit-trail-time'>{data.time}</p>
              </div>
            )
          })
          }
        </div>
      </div>
    </div>
  )
}

export default AuditTrail