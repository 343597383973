import React, { useState } from 'react'
import CreateModel from "../Model/CreateModel";
import FlightIcon from '@mui/icons-material/Flight'
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { useGetAircraftTemplatesQuery } from '../../features/aircraft_template/aircraftApiSlice';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const options = ['5 items', '10 items', '20 items', '50 items']

const _data = [
    {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
    {name: 'Aircraft Template Manager', link: '/aircraft_template' , Icon:FlightIcon},
]

const CreateAircraftModel = () => {

   // const {aircraft: data} = useSelector(state => state.aircraft)
    const search = useLocation().search
    const params = new URLSearchParams(search);

    const { data, isSuccess, isLoading, isError } = 
        useGetAircraftTemplatesQuery({ refetchOnMountOrArgChange: true})

    // Refetch Aircraft Templates.
    const [progress, setProgress] = React.useState(0);
    const [download, setDownload] = React.useState(false)
    
    const [open2, setOpen2] = React.useState(false);
    const handleOpen = () => setOpen2(true);

    const handleClose = () => {
        setProgress(0)
        setOpen2(false)
        setDownload(false)
    }

    const [page, setPage] = React.useState(1);
    const [PerPage, setPerPage] = React.useState(10)

    /**
     *
     * @param {string} model
     * @param {string} aircraft_type
     * @param {integer} year
     * @param {string} manufacturer
     * @param {string} icad_code
     * @returns {object} - model, aircraft_type , year, manufacturer, icad_code
     */
    // Create a model aircraft type year manufacturer and icao code.
    const createData = (model, aircraft_type, year, manufacturer, icao_code, id) => {
        return { model, aircraft_type, year, manufacturer, icao_code , id};
    }

    const rows = data?.data || [];
    const [filterData, setFilterData] = useState([]);

    // if (data.length > 0) {

    //     for (let step = 0; step < data.length; step++) {

    //         //get year
    //         let data_row = data[step]

    //         let yearArr = data_row?.year_manufactured?.split("-")
    //         let len = (yearArr?.length - 1)
    //         let year = yearArr[len]


    //         let manufacturer = data_row?.manufacturer ? data_row.manufacturer : "null";
    //         let icao_code


    //         if (data_row.icao_code === null) {
    //             icao_code = "null"
    //         }

    //         else if (!data_row.icao_code) {
    //             icao_code = "null"
    //         }
    //         else icao_code = data_row.icao_code

    //         // Create a new data row.
    //         rows.push(createData(data_row?.aircraft_model, data_row?.type, year, manufacturer, icao_code,data_row?.id));
    //     }

    // }

    const info = {
        h1: "Create New Aircraft Model Data",
        p1: 'Add new data model to the aircraft template',
        span1: 'Create Aircraft Model Data',
        span2: 'import Aircraft Model Data',
        title: 'Aircraft Template Manager',
        h2: 'Aircraft Model Data',
        icon: FlightIcon
    }

    const { h1, p1, span1, span2, title, h2, icon } = info
    const spread = { h1, p1, span1, span2, title, h2, icon }

    const doFilter = (query) => {
        if (query) {
            const result = rows.filter((item) => { 
              return item.aircraft_model.toLowerCase().includes(query.toLowerCase()) 
            })
            setFilterData(result)
        }
        else {
            setFilterData([])
        }
    }

    // model, aircraft_type, year, manufacturer, icad_code
    const headings = ['Model', 'Aircraft Type', 'Year', 'Manufacturer', 'ICAO code']
    
    const exports = { open2, options, PerPage, setPage, setPerPage, doFilter,
        rows: filterData.length ? filterData : rows, handleClose, handleOpen, setOpen2, page, 
        setDownload, download, progress, setProgress }

    return (
        <>
            <IconBreadcrumbs data={_data}/>
            <CreateModel {...exports} {...spread} headings={headings} />
        </>
    )
}

export default CreateAircraftModel