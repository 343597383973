import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({open, onClose, onAction, isOk = false, title, message}) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { minWidth: '450px' }} }>

        <DialogTitle id="alert-dialog-title">
          <h2 className='font-bold text-center'>
            {title || 'Deactivate Accident?'}
          </h2>
        </DialogTitle>

        <DialogContent className=''>
          <DialogContentText id="alert-dialog-description" className='px-10 text-center'>
            {message || 'Are you sure you want to procceed?'}
          </DialogContentText>
        </DialogContent>

        <div className='text-center p-3'>
          <button className={'p-3 px-10 border-sky-600 border-solid border text-sky-600 mr-4'} onClick={() => onClose()}>No</button>
          
          <button className={(isOk ? 'bg-primary' : 'bg-red-600') + ' p-3 px-10 text-white'}
           onClick={() => onAction(true)} autoFocus>
            Yes
          </button>

        </div>
      </Dialog>
    </div>
  );
}

export default AlertDialog;