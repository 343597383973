import { Outlet } from "react-router-dom"
import { Navbar, Sidebar } from "../../components"
import './dashboard.scss'
// import { useState } from "react";
import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';


export const Dashboard = () => {
    
    return (
        <>
        <div className="flex">
            <Sidebar  />
            <div className={"flex-grow bg-gray-100"}>
                <Navbar />
                {/* <main>  <Outlet /> </main> */}
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    {/* <Toolbar /> */}
                    <Container disableGutters maxWidth="xl" sx={{}} className="">
                        <Outlet />
                    </Container>


                </Box>
            </div>
        </div>
        </>
    )
}