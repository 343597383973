import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Box } from '@mui/material'
import Search from '../../../components/search/Search'
import './listrole.scss'

import PageTitle from '../../../components/pagetitle/PageTitle'
import { Link } from 'react-router-dom'

import UserInfoWrapper from '../../../components/UserDashboardWrapper/UserInfoWrapper'
import { useState } from 'react'
import usePagination from '../../../utils/Pagination'
import LongMenu from '../../../components/menu/LongMenu'
import { Stack,  } from '@mui/material'
import Pagination  from '@mui/material/Pagination';
import {useSelector} from 'react-redux'
import PeopleAlt from '@mui/icons-material/PersonOutline'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setSearchResults } from '../../../features/user/userSlice'
import { Add } from '@mui/icons-material'
import { selectRoles, useGetRolesQuery } from '../../../features/identity/identityApiSlice'
import UsersAndRolesDataList from '../../../components/widgets/UsersAndRolesDataList'
import Paginate from '../../../components/Paginate/Paginate'

const options = ['5 items', '10 items', '20 items', '50 items']

const data = [
  {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
  {name: 'User Management', link: '/users' , Icon:PeopleAltOutlinedIcon},
  {name:"Roles Profiles", link: '/users/all' , Icon:PeopleAlt},

]
const ListRoles = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  //const {permissions:roles} = useSelector(state => state.user)

  const [PerPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)

  const { isLoading: rolesLoading } = useGetRolesQuery()
  const roles = useSelector(selectRoles);

  // Calculates the number of data per page.
  const count = Math.ceil(roles?.length / parseInt(PerPage));

  //  initialize usePagination, passing in data to process and how many perpage
  let _data = usePagination(roles, PerPage);

  //  set the page number
  const handleChange = (e, value) => {
      setPage(value);
      _data.jump(value);
  };

  const [query , setQuery] = useState(searchParams.get('search') || '')
  const _export = { options, setValue: setPerPage, handleChange , arrLength: roles?.length };

  const handleSearch = (e) => {
    setQuery(e.target.value)
  }

  useEffect(() => {

    let q = query.replaceAll(/\s/g,"")
    const filtered = _data?.currentData()?.filter(item =>{
      //check for item.id in allUsersGroup, if it exists, return the group name
      return item.role.toLowerCase().includes(q.toLowerCase())
    })

    //delay dispatch to allow searchParams to update
    let timeout = setTimeout(() => {
        dispatch(setSearchResults(filtered))
    }, 2000);

    return () => clearTimeout(timeout)
  } , [_data, query, dispatch])


  return (
    <>

    <div className='px-3 lg:px-10'>

      {/* Page header */}
      <IconBreadcrumbs data={data} />
      <div className='flex justify-between items-center mb-5'>
        <PageTitle
            icon={<FolderOutlinedIcon />}
            pagename='Roles Profiles'/>

            <Link to='/users/roles/new'>
              <button className='btn-black-outline-two'>
                <Add />
              </button>
          </Link>
      </div>

      {/* List header */}
      <div className='header bg-black flex justify-end md:justify-between p-4 pl-6 text-white items-center rounded-t'>
       <div className='hidden md:block'>
        <Search placeholder='Search for role' handleChange={handleSearch} value={query} />
       </div>

        <div className='flex text-white items-center gap-3'>
          <p className='mb-0'>
            {`${_data.begin + 1} - ${parseInt(_data.end)} of ${roles?.length}`}
          </p>
          <LongMenu className={'text-white'} {..._export} />
        </div>
      </div>

      {/* Content */}
      <UsersAndRolesDataList type={"role"} data={_data} cells={['Role', 'Users', 'Status']} />

      {/* <UserInfoWrapper type="role" data={_data} name="Roles" middle="Users" status="Status"  /> */}
       
        {/* Pagination */}
        <div className='bg-white'>
          <hr className='mx-4' />
          <Paginate count={count} page={page} onChange={handleChange} />
        </div>
    </div>


    <div className='listroles hidden'>
      <IconBreadcrumbs data={data} />
      <div className='titlecontainer hidden'>
        <PageTitle
          icon={<FolderOutlinedIcon className='roleicon' />}
          pagename='Roles Profiles'/>
        <Link to='/users/role/new'>
          <button className='btn-black-outline-two'>Create New Role</button>
        </Link>
      </div>

      <div className='content'>
        <Box sx={{ width: '100%' }}>
          <div className='listusers'>
            <div className='title role'>
              <div >
                <Search placeholder='Search for role' handleChange={handleSearch} value={query} />
              </div>
              <div className='second' >
                 {/* dynamic data */}
                 <p>{`${_data.begin + 1} - ${parseInt(_data.end)} of ${roles?.length}`}</p>
                            <LongMenu {..._export} />
              </div>
            </div>

            {/* <UserInfoWrapper type="role" data={_data} name="Roles" middle="Users" status="Status"  /> */}
          </div>
        </Box>
      </div>

      <Stack spacing={3} direction={'column'} sx={{ padding: '0' }}>
                            <Pagination count={count} page={page} onChange={handleChange}
                                sx={{
                                    '& .css-1ddq0ly-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected ': {
                                        borderBlockEnd: '2px solid #0138a1',
                                        background: 'transparent',
                                        borderRadius: 0,
                                        fontSize: '16px'
                                    },
                                    background: '#fff',
                                    alignSelf: 'flex-end'
                                }} />
                        </Stack>
    </div>
    </>
  )
}

export default ListRoles
