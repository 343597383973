import React from 'react'


/**
 *
 * @param {object} props
 * @returns {jsx}
 */
const UncontrolledTextArea = ({ label, name, value, handleChange,  placeholder, dialog , Register ,isDirty, defaultValue }) => {


    return (
        <div className='password-input form title'>

            <label htmlFor={label} className='form-label'>
                {label}
            </label>

            <div className={`form-input pass-textarea`}
            // if input is dirty, set a different style to the border
             style={{ border: isDirty ? '2px solid #0F62FE':'', borderBlockEnd: dialog ? '1px solid #0F62FE':''}} >
                <textarea
                  maxLength={name==="phone_number" ? 14 :''}
                    value={value}
                    onChange={handleChange}
                    className={'form-input' + ' ' }
                    name={name}
                    {...Register(name)}
                    placeholder={placeholder}
                    defaultValue={defaultValue}


                >
                </textarea>

            </div>
        </div>
    )
}

export default UncontrolledTextArea