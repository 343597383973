import React, { useState, useContext, useEffect } from "react";
import DeactivateDialog from "../Dialog/DataSettings/DeactivateDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch} from "react-redux";
import Message from "./Message";
import APISettingsDescription from "./API/APISettingsDescription";
import { TabPanelContext } from "./TabPanel";
import { Stack, Pagination, Skeleton, IconButton} from "@mui/material";
import { EditAction, DeleteAction } from "../Actions";
import { useActivateDatasetQuery, useLoadDataQuery } from "../../features/data/occurenceApiSlice";
import usePagination from "../../utils/Pagination";
import AlertDialog from "../Dialog/AlertDialog";
import BasicDialog from "../Dialog/BasicDialog";
import OccurenceDataForm from "./OccurenceDataForm";
import { handleError } from "../../utils/error-handler";
import { useForm } from "react-hook-form";
import Input from "../forms/PassWordInput";
import { useCreateAirlabMutation, useUpdateAirlabMutation } from "../../features/data/apiDataApiSlice";
import Paginate from "../Paginate/Paginate";

// For API Dataset
const APIDataView = ({models, type, enabled = false}) => {

  const [updateAirlab, {}] = useUpdateAirlabMutation();
  const [createAirlab, {}] = useCreateAirlabMutation();

  const model = models?.length ? models[0] : null;
  const form = useForm({defaultValues: {
    api_key: '',
    data_offset: 0,
    data_per_page: 0,
  }})

  form.setValue('api_key', model?.api_key);
  form.setValue('data_offset', model?.data_offset);
  form.setValue('data_per_page', model?.data_per_page);

  const update = async (data) => {
    try {
      let response;
      if(type == 'Airlabs') {
        response = await updateAirlab({id: model.id, ...data}).unwrap()
      }

      if(type == 'Aviation stack') {
        response = await updateAirlab({id: model.id, ...data}).unwrap() 
      }

      if(response) toast.success(response.message)
    } 
    catch (error) {
      handleError(error)  
    }
  }

  const create = async (data) => {
    try {
      let response;
      if(type == 'Airlabs') {
        response = await createAirlab(data).unwrap()
      }

      if(type == 'Aviation stack') {
        response = await createAirlab(data).unwrap()
      }

      if(response) toast.success(response.message)
    } 
    catch (error) {
      handleError(error) 
    }
  }

  const submit = async (data) => {
    if(model) {
      update(data)
    }
    else {
      create(data)
    }
  }

  return (
    <>
    <div className="w-full mb-5">
      <div className="">
        <form onSubmit={form.handleSubmit(submit)}>
          <div className="mb-4">
            <Input
              isDirty={form.getValues('api_key')}
              label='API Key' type='text'
              name='api_key' disabled={enabled}
              placeholder='API Key' register={form.register} borderBottom={true}
            />
          </div>

          {
            type == 'Aviation stack' && <>
              <div className="mb-4">
                <Input
                  isDirty={form.getValues('data_per_page')}
                  label='Data per page'
                  type='number'
                  name='data_per_page'
                  disabled={enabled}
                  placeholder='Data Per Page'
                  register={form.register}
                  borderBottom={true}
                />
              </div>

              <div className="mb-14">
                <Input
                  isDirty={form.getValues('data_offset')}
                  label='Data offset'
                  type='number'
                  name='data_offset'
                  disabled={enabled}
                  placeholder='Data offset'
                  register={form.register}
                  borderBottom={true}
                />
              </div>
            </>
          }

          <button className="p-3 bg-sky-500 text-white rounded" disabled={enabled}>Save</button>
        </form>
       
      </div>
    </div>
    </>
  )
}

// Other Dataset
const DataSetView = ({model, handleEdit, handleDelete}) => {
  return (
    <div className="mb-4 flex gap-4 items-center border-b py-3" key={model.id}>
      <div className="grow">
        <h1 className="font-bold mb-3 text-black">{model.name}</h1>
        <p>{model.description}</p>
      </div>
      <div className="flex gap-4 mb-3">
        <EditAction handleClick={() => handleEdit(model)} />
        {model.is_active && <DeleteAction handleClick={() => handleDelete(model)} />}
      </div>
    </div>
  )
}

const OccurenceTypeList = () => {
  
  // Get TabPanel Context
  const {
    data, count, page,
    handleChange, _data, refresh } = useContext(TabPanelContext);

  const [skip, setSkip] = useState(true);
  const [statusUrl, setStatusUrl] = useState('');

  const {data: statusResponse, error} = useActivateDatasetQuery(statusUrl, {skip})
  if(statusResponse) {
    refresh();
    // toast.success(statusResponse.message);
  }

  // Raw data
  const { active, url, dataset, toggleAviation, 
      toggleAirlabs} = useSelector((state) => state.dataset);

  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    //if(error) handleError(error)
  }, [active])

  const handleEdit = (item) => {
    setEdit(true)
    setSelected(item)
  }

  const handleDelete = (item) => {
    setConfirm(true)
    setSelected(item)
  }

  const onClose = () => {
    setEdit(false)
    setConfirm(false)
  }

  const confirmAction = (action) => {
    setConfirm(false);
    if(action) {
      setStatusUrl(`${url}/${selected.id}/deactivate`);
      setSkip(false);
    }
  }

  const isApiData = () => {
    return dataset == 'API Data' || 
      ['Airlabs', 'Aviation stack'].includes(active);
  }

  return (
    <>
    <div className="py-4">

      {
        isApiData()
          ? <APIDataView models={data} type={active} 
            enabled={active == 'Airlabs' ? toggleAirlabs : toggleAviation}></APIDataView>

          : _data?.currentData()?.map((item, index) => {
            return <DataSetView model={item} handleEdit={handleEdit} handleDelete={handleDelete} key={index}></DataSetView>
          })
      }

      {
        (!isApiData() && data?.length) && 
          <Paginate count={count} page={page} onChange={handleChange} />
      }
    </div>

    {/* Empty data */}
    {
      data?.length == 0 
        && (<Message
          title={"No Data Set"}
          description="Click on the create button to create a data set or import data set."
        />)
    }

    {/* Edit Dialog */}
    <BasicDialog open={edit} onClose={onClose}>
      <OccurenceDataForm 
        model={selected} onCancel={onClose} url={url} refresh={refresh} />
    </BasicDialog>

    {/* Alert dialog */}
    <AlertDialog open={confirm} onClose={onClose} onAction={confirmAction}/>

    </>
  )
};

export default OccurenceTypeList;
