import {
    Box,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import IconBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { HeaderContainer } from "../../components/header/Header.style";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { selectPrivileges, selectRoles, useGetRolesQuery, useLoadPrivilegesQuery } from "../../features/identity/identityApiSlice";
import { useSelector } from "react-redux";
import { SmallImage } from '../../components/images/SmallImage.styled';
import { AiFillEye, AiOutlineHome } from 'react-icons/ai'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { PeopleAltOutlined } from '@mui/icons-material'
import { FiEye } from 'react-icons/fi';
import { TableBodySkeleton } from '../../components/skeleton/Skeleton';

/**
 * @constant
 * @name data
 * @description This data will populate the breadcrumbs
 * @type {[{name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: string}]}
 */
const data = [
    { name: 'Dashboard', link: '/home', Icon: AiOutlineHome },
    { name: 'Identity Management', link: '/users', Icon: PeopleAltOutlined },
]

export const IdentityIndex = () => {

    const navigate = useNavigate();

    const { isLoading: rolesLoading } = useGetRolesQuery()
    const roles = useSelector(selectRoles);

    const { isLoading: privilegeLoading } = useLoadPrivilegesQuery();
    const privileges = useSelector(selectPrivileges);

    const openRole = (model) => {
        navigate(`/identities/roles/${model.id}`);
    }

    const openPrivilege = (model) => {
        navigate(`/identities/privileges/${model.id}`);
    }

    return (
        <>
            <div className='px-3 lg:px-5'>
                <IconBreadcrumbs data={data} />
                <HeaderContainer>
                    <PeopleAltOutlined className='createusericon' />
                    <h2>Identity Management</h2>
                </HeaderContainer>

                <div className='lg:flex gap-3'>

                    {/* Privileges  */}
                    <Box className='lg:w-2/4 mb-4'>

                        {/* Header  */}
                        <div className='header p-6 bg-primary rounded mb-4'>

                            <div className='md:flex justify-between mb-5'>
                                <div className='text-white mb-4'>
                                    <h2 className='font-bold text-white'>Create New Privilege</h2>
                                    <p>Add new privilege to your system</p>
                                </div>

                                <div className='flex gap-2 text-white'>
                                    {
                                        privilegeLoading
                                            ? <Skeleton width={50} height={30} sx={{ background: "#fff" }} />
                                            : <GroupOutlinedIcon />}

                                    <div className='count'>
                                        {
                                            privilegeLoading
                                                ? <Skeleton variant="circular" sx={{ background: "#fff" }} height={40} width={40} />
                                                : privileges?.length}
                                    </div>

                                    <div className='name'>
                                        {
                                            privilegeLoading
                                                ? <Skeleton width={50} height={30} sx={{ background: "#fff" }} />
                                                : "Privileges"
                                        }
                                    </div>
                                </div>
                            </div>

                            <Link to='/identities/privileges/new'>
                                <button className='btn bg-white text-black'>
                                    Add New <span>+</span>
                                </button>
                            </Link>
                        </div>

                        {/* Data  */}
                        <div className='flex justify-between items-center bg-primary p-6'>
                            <div className='mb-0 text-white'>
                                <GroupOutlinedIcon /> <span>Privileges</span>
                            </div>

                            <Link to='/identities/privileges'>
                                {privilegeLoading ?
                                    <Skeleton variant="text" sx={{ background: "#fff" }} width={80} height={40} />
                                    : <button className='btn-primary small'>View More</button>}
                            </Link>
                        </div>

                        <TableContainer component={Paper} sx={{ minHeight: "344px", display: "grid", paddingBlockEnd: '30px' }}>
                            <Table sx={{}} aria-label='simple table'>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align='right'>Action</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        privilegeLoading
                                            ? <TableBodySkeleton user={true} />
                                            : privileges?.slice(0, 5).map((item) => {
                                                return (
                                                    <TableRow key={item.id} href={`/users/profile/${item.id}`}>
                                                        <TableCell>
                                                            <h4>{item.priviledge}</h4>
                                                        </TableCell>

                                                        <TableCell align='right'>
                                                            <FiEye className='ml-auto cursor-pointer' onClick={() => openPrivilege(item)} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* Roles  */}
                    <Box className='lg:w-2/4 mb-4'>

                        {/* Header  */}
                        <div className='header p-6 bg-black rounded mb-4'>

                            <div className='md:flex justify-between mb-5'>
                                <div className='text-white mb-4'>
                                    <h2 className='font-bold text-white'>Create New Role</h2>
                                    <p>Add new role to your system</p>
                                </div>

                                <div className='flex gap-2 text-white'>
                                    {
                                        rolesLoading
                                            ? <Skeleton width={50} height={30} sx={{ background: "gray" }} /> : <FolderOutlinedIcon />}

                                    <div className='count'>
                                        {rolesLoading
                                            ? <Skeleton variant="circular" sx={{ background: "gray" }} height={40} width={40} /> : roles?.length}
                                    </div>

                                    <div className='name'>
                                        {
                                            rolesLoading ? <Skeleton width={50} height={30} sx={{ background: "gray" }} /> : "Roles"}
                                    </div>
                                </div>
                            </div>

                            <Link to='/identities/roles/new'>
                                <button className='btn bg-white text-black'>
                                    Add New <span>+</span>
                                </button>
                            </Link>
                        </div>

                        {/* Data */}
                        <div className='flex justify-between items-center bg-primary p-6'>
                            <div className='mb-0 text-white'>
                                <FolderOutlinedIcon /> <span>Roles</span>
                            </div>

                            <Link to='/identities/roles'>
                                {rolesLoading ? <Skeleton variant="text" sx={{ background: "#fff" }} width={80} height={40} /> :
                                    <button className='btn-primary-black small'>View More</button>}
                            </Link>
                        </div>

                        <TableContainer component={Paper} sx={{ minHeight: "344px", display: "grid", paddingBlockEnd: '30px' }} >
                            <Table sx={{}} aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align='right'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rolesLoading
                                            ? <TableBodySkeleton user={false} />
                                            : roles?.slice(0, 5).map((model, index) =>
                                                <TableRow component={'a'}>
                                                    <TableCell>
                                                        <h4> {model.role}</h4>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <FiEye className='ml-auto cursor-pointer' onClick={() => openRole(model)} />
                                                    </TableCell>
                                                </TableRow>)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>


                </div>
            </div>

            <ToastContainer />
        </>
    )
}