import React from 'react'
import './styles/index.scss'
import Input from '../forms/PassWordInput'
import FormDropdown from '../forms/FormDropdown'
import { useForm, FormProvider } from 'react-hook-form'
import UploadImage from '../uploadphoto/UploadImage'
import './styles/aircraft_form.scss'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import FlightIcon from '@mui/icons-material/Flight'
import { usePostTemplateMutation } from '../../features/aircraft_template/aircraftApiSlice'
import { useNavigate } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css"
import {toast , ToastContainer} from 'react-toastify'
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { HeaderContainer } from '../header/Header.style'
import ButtonContainer from '../ButtonContainer/ButtonContainer'
import { handleError } from '../../utils/error-handler'
const _data = [
    {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
    {name: 'Aircraft Template Manager', link: '/aircraft-template' , Icon:FlightIcon},
    {name: "Create Aircraft Model Data", link: '/' , Icon:""},
  ]
/**
 * mock data
 * @type {object}
 */
const lists_data = {
    // manufacturer_company_full_name: "Manufacturers' Company Full Name",
    manufacturer: "Manufacturer",
    aircraft_model: 'Model',
    icao_code: 'ICAO Code',
    year_manufactured: 'Year',
    type: ['Land Plane', 'Cargo Plane', 'Private Jet'],
    engine_type: ['Turbojet Engine', 'Gama Engine', 'Apha Engine'],
    engine_count: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    iata: 'IATA Code',
    manufacturer_line: "Manufacturer Line Number",
    // registration_number: 'Registration Number',
    wave_turbulence_category: ['H(Heavy)', 'M(Medium)', 'L(Light)'],
    description: 'Description'
}

const listKeys = Object.keys(lists_data) //returns an array of lists_data keys

/**
Aircraft creation form
@function
@return {object } jsx
 */
const AircraftCreationForm = () => {

    const navigate = useNavigate()

    //initialize post
    const [postTemplate, {isLoading} ] = usePostTemplateMutation()

    // 
    const initialValues = {
        // manufacturer_company_full_name: "",
        // manufacturer_company_name: "",
        aircraft_model: '',
        icao_code: '',
        year_manufactured: '',
        type: '',
        engine_type: '',
        engine_count: '',
        iata: '',
        registration_number: '',
        wtc: '',
        description: '',
        model_3d_image: '',
        manufacturer: '',
    }

    // form
    const methods = useForm({
        defaultValues: initialValues
    });

    // destructure some methods from useForm hook
    const { register, formState: { errors, dirtyFields }, handleSubmit, setValue } = methods

    // watch for errors and return a boolean for a given input
    const checkErrors = (errs, name) => {
        const errors = Object.keys(errs)
        return errors.find(er => name === er)
    }

    /**
        on Submit function
        @function onSubmit - passed inside handleSubmit() function as a parameter
        @param {object} data - Returned data after validations
     */

    const onSubmit = async (data) => {

        const form = new FormData()
        const initial = Object.keys(initialValues)

        //append the first element of data.model_3d_image array to form data
        form.append('model_3d_image', data.model_3d_image[0])

        // append the remaining values
        initial.forEach(item => {
            form.append(item, data[item])
        })

        try {
            const response = await postTemplate(form).unwrap() 
            toast.success(response.message);
            setTimeout(() => { navigate(-1) }, 5000)
        } 
        catch (error) {
            handleError(error)
        }
    } 

    return (
        <>
        <div className='px-3 lg:px-10'>
            <IconBreadcrumbs data={_data} />
            <HeaderContainer>
                <FlightIcon />
                <h2>Create Aircraft Model Data</h2>
            </HeaderContainer>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='md:flex gap-6 mb-4 bg-white p-5'>
                        {/* Text Data */}
                        <div className='lg:w-4/6 mb-4'>
                            {listKeys.map(
                                (type, index) => {
                                    return typeof lists_data[type] !== 'object' 
                                    ? // check if list_data type is not array object
                                        <div key={index} className='form-and-input error mb-4'>
                                            <Input
                                                isDirty={dirtyFields[type]}
                                                label={lists_data[type]}
                                                type='text'
                                                name={type}
                                                placeholder={`${
                                                    type === "aircraft_model" ? "Aircraft Model Number" :
                                                    type === "year_manufactured" ? "Aircraft Manufactured year" :
                                                `Aircraft ${lists_data[type]}` }`}
                                                register={register}
                                                error={checkErrors(errors, type)}
                                            />
                                            {
                                                errors ? errors[type] ? <p className='error'>This field is required</p> : '' : ''
                                            }
                                        </div>
                                        :
                                        <div className='form-and-input error mb-4' key={index}>
                                            <FormDropdown

                                                isDirty={dirtyFields[type]}
                                                //use type as label; if name is combined with an underscore ("_")
                                                //e.g type = "engine_count"
                                                //split "engine_count"and map the array = engine count
                                                label={type === "wave_turbulence_category" ? "Wave Turbulence Category (WTC)":
                                                type === "type" ? "Aircraft Type" :
                                                type.replaceAll('_', " ")}

                                                type='text'
                                                name={type === "wave_turbulence_category" ? "wtc" : type}
                                                placeholder='Choose an option'
                                                register={register}
                                                error={checkErrors(errors, type)}
                                                getValue={setValue}
                                                data={lists_data[type]}

                                            />
                                            {
                                                errors ? errors[type] ? <p className='error'>This field is required</p> : '' : ''
                                            }
                                        </div>
                                }
                            )}
                        </div>

                        {/* Model */}
                        <div className='lg:w-2/6 mb-4 ml-auto'>
                            <h4 className='font-bold'>3D Aircraft Model</h4>

                            <UploadImage accept={{ "image/*": ['.png', '.jpg'] }}
                                name="model_3d_image"
                                label="File Upload"
                                className='items-start w-full'
                                icon={InsertPhotoOutlinedIcon}
                                text={'Upload 3D Aircraft Model'}
                            />
                            
                            {
                                errors?.photo && <p className='error'>Please upload a photo</p>
                            }
                        </div>
                    </div>

                    <ButtonContainer noShadow={true}>
                        <button className='bg-primary text-white px-8 py-4' type='submit'>
                            {isLoading ? "Creating..." : "Create" }
                        </button>

                        <button className='bg-error text-white px-8 py-4' type='button' onClick={() => navigate(-1)}>Cancel</button>
                    </ButtonContainer>
                </form>
            </FormProvider>
        </div>

        <ToastContainer />
        </>
    )
}

export default AircraftCreationForm