import { useForm } from "react-hook-form";
import UncontrolledTextArea from "../forms/UncontrolledTextArea";
import Input from "../forms/PassWordInput";
import { useActivateDatasetQuery, usePostDataSetMutation, useUpdateOccurrenceDataSetMutation } from "../../features/data/occurenceApiSlice";
import { toast } from "react-toastify";
import { handleError } from "../../utils/error-handler";
import { useEffect, useState } from "react";

const OccurenceDataForm = ({model, onCancel, url, refresh}) => {

    const { register, handleSubmit, 
        formState: { dirtyFields }} = useForm()

    const [skip, setSkip] = useState(true);
    const [loading, setLoading] = useState(false);

    const [createDataSet, {}] = usePostDataSetMutation()
    const [updateDataSet, {}] = useUpdateOccurrenceDataSetMutation()
    const {data: activate, isLoading, error} = useActivateDatasetQuery(`${url}/${model?.id}/activate`, {skip})

    const submit = async (data) => {
        try {
            setLoading(true)
            let to = model ? `${url}/${model.id}` : `${url}`
            let body = {url: to, ...data}
            let response = model 
                ? await updateDataSet(body).unwrap() : await createDataSet(body).unwrap()
           toast.success(response.message);
        }
        catch(e) {
            onCancel()
            handleError(e)
        }
        finally {
            onCancel()
            setLoading(false)
        }
    }

    const doActivate = (e) => {
        setSkip(false);
        setLoading(true);
    }

    useEffect(() => {

        if(error) {
            setLoading(false)
            handleError(error);
        }

        if(activate) {
            onCancel();
            if(refresh) { refresh() }
            // toast.success(activate.message)
        }
    }, [activate, error])

    return (
        <>
            <div className='create-type'>
                <h4 className='create-type-heading'>
                    {model ? 'Edit Data' : 'Create New Data Type'} 
                </h4>
                <div>
                    <form onSubmit={handleSubmit(submit)}>

                        <div className='form-control'>
                            <div className='create-type-title_menu'>
                                <Input placeholder='Enter a title'
                                    name='name'
                                    defaultValue={model?.name}
                                    register={register}
                                    isDirty={dirtyFields.name}
                                    label='Title'
                                    disabled={model ? !model.is_active : false}
                                />
                            </div>

                            <div className='create-type-title_menu'>
                                <UncontrolledTextArea
                                    placeholder='Enter a description here'
                                    name='description'
                                    Register={register}
                                    isDirty={dirtyFields.description}
                                    label='Description (optional)'
                                    defaultValue={model?.description}
                                    disabled={model ? !model.is_active : false}
                                />
                            </div>
                        </div>
                        
                        <div className='create-type-action'>
                            {
                                model?.is_active != false &&
                                <button className='cta-btn-open bg-primary py-3' type='submit' disabled={loading}>
                                    {loading ? 'Submitting' : 'Submit'}
                                </button>
                            }

                            {
                                model?.is_active == false &&
                                <button className='cta-btn-open bg-primary py-3' type='button' onClick={doActivate}  disabled={loading}>
                                    {loading ? 'Submitting' : 'Activate'}
                                </button>
                            }

                            <button className='cta-btn-close' type='button' onClick={onCancel} disabled={loading}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}


export default OccurenceDataForm;