import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import AppThemeProvider from './themes/AppThemeProvider'
import { CssBaseline } from '@mui/material'
import  store  from './app/store'
import { Provider } from 'react-redux'
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <AppThemeProvider>
      <CssBaseline />
      <App />
    </AppThemeProvider>
  </Provider>
)
