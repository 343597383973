import { CircularProgress, Box } from '@mui/material'

const Spinner = () => {

return (

  <Box sx={{ display: 'flex', justifyContent: 'space-around', height: '70vh' , alignItems:"center" }}>
    <CircularProgress />
  </Box>
)
}

export default Spinner
