import { createSlice } from "@reduxjs/toolkit";


const datasetSlice = createSlice({
    // The name of the slice is used to generate the action types
    name: 'dataset',
    // The initial state of the slice
    initialState: {
        toggleAviation: false,
        toggleAirlabs: false,
        dataset: '',
        active: '',
        url: '',
        payload: false,
        aviationData: [],
        airlabsData: [],
        apiSettingsData: {
            aviation_api_key: "",
            data_per_page: "",
            data_offset: "",
            airlab_api_key: ""
        },
        occurrenceDataSet:{
            occurrenceType: {data:[] , loading: false ,isFetching: false , error: null},
            investigationStatus: {data:[] , loading: false ,isFetching: false , error: null},
            occurrenceCategory: {data:[] , loading: false ,isFetching: false , error: null},
            reportStatus: {data:[] , loading: false ,isFetching: false , error: null},
            highestInjuryLevel: {data:[] , loading: false ,isFetching: false , error: null},
            typeOfOperation: {data:[] , loading: false ,isFetching: false , error: null},
            flightPhase: {data:[] , loading: false ,isFetching: false , error: null},
        },
        occurrenceDataSetLoading: false,
        item : {} ,
        datasetSearchResult : [],

        // Taofeek
        selectedDataSet: {}
    },

    reducers: {
        setSelected:  (state, action) => {
            state.selectedDataSet = action.payload
        },
        // set the dataset
        setDataset: (state, action) => {
            state.dataset = action.payload
        },
        // set the active dataset
        setActive: (state, { payload }) => {
            state.active = payload
        },
        // set the url
        setUrl: (state, { payload }) => {
            state.url = payload
        },
        setToggleAviation: (state, { payload }) => {
            state.toggleAviation = payload
        },
        setToggleAirlabs: (state, { payload }) => {
            state.toggleAirlabs = payload
        },
        setApiSettings: (state, { payload }) => {
            // destructure the payload
            const { airlab_api_key, aviation_api_key, data_offset, data_per_page } = payload

            // state.apiSettingsData.airlabs_api = airlabs_api
            // state.apiSettingsData.aviation_api = aviation_api
            // state.apiSettingsData.data_offset = data_offset
            // state.apiSettingsData.data_per_page = data_per_page
            // state.apiSettingsData.airlabs_endpoint = airlabs_endpoint
            // return stat and new payload
            return { ...state, apiSettingsData: { ...state.apiSettingsData,airlab_api_key, aviation_api_key, data_offset, data_per_page } }
        },
        setOccurrenceDataSet: (state, { payload }) => {
            // set the the payload to the state and return the state
            return { ...state, occurrenceDataSet: { ...state.occurrenceDataSet, ...payload } }
        },
        setOccurrenceDataSetLoading: (state, { payload }) => {
            state.occurrenceDataSetLoading = payload
        },

        setItem : (state, { payload }) => {
            state.item = payload
        },
        setDatasetSearchResult : (state, { payload }) => {
            state.datasetSearchResult = payload
        },
        setAviationData: (state, { payload }) => {
            state.aviationData = payload
        },
        setAirlabsData: (state, { payload }) => {
            state.airlabsData = payload
        }
    }
})

export const {
    setDatasetSearchResult, setItem, setDataset, setActive, 
    setUrl, setToggleAviation,setToggleAirlabs, setApiSettings, 
    setOccurrenceDataSet, setOccurrenceDataSetLoading, setAirlabsData, 
    setAviationData, setSelected } = datasetSlice.actions
export default datasetSlice.reducer