import { Logout, NotificationsNoneOutlined, PersonAdd, Settings } from '@mui/icons-material'
import React from 'react'
import './navbar.scss'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useState } from 'react'
import { Menu, MenuItem, Stack, Skeleton, CircularProgress, Divider, Avatar, ListItemIcon, Box, Tooltip, IconButton } from '@mui/material';
import { getFromLocalStorage } from '../../utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { logOut as LOGOUT, logOut } from '../../features/authentication/authSlice';
import { setUser } from '../../features/user/userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { useLogOutMutation } from '../../features/authentication/authApiSlice';
import navA from '../../assets/A.svg'
import SearchInput from '../forms/Search/SearchInput';
import { useForm } from 'react-hook-form';
import { AiOutlineUser } from 'react-icons/ai/'
import Dropdown from './Dropdown'
import { selectProfile, useGetCurrentUserQuery } from '../../features/user/userApiSlice';
import { getProfile } from '../../utils/profile-url';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
const Navbar = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleSubmit } = useForm()

  const [anchorEl, setAnchorEl] = useState(null)
  // const [dropOpen, setDropOpen] = useState(false)

  const { isLoading } = useGetCurrentUserQuery();
  const user = useSelector(selectProfile);

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleSelect = (menu) => {
    setAnchorEl(null);

    switch (menu) {
      case 'profile':
        if (user) navigate(`/profile/${user.id}`)
        break;

      case 'logout':
        logout()
        break;

      default:
        break
    }
  }

  const logout = () => {
    dispatch(logOut());
    navigate('/');
  }

  const onSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <>
      <ToastContainer />
      <nav className='bg-white py-8 px-[64px] flex justify-end md:justify-between items-center'>

        <form onSubmit={onSubmit} className=' w-1/4 hidden md:block'>
          {/* <SearchInput placeholder='Search input text' name='search' type="submit" /> */}
          <TextField
            id="input-with-icon-textfield"
            placeholder='Seach input text'
            name='search'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="filled"
            className='w-[360px] ml-[64px] '
          />
        </form>

        <Box className='flex gap-5 items-center'>

          <div className='badge'>
            <NotificationsNoneOutlined className='text-sky-500' />
            <span className='bg-red-500 text-white'>0</span>
          </div>

          {
            user?.profile_image
              ? <img src={getProfile(user?.profile_image)} className='w-8 h-8 rounded-full' alt="Profile pic" />
              : <Avatar sx={{ width: '32px', height: '32px' }} />
          }

          <Tooltip title="Account">
            <div className='cursor-pointer' onClick={handleClick}>
              {
                open
                  ? <KeyboardArrowUpOutlinedIcon />
                  : <KeyboardArrowDownOutlinedIcon />
              }
            </div>
          </Tooltip>

        </Box>

        {
          user &&
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

            <MenuItem onClick={() => handleSelect('profile')}>
              <Avatar /> Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleSelect('logout')}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        }

      </nav>
    </>
  )
}

export default Navbar