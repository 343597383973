import React from 'react'

const Message = ({title, description}) => {
  return (
    <div className='message'>
        <h4>{title}</h4>
        <p>{description}</p>
    </div>
  )
}

export default Message