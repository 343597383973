import { createSlice  ,createAsyncThunk} from "@reduxjs/toolkit"
import { addToLocalStorage, removeFromLocalStorage,} from "../../utils/localStorage"
import  axios  from  "axios"
import { keys } from "../../utils/constants"

//reset password async thunk
const resetPassword = createAsyncThunk(
   "auth/resetPassword",
    async (body, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                "http://20.102.27.112:8000/api/user/password-reset/",
                body
            )
            return response.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)


const authSlice = createSlice({
    name: 'auth',
    initialState: { access: null , refresh:null,isLoggedIn:false},
     // Reducers are functions that determine changes to an application's state.
     // It uses the immer library to allow us to write simpler immutable updates to the state.
    reducers: {

        // login reducer
        setCredentials: (state, action) => {
            const {  access, refresh, isLoggedIn} = action.payload
            
            state.access= access
            state.refresh = refresh
            state.isLoggedIn = isLoggedIn
            addToLocalStorage(keys.isLoggedIn, true)
            addToLocalStorage(keys.access, state.access)
            addToLocalStorage(keys.refresh, state.refresh)
        },

        // logout reducer
        logOut: (state, action) => {

            state.access = ""
            state.refresh = ""
            state.isLoggedIn = false

            removeFromLocalStorage(keys.access)
            removeFromLocalStorage(keys.refresh)
            removeFromLocalStorage(keys.isLoggedIn)
        }
    },

    // extraReducers are for handling actions created by createAsyncThunk or in other slices.
    extraReducers: {
        // reset password reducer
        [resetPassword.fulfilled]: (state, action) => {
            state.message = action.payload
        },
        [resetPassword.rejected]: (state, action) => {
            state.error = action.payload
        },
    },



})

// Export actions generated by the slice so that they can be used in components
export const { setCredentials, logOut, } = authSlice.actions

// Export the reducer generated by the slice so that it can be added to the store
export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.access
export const selectIsLoggedIn = state => state.auth.isLoggedIn
