import React from 'react'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell'
import { useDispatch } from 'react-redux'
import { setAircraftById } from '../../features/aircraft_template/aircraftSlice';
import { useNavigate } from 'react-router-dom';

const TableBody = ({flight, report}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClick = (id) => {
    dispatch(setAircraftById(id))
    navigate(`/aircraft-template/${id}`);
  }

  return (
    <>
      {
      flight 
        ? 
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="strong" scope="row" className='cursor-pointer' onClick={() => handleClick(flight.id)}> 
            <h4 className='font-bold'>{flight.aircraft_model}</h4>
          </TableCell>
          <TableCell>{flight.engine_type || 'NA'}</TableCell>
          <TableCell>{flight.year_manufactured || 'NA'}</TableCell>
          <TableCell>{flight.manufacturer || 'NA'}</TableCell>
          <TableCell>{flight.icao_code || 'NA'}</TableCell>
      </TableRow> 
      
      : <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell scope="row">
            {report.report_number}
          </TableCell>
          <TableCell component="th">{report.occurrence_title}</TableCell>
          <TableCell >{report.occurrence_category}</TableCell>
          <TableCell >{report.occurrence_type}</TableCell>
          <TableCell >{report.report_status}</TableCell>
        </TableRow>

      }
    </>
  )
}

export default TableBody