import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import './DataSettings/Dialog.scss'

const BasicDialog = ({children, open, onClose}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { minHeight: 480, maxWidth: 'fit-content' } }}>
            <div className=''>
                {children}
            </div>
      </Dialog>
    </div>
  );
}

export default BasicDialog;