import React, { useState, useContext } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchBar from "./SearchBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import {setToggleAirlabs, setToggleAviation, setItem
} from "../../features/data/dataSettingsSlice";
import Linear from "../Spinner/Linear";
import { TabPanelContext } from "./TabPanel";
import LongMenu from "../menu/LongMenu";
import ImportModal from "./Modals/ImportModal";
import SwitchDataSetModals from "./SwitchDataSetModals";
import { handleError } from "../../utils/error-handler";
import { useActivateDatasetQuery, usePostDataSetMutation } from "../../features/data/occurenceApiSlice";
import BasicDialog from "../Dialog/BasicDialog";
import OccurenceDataForm from "./OccurenceDataForm";

const Toggle = ({ toggle, handleToggleClick, isLoading }) => {

  return (
    <>
      {
      !toggle 
        ? 
          <div className="toggle-one cursor-pointer" onClick={handleToggleClick}>
            <ToggleOnIcon /> {isLoading ? <Linear /> : <p>Disabled</p>} 
          </div>
        : 
          <div className="toggle-two cursor-pointer" onClick={handleToggleClick}>
            <ToggleOffIcon /> {isLoading ? <Linear /> : <p> Disable </p>}
          </div>
      }
    </>
  );
}

// Tab Panel Header
const TabPanelDescriptionHeader = () => {

  const [aUrl, setAUrl] = useState('');
  const [skipActivate, setSkipActivate] = useState(true);

  const [progress, setProgress] = useState(0);
  const [download, setDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [importOpen, setImportOpen] = useState(false);

  const { 
    active, dataset, url,
    toggleAviation, toggleAirlabs} = useSelector((state) => state.dataset);

  // Occurence
  const [createDataSet, {}] = usePostDataSetMutation()

  const handleImportOpen = () => {

    if(['cities', 'states', 'country'].includes(active.toLowerCase())) {
      toast.error("This dataset cannot be imported");
    }
    else {
      setImportOpen(true);
      setDownload(false);
      setProgress(0);
    }
  }

  const handleImportClose = (reset =() => {}) => {
    setDownload(false);
    setProgress(0);
    setImportOpen(false);
    reset({name:""})
  }

  const { _data, _export, data, 
    handleChangeSearch, importData = {} } = useContext(TabPanelContext);

  const _exp = {
    handleClose: handleImportClose,
    open: importOpen,
    download,
    setDownload,
    progress,
    setProgress,
    importData,
    name: importData?.name
  };

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const handleToggleClick = () => {
    // Access model
    // let model = data?.length ? data[0] : null;
    if (active === "Aviation stack") {
      dispatch(setToggleAviation(!toggleAviation));
    } 
    else if (active === "Airlabs") {
      dispatch(setToggleAirlabs(!toggleAirlabs));
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(setItem({}))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await createDataSet({url, ...data}).unwrap();
      toast.success(response.message);
      handleClose();
    } 
    catch (e) {
      handleError(e);
      handleClose();
      setIsLoading(false)
    }
  }

  // const funcs = {
  //   open,
  //   handleClose,
  //   handleSubmit,
  // }

  const isApiData = () => {
    return dataset == 'API Data' || 
      ['Airlabs', 'Aviation stack'].includes(active);
  }

  return (
    <>
      <div className="d-header">
        <h4 className="font-bold">{active}</h4>
        <SearchBar open={openSearch} setSearchQuery={handleChangeSearch} />
        <div className="d-right">
          {
            isApiData()
              ?
              <>
                <Toggle toggle={active == 'Airlabs' 
                  ? toggleAirlabs : toggleAviation} handleToggleClick={handleToggleClick} />
              </>
              : 
              <>
                <SearchOutlinedIcon
                  className="search-icon" onClick={() => setOpenSearch(!openSearch)} />

                {data?.length > 4 && (
                  <div className="second">
                    <p>
                      {`${_data.begin + 1} - ${parseInt(_data.end)} of ${data?.length}`}
                    </p>
                    <LongMenu {..._export} />
                  </div>
                )}

                <button className="os-btn-blue" onClick={handleClickOpen}>
                  Create
                </button>

                <button className="ds-btn-white" onClick={handleImportOpen}>
                  <span>Import</span> <FileDownloadOutlinedIcon />
                </button>
              </>
          }
        </div>
      </div>

      {/* Import Modal */}
      <ImportModal {..._exp} />

      {/* Create Dialog */}
      <BasicDialog open={open} onClose={handleClose}>
        <OccurenceDataForm 
          onCancel={handleClose} url={url} />
      </BasicDialog>
    </>
  );
}

export default TabPanelDescriptionHeader;
