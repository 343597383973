export const initialFormValues =
    {
        role_name: "",
        modules: [
            {
                module_name: "report_manager",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "content_manager",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "user_profiles",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "user_roles",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "air_template_manager",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "data_Settings",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },
            {
                module_name: "api_service_manager",
                view: false,
                create: false,
                edit: false,
                delete: false,
                print: false,
                deactivate: false,
                import: false,
                export: false,
                activate: false
            },

        // {
        //     module_name:"WorkFlowManager",
        //     can_view: false,
        //     can_add: false,
        //     can_edit: false,
        //     can_delete: false,
        //     can_print: false,
        //     can_deactivate: false,
        //     can_import: false,
        //     can_export: false

        // },
        // {
        //     module_name:"MediaLibrary",
        //     can_view: false,
        //     can_add: false,
        //     can_edit: false,
        //     can_delete: false,
        //     can_print: false,
        //     can_deactivate: false,
        //     can_import: false,
        //     can_export: false

        // }
        ]

}