//capitalize the first letter of a string and any other letter to lowercase


const capitalize = (string="") => {
    //check if the string is a multiple word string and split it into an array
    if(typeof string !== 'string') return  '';
    string = string.toLowerCase();
    if (string?.includes(' ')) {
        const stringArray = string.split(' ');
        //loop through the array and capitalize the first letter of each word
        for (let i = 0; i < stringArray.length; i++) {
            stringArray[i] = stringArray[i].toLocaleUpperCase() === stringArray[i] ? stringArray[i]
            :stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1).toLowerCase();
        }
        //join the array back into a string
        return stringArray.join(' ');
    }
    //if the string is not a multiple word string, capitalize the first letter and lowercase the rest
    return string?.toLocaleUpperCase() === string ? string :
    string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export default capitalize;


