import { FormProvider, useForm } from "react-hook-form"
import Input from "../../../components/forms/PassWordInput"
import { Checkbox, FormControlLabel } from "@mui/material"
import { ToastContainer, toast } from "react-toastify"
import IconBreadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import { useCreatePrivilegeMutation } from "../../../features/identity/identityApiSlice"
import { AiOutlineHome } from "react-icons/ai"
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useState } from "react"
import { handleError } from "../../../utils/error-handler"
import { useNavigate } from "react-router-dom"

/**
 * @constant
 * @name data
 * @description This data will populate the breadcrumbs
 * @type {[{name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: string}]}
 */
const data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'Identity Management', link: '/identities' , Icon: PeopleAltOutlinedIcon},
    // {name: "Role Profiles", link: '/users/roles' , Icon:FolderOutlinedIcon},
    {name: 'Create new privilege', link: '/identities/privileges/new' , Icon: ""},
]


const dummyActions = ['view', 'create', 'edit', 'delete', 'import', 'print', 'activate', 'deactivate']

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * @name AddPrivilege
 * @description For creating privileges
 *
 */

export const AddPrivilege = () => {

    // Form
    const form = useForm({defaultValues: {
        name: '',
        actions: []
    }})

    const {handleSubmit, register} = form;

    //
    const navigate = useNavigate()

    const [selectedActions, setSelection] = useState([]);
    const [createPrivilege, {isLoading: isCreating}] = useCreatePrivilegeMutation();

    const onSubmit = async (data) => {
        try {

            let response = await createPrivilege({
              actions: selectedActions,
              priviledge: data.name?.toUpperCase()}).unwrap();
      
            toast.success(response.message);
            setTimeout(() => { navigate(-1)}, 5000)
        } 
        catch (error) {
            handleError(error)
        }
    }

    const handleChange = (selectedIndex) => {

        const action = dummyActions[selectedIndex];
        const selected = [...selectedActions];
        const index = selected.findIndex(item => item === action);

        if(index != -1) {
            selected.splice(index, 1);
        }
        else {
            selected.push(action);
        }

        //
        setSelection(selected);
    }

    return (
        <>
        <div className='px-3 lg:px-5'>
          <IconBreadcrumbs data={data}/>
          <ToastContainer />
    
          <FormProvider {...form} >
            <form  onSubmit={handleSubmit(onSubmit)}>
    
              <div className='bg-white px-5 py-3 mb-4'>
    
                <div className='md:w-2/5 mb-6'>
                  <Input
                      name='name'
                      label= 'Privilege Title'
                      placeholder='Enter Title'
                      register={register} />
                </div>
    
                {/* <Permission permit={dummyData} /> */}
                <p>Actions</p>
                {
                  dummyActions?.map((item, index) => {
                    return <FormControlLabel key={index} onChange={() => handleChange(index)} control={<Checkbox />} label={item} />
                  })
                }
              </div>
    
              <div className='bg-white p-5 mb-4'>
                <button type='submit' className='bg-sky-500 px-5 py-3 text-white'>{isCreating ? "Submitting..." : "Create Privilege"}</button>
              </div>
            </form>
          </FormProvider>
        </div>
        </>
    )
}