import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const auditApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        loadAudit: builder.query({
            query: (type) => ({
                url: `audit/?search=${type}&page=1&size=10`,
                // headers: {superadmin: 'superadmin'}
            }),
        }),
    })
})

// selectors
const auditLoadResult = auditApiSlice.endpoints.loadAudit.select();
export const selectAudits = createSelector(auditLoadResult, (state) => state.data?.data);

// hooks
export const { useLoadAuditQuery } = auditApiSlice
