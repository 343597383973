
import BackgroundImg from '../../../assets/reset_bg.svg'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css'
import './reset.scss'
import ArgueBlue2 from '../../../assets/Logo/ArgueBlue2.svg'
import { LogoWrapper, Background, ErrorMessage } from '../login/Login'
import { useState } from 'react'
import Input from '../../../components/forms/PassWordInput'
import { useResetMutation } from '../../../features/authentication/authApiSlice'
import { handleError } from '../../../utils/error-handler'

const ForgotPassword = () => {

  const navigate = useNavigate()
  const [message, setMessage] = useState('');
  const [resetPassword, { isLoading,  }] = useResetMutation()
  const [errorState, setErrorState] = useState('') //error state

  const { register, handleSubmit, reset, formState: { dirtyFields, errors } } = useForm({
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async (e) => {
  
    setMessage('')
    setErrorState('')
    const { email } = e

    try {
      let response = await resetPassword({ email }).unwrap()
      reset({email: ''});
      setMessage(response.message);

      // setTimeout(() => { navigate("/")}, 5000)
      // navigate(`/reset-password?token=${response.data.Reset_token}`)
    } 
    catch (error) {
      let message = handleError(error);
      setErrorState(message)
    }
  }

  return (
    <>
      <ToastContainer />
      <div className='form-container reset'>

        <div className='form-image'>
          <Background src={BackgroundImg}>
          </Background>
        </div>

        <div className='form-entry'>
          <LogoWrapper>
            <img src={ArgueBlue2} alt='Argue Logo' />
          </LogoWrapper>

          <div className='wrapper'>
            <div className='form-wrapper'>
              <div className='form-wrapper2'>
                <div className='form-header mb-4'>
                  <h2>Reset Password</h2>
                  <h1 className='text-green-500'>{message}</h1>
                </div>

                <form className='form-group' onSubmit={handleSubmit(onSubmit)}>
                  <Input
                  error={errors?.email ? errors?.email:errorState}
                    label='Email Address'
                    type='email'
                    name='email'
                    register={register}
                    placeholder='Enter your email address'
                    isDirty={dirtyFields.email}
                    validate={
                      {
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        required: true
                      }
                    }
                  />
                  {errorState && <ErrorMessage >{errorState}</ErrorMessage>}

                  <div className="login-action">
                    <button className='btn-blue' type='submit' disabled={isLoading}>
                      {isLoading ? "Submitting...." : "Continue"}
                    </button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword