import { toast } from "react-toastify";

export const handleError = (error) => {
    if(!error) return;
    let errData = error?.data;
    let message = errData?.message;
    let validationError = errData?.data;
    
    // Validation Error
    if (validationError) {
      let keys = Object.keys(validationError);
      message = validationError[keys[0]][0];
    }
  
    //
    message = message || 'Operation not successful';
    toast.error(message);

    return message;
  }