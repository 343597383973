import * as React from 'react';
// import div from '@mui/material/div';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { NavLink } from 'react-router-dom';
import FlightIcon from '@mui/icons-material/Flight';
// //import workflow_management from '../../assets/workflow_management.svg'
import SchemaIcon from '@mui/icons-material/SchemaOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useSelector } from 'react-redux'
import CreditCard from '@mui/icons-material/CreditCard';
import CodeIcon from '@mui/icons-material/Code';
import { useEffect, useState } from 'react';
import { Security } from '@mui/icons-material';
import { selectProfile } from '../../features/user/userApiSlice';



function DrawerList({ open, pathName }) {

  const [roles, setRoles] = useState({});
  const [permissions, setPermissions] = useState({});

  const user = useSelector(selectProfile);


  const hasTrue = (model) => {
    if (!model) return false;

    let bool = false;
    let keys = Object.keys(model);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      // eslint-disable-next-line eqeqeq
      if (model[key] == 'true') {
        bool = true;
        break
      }
    }

    return bool;
  }

  const hasPermision = (permission) => {
    let bool = false;
    let model = permissions[permission];

    if (model) {

      if (permission === 'user_management') {

        // Check roles
        bool = hasTrue(model.user_roles);

        // Check profile
        if (!bool) {
          bool = hasTrue(model.user_profiles);
        }
      }
      else {
        bool = hasTrue(model);
      }
    }

    return bool;
  }

  useEffect(() => {
    if (user) {
      setRoles(user.roles);
      let role = Object.keys(user.roles)[0];
      setPermissions(user.roles[role])
    }
  }, [user])


  const listButtonStyle = 'flex items-center  py-4 text-black group hover:font-bold my-2 hover:cursor-pointer hover:bg-gray-100 font-pt'
  const listItemIconStyle = `transition-all min-w-[20px] group-hover:text-primary font-pt`;
  const listTextStyle = `transition-all ${open ? 'opacity-100' : 'opacity-0'} group-hover:text-primary font-pt`;
  const listIconStyle = `text-primary group-hover:text-primary`

  // hasPermision('data_Settings')


  const navLinks = [
    { id: 1, name: 'Dashboard', path: 'home', icon: <DashboardIcon className={`${pathName.includes('home') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />, permission: true, fade: false },
    { id: 2, name: 'Aircraft Template Manager', path: 'aircraft-template', icon: <FlightIcon className={`${pathName.includes('aircraft-template') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />, permission: hasPermision('air_template_manager'), fade: false },
    { id: 3, name: 'Data Settings', path: 'data-settings', icon: <SchemaIcon className={`${pathName.includes('data-settings') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />, permission: true, fade: false },
    { id: 4, name: 'User Management', path: 'users', icon: <PeopleAltOutlinedIcon className={`${pathName.includes('users') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />, permission: hasPermision('user_management'), fade: false },
    { id: 5, name: 'Identity Management', path: 'identities', icon: <Security className={`${pathName.includes('identities') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />, permission: false, fade: false },
    { id: 6, name: 'Billing CRM', path: 'billing', icon: <CreditCard className={`${pathName.includes('billing') ? 'text-primary group-hover:text-primary' : 'text-secondary group-hover:text-gray-600'}`} />, permission: true , fade: true},
    { id: 7, name: 'API Service Manager', path: 'api-service', icon: <CodeIcon className={`${pathName.includes('api-service') ? 'text-primary group-hover:text-primary' : 'text-secondary group-hover:text-gray-600'}`} />, permission: true, fade: true },
    { id: 8, name: 'Tenacy Management', path: 'tenacies', icon: <PeopleAltOutlinedIcon className={`${pathName.includes('api-service') ? 'text-primary group-hover:text-primary' : 'text-secondary group-hover:text-gray-600'}`} />, permission: false, fade: true },
  ]


  return (

    <div className='mt-[72px]' >
      {navLinks.map((link) => (
        <>
          {link.permission &&
            <NavLink to={`/${link.path}`} key={link.id} className={`${listButtonStyle} ${pathName.includes(link.path) && 'border-r-[3px] border-primary'}`}>
              <ListItemIcon className={listItemIconStyle}>
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.name} className={`${pathName.includes(link.path) ? 'text-primary' : link.fade ? 'text-secondary' : ''}  ${listTextStyle}`} />
            </NavLink>
          }
        </>
      ))}




      {/* <aside className={(isCollapsed ? 'collapsed' : '') + ' hidden md:flex flex-col justify-between p-5'}>

      <div className='nav'>

        <div className='p-3 lg:p-5 mb-14'>
          <img src={ArgueBlue} alt='aib' />
        </div>

        <NavLink to='/home' end className='link'>
          <DashboardIcon className='icon' />
          <p>Dashboard</p>
        </NavLink>

        <NavLink to="/aircraft-template" className={(!hasPermision('air_template_manager') ? 'hidden' : '') + ' link'} >
           <FlightIcon className='icon' />
           <p>Aircraft Template Manager</p>
        </NavLink>

        <NavLink to='/data-settings' className={(!hasPermision('data_Settings') ? 'hidden' : '') + ' link'}>
          <SchemaIcon className='icon' />
           <p>Data Settings</p>
        </NavLink>

        <NavLink to='/users' className={(!hasPermision('user_management') ? 'hidden' : '') + ' link'}>
          <PeopleAltOutlinedIcon />
          <p>User Management</p>
        </NavLink >

        <NavLink to='/identities' className={'hidden'}>
          <Security className='icon' />
          <p>Identity Management</p>
        </NavLink >

        <NavLink to={'/billing'} className={'text-gray-400'} onClick={(e) => e.preventDefault()}>
          <CreditCard className='icon' />
          <p>Billing CRM</p>
        </NavLink >

        <NavLink to={'/api-service'} className={'text-gray-400'} onClick={(e) => e.preventDefault()}>
          <CodeIcon className='icon' />
          <p>API Service Manager</p>
        </NavLink >

        <NavLink to='/tenacies' className={'hidden'}>
          <PeopleAltOutlinedIcon className='icon' />
          <p>Tenacy Management</p>
        </NavLink >

      </div>

      <div className='toggler flex gap-3 cursor-pointer py-3' onClick={onClick}>
        <PlayCircleOutlinedIcon  />
        <p className=''>Collapse Menu</p>
      </div>

    </aside> */}



    </div>
  )
}

export default DrawerList


