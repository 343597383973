export const keys = {
    access: 'access-token',
    refresh: 'refresh-token',
    isLoggedIn: 'is-logged-in'
}

export const tags = {
    user: 'User',
    role: 'Role',
    privilege: 'Privilege',
    dataset: 'Dataset',
    aircraft: 'Aircraft',
    profile: 'Profile'
}