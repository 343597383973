import React, { useEffect, useState } from 'react'
import { FiUser, } from 'react-icons/fi'
import { toast, ToastContainer } from 'react-toastify'
import './newuser.scss'
import UploadImage from '../../../components/uploadphoto/UploadImage'
import { useForm, FormProvider } from 'react-hook-form'
import SelectRole from '../../../components/forms/SelectRole'
import Input from '../../../components/forms/PassWordInput'
import { useRegisterUserMutation } from '../../../features/user/userApiSlice';
import { useNavigate } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import PeopleAlt from '@mui/icons-material/Person';
import generatePassword from '../../../utils/PasswordGenerator'
import { handleError } from '../../../utils/error-handler'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { HeaderContainer } from '../../../components/header/Header.style'
import UploadImageIcon from '../../../assets/uploadImage.png'
import DashboardIcon from '@mui/icons-material/Dashboard'

/**
 * @constant
 * @name initialFormValue
 * @description This is the initial form value that will be used to populate the form
 */
const initialValue = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
  role: '',
  generate_password: false,
  require_change_password: false
}

/**
 * @constant
 * @name data
 * @description This data will populate the breadcrumbs
 * @type {[{name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: string}]}
 */
const data = [
  { name: 'Dashboard', link: '/home', Icon: DashboardIcon },
  { name: 'User Management', link: '/users', Icon: PeopleAltIcon },
  { name: "User Profile", link: '/users/all', Icon: PeopleAlt },
  { name: 'Create new user', link: '/newuser', Icon: "" },
]

/**
 * @returns {JSX.Element}
 * @constructor
 * @name NewUser
 * @description This component is responsible for creating a new user
 * @example <NewUser />
 * @see {@link https://reactjs.org/docs/components-and-props.html|React Components}
 *
 */

const NewUser = () => {

  /**
   * @constant
   * @name registerUser
   * @description This is the mutation that will be used to register a new user
   * @type {function}
   *
   */
  const [registerUser, { isLoading, }] = useRegisterUserMutation()
  const [removePassword, setRemovePassword] = React.useState(false)

  /**
   * @constant
   * @name navigate
   * @description This is the navigate function that will be used to navigate to a different page
   */
  const navigate = useNavigate()
  const [noImage, setNoImage] = useState(false)

  /**
   * @constant
   * @name methods
   * @description This is the methods that will be used to control the form
   * @type {object}
   */
  const methods = useForm({
    defaultValues: initialValue
  });

  const { watch, register, formState: { errors, dirtyFields }, handleSubmit, setValue, } = methods
  const watchProfile = watch('profile_image');

  /**
   * @constant
   * @name onSubmit
   * @description This is the function that will be called when the form is submitted
   * @param {object} data
   * @param {object} e
   * @returns {Promise<void>}
   * @example onSubmit(data)
   */

  const onSubmit = async (data) => {

    if (!data.profile_image) {
      setNoImage(true)
      return
    }
    else setNoImage(false)

    //if the user is not auto generating the password, generate a random password
    let password = removePassword
      ? generatePassword() : data.password

    const form = new FormData()

    const image = data.profile_image[0]
    const roles = data.role || {};
    form.append('email', data.email)
    form.append('password', password)
    form.append('profile_image', image)
    form.append('first_name', data.first_name)
    form.append('last_name', data.last_name)
    form.append('roles', JSON.stringify(roles))
    form.append('phone_number', data.phone_number)
    form.append('generate_password', removePassword)
    form.append('require_change_password', data.change_password_on_first_signin)

    try {
      const response = await registerUser(form).unwrap()
      if (response) {
        methods.reset()
        toast.success(response.message)
        setTimeout(() => { navigate(`/users`) }, 2000)
      }
    }
    catch (error) {
      handleError(error)
    }
  }

  const checkErrors = (errs, name) => {
    const errors = Object.keys(errs)
    return errors.find(er => name === er)
  }

  const onCancel = () => navigate(-1)

  useEffect(() => {
    if (watchProfile) setNoImage(false)
  }, [watchProfile])

  return (
    <>
      <div className='px-3 lg:px-10'>

        <IconBreadcrumbs data={data} />
        <HeaderContainer>
          <PersonAddOutlinedIcon className='w-6 h-6' />
          <h2>Create New User</h2>
        </HeaderContainer>

        <FormProvider  {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className='mt-4'>
            <div className='md:flex gap-5 mb-4'>

              {/* Information */}
              <div className='lg:w-3/5 bg-white px-6 pt-8 pb-[70px] mb-4 rounded shadow-sm shadow-white'>

                {/* Name */}
                <div className='row md:flex gap-5'>
                  <div className='input-and-error mb-4 flex-1'>
                    <Input
                      isDirty={dirtyFields.first_name}
                      label='First Name'
                      type='text'
                      name='first_name'
                      placeholder='Enter First Name'
                      register={register}
                      error={checkErrors(errors, 'first_name')}
                      validate={
                        {
                          pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                          minLength: 3,
                          maxLength: 20
                        }
                      } />

                    {errors?.first_name?.required && <p>First Name is required</p>}
                    {errors?.first_name && <p>Please input a valid name</p>}
                  </div>

                  <div className='input-and-error mb-4 flex-1'>
                    <Input
                      isDirty={dirtyFields.last_name}
                      label='Last Name'
                      type='text'
                      name='last_name'
                      register={register}
                      placeholder='Enter Last Name'
                      validate={
                        {
                          pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                          minLength: 3,
                          maxLength: 20
                        }
                      }
                      error={checkErrors(errors, 'last_name')}
                    />
                    {errors?.last_name?.required && <p>Last  Name is required</p>}
                    {errors?.last_name && <p>Please input a valid name</p>}
                  </div>
                </div>

                {/* Email */}
                <div className='md:flex gap-5'>
                  <div className='input-and-error mb-4 flex-1'>
                    <Input
                      isDirty={dirtyFields.email}
                      label='Email Address'
                      type='email'
                      name='email'
                      placeholder='Enter Email Address'
                      register={register}
                      validate={
                        {
                          pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        }
                      }
                      error={checkErrors(errors, 'email')}
                    />
                    {errors?.email?.required && <p>Email is required</p>}
                    {errors?.email && <p>Please input a valid email</p>}
                  </div>

                  <div className='input-and-error mb-4 flex-1'>
                    <Input
                      isDirty={dirtyFields.phone_number}
                      error={checkErrors(errors, 'phone_number')}
                      label='Phone Number'
                      type='text'
                      name='phone_number'
                      register={register}
                      placeholder='(+234) 8149181923'
                      validate={
                        {
                          pattern: /^(((\+234\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+234\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+234\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
                          maxLength: 15
                        }
                      } />

                    {errors?.phone_number?.required && <p>Phone number is required</p>}
                    {errors?.phone_number && <p>Please input a valid phone number</p>}
                  </div>
                </div>

                {/* Password */}
                {
                  !removePassword &&
                  <div className={'md:flex gap-5'}>
                    <div className='input-and-error mb-4 flex-1'>
                      <Input
                        isDirty={dirtyFields.password}
                        error={checkErrors(errors, 'password')}
                        label='Password'
                        type='password'
                        name='password'
                        register={register}
                        placeholder='Enter password'
                        validate={
                          {
                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
                          }
                        }
                      />
                      {errors?.password?.required && <p>Password is required</p>}
                      {errors?.password && <p>Min password required = 6, atleast 1 number and 1 character </p>}
                    </div>

                    <div className='input-and-error mb-4 flex-1'>
                      <Input
                        label='Confirm Password'
                        isDirty={dirtyFields.confirmpassword}
                        type='password'
                        name='confirmpassword'
                        register={register}
                        placeholder='Enter password'
                        error={checkErrors(errors, 'confirmpassword')}
                      />

                      {errors?.confirmpassword?.required && <p>Password is required</p>}
                      {dirtyFields.confirmpassword && (watch('password') !== watch('confirmpassword')) ? <p>Password does not match</p> : ''}
                    </div>
                  </div>
                }

                {/* Roles */}
                <SelectRole register={register} setValue={setValue} isDirty={'datarole'} title='Role Profile' />

                <div className='otheraction'>
                  <div className='flex items-center'>
                    <input type='checkbox' className='mr-4' name='is_auto_generate_password"' value='' {...register("is_auto_generate_password")} onClick={() => setRemovePassword(!removePassword)} />
                    <label for='createpassword'>
                      {' '}
                      Automatically create a password (Optional)
                    </label>
                  </div>
                  <div className='flex items-center'>
                    <input type='checkbox' className='mr-4' name='change_password_on_first_signin' {...register("change_password_on_first_signin")} />
                    <label for='changepassword'>
                      {' '}
                      Require this user to change their password when they first sign in (Optional)
                    </label>
                  </div>
                </div>
              </div>

              {/* Image */}
              <div className='lg:w-2/5 mb-4'>
                <UploadImage accept={{ "image/*": ['.png', '.jpg'] }}
                  name="profile_image" label="File Upload"
                  icon={FiUser} text={'Upload Photo'} rounded={true} img={UploadImageIcon} />

                {
                  noImage && <p className='text-red-500 text-center'>Please select a profile image</p>
                }
              </div>
            </div>

            <div className='bg-white px-[34px] py-[18px] mb-4 rounded shadow-sm shadow-white flex gap-x-8'>
              <button className='bg-primary px-7 py-[14px] text-white ' type='submit'>
                {isLoading ? 'Submitting...' : 'Create User'}
              </button>

              <button className='cancel px-7 py-[14px] border border-red-500 text-red-500' type='button' onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        </FormProvider>

      </div>

      <ToastContainer />
    </>
  )
}

export default NewUser