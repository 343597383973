import React, { useState } from 'react'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import './newrole.scss'
import Permission from '../../../components/permissions/Permission'
import Input from '../../../components/forms/PassWordInput'
import {useForm, FormProvider} from 'react-hook-form'
import { initialFormValues } from './InitialFormValues'
import { usePostUserRolePermissionsMutation } from '../../../features/user/userApiSlice'
import { useNavigate } from 'react-router-dom'
import { ToastContainer , toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { HeaderContainer } from '../../../components/header/Header.style'
import { handleError } from '../../../utils/error-handler'
import { selectPrivileges, useCreateRoleMutation } from '../../../features/identity/identityApiSlice'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel } from '@mui/material'
import DashboardOutlined from '@mui/icons-material/DashboardOutlined'

/**
 *
 * @type {[string, string, string, string, string, string, string, string]}
 * @constant
 * @name dummyData
 * @description This data will populate the checkboxes in the permission component
 */
const dummyData = ['view', 'create', 'edit', 'delete', 'import', 'print', 'activate', 'deactivate']

/**
 * @constant
 * @name data
 * @description This data will populate the breadcrumbs
 * @type {[{name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: string}]}
 */
const data = [
  {name: 'Dashboard', link: '/home' , Icon:DashboardOutlined},
  {name: 'Identity Management', link: '/identities' , Icon:PeopleAltOutlinedIcon},
  // {name: "Role Profiles", link: '/users/roles' , Icon:FolderOutlinedIcon},
  {name: 'Create new role', link: '/identities/roles/new' , Icon:""},
]

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * @name NewRole
 * @description This component is responsible for creating a new role
 *
 */
const NewRole = () => {

  const navigate = useNavigate()

  /**
   * @constant
   * @name postUserRoles
   * @description This is the rtquery mutation function that will be used to create a new role profile
   */
 
  const [createRole, {isLoading: isCreating}] = useCreateRoleMutation();
  //const [postUserRoles , {isLoading}] = usePostUserRolePermissionsMutation()

  /**
   * @constant
   * @name methods
   * @description This is the react-hook-form method that will be used to handle the form
   * @type {any}
   * @see https://react-hook-form.com/api/useform
   * @see https://react-hook-form.com/api/useformcontext
   * @see https://react-hook-form.com/api/useformregister
   *
   */

  const methods = useForm({
      defaultValues: {
        role_name: '',
        report_manager: [],
        content_manager: [],
        user_profiles: [],
        user_roles: [],
        air_template_manager: [],
        data_Settings: [],
        billing_crm: [],
        api_service_manager: []
      }
    }
  )

  /**
   * @constant
   * desrucutre the register method from the methods object
   */
  const {register, handleSubmit} = methods

  /**
    * @function onSubmit
    * @description This function will be called when the form is submitted
    * @param {object} data
    * @param {object} e
    * @returns {Promise<void>}
    * @async true
    * @see https://react-hook-form.com/api/useform/trigger
    * @see https://react-hook-form.com/api/useform/handlesubmit
    *
   */
  const onSubmit = async(data) => {

    let newModule = {};
    let userManagement = {};

    console.log(data);

    initialFormValues.modules.filter((module) => {

      let permissions = data[module.module_name]

      console.log(permissions);

      // Check if the permissions array includes the permission and set the value to true or false
      module.create = permissions?.includes('create')? "true" : "false"
      module.view = permissions?.includes('view') ? "true" : "false"
      module.export = permissions?.includes('export') ? "true" : "false"
      module.deactivate = permissions?.includes('deactivate')? "true" : "false"
      module.edit = permissions?.includes('edit') ? "true" : "false"
      module.delete = permissions?.includes('delete') ? "true" : "false"
      module.import = permissions?.includes('import') ? "true" : "false"
      module.print = permissions?.includes('print')? "true" : "false"
      module.activate = permissions?.includes('activate') ? "true" : "false";

      let name = module.module_name;
      delete module.module_name;

      if(['user_profiles', 'user_roles'].includes(name)) {
        userManagement[`${name}`] = module;
      }
      else {
        newModule[`${name}`] = module;
      }
    })

    newModule.user_management = userManagement;

    try {

      let response = await createRole({
        ...newModule,
        role: data.role_name?.toUpperCase()}).unwrap();

      toast.success(response.message);
      setTimeout(() => { navigate('/users/roles')}, 5000)
    } 
    catch (error) {
      handleError(error)
    }
  }

  return (
    <>
    <div className='px-3 lg:px-10'>
      <IconBreadcrumbs data={data}/>
      <ToastContainer />

      <FormProvider {...methods} >
        <form  onSubmit={handleSubmit(onSubmit)}>

          <div className='bg-white px-6 pt-[32px] mb-4 rounded'>

            <div className='md:w-2/5 mb-6'>
              <Input
                  name='role_name'
                  label= 'Role Title'
                  placeholder='Enter Role Title'
                  register={register} />
            </div>

            <Permission permit={dummyData} />
          </div>

          <div className='bg-white px-[32px] py-4 mb-4 rounded'>
            <button type='submit' className='bg-primary px-5 py-3 text-white'>{isCreating ? "Submitting..." : "Create Role"}</button>
          </div>
        </form>
      </FormProvider>
    </div>
    </>
  )
}

export default NewRole