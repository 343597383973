import React from 'react'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ErrorIcon from '@mui/icons-material/Error';

/**
 * @param {object} props
 * @returns {jsx}
 * @description This dropdown component is used in the form component
 * @example <FormDropdown editActive={false} borderBottom={false} data={[]} getValue={getValue} error={false} validate={false} label={'Select'} name={'select'} handleChange={handleChange} className={'select-input'} placeholder={'Select'} register={register} isDirty={false} defaultValue={''} />
 * @param {boolean} editActive
 * @param {boolean} borderBottom
 * @param {array} data
 * @param {function} getValue
 * @param {boolean} error
 * @param {boolean} validate
 * @param {string} label
 * @param {string} name
 * @param {function} handleChange
 * @param {string} className
 * @param {string} placeholder
 * @param {function} register
 * @param {boolean} isDirty
 * @param {boolean} dialog
 * @param {boolean} disabled
 * @param {string} defaultValue
 * @param {boolean} editActive
 * @param {string} errorMessage
 * @param {boolean} isDirty
 * @type {function({editActive: boolean, borderBottom: boolean, data: array, getValue: function, error: boolean, validate: boolean, label: string, name: string, handleChange: function, className: string, placeholder: string, register: function, isDirty: boolean, dialog: boolean, disabled: boolean, defaultValue: string, editActive: boolean, errorMessage: string, isDirty: boolean, error: boolean}})}
 *
 */
const FormDropdown = ({editActive, data, getValue, error, validate, label, name, handleChange, className, placeholder, register, isDirty ,defaultValue, borderBottom, setValue : setVal = (n ,v)=>(n * v)}) => {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState('')
    const handleOpen = () => {
        setOpen(!open)
    }
    const handleAddValue = (val) => {
        getValue(name, val)
        setVal(name, val)
        setValue(val)
        setOpen(false)


    }

    return (
        <div className='password-input form title'>

            <label htmlFor={label} className='form-label'>
                {label}
            </label>

            <div   onClick={handleOpen} className={`form-input pass-input ${className} ${borderBottom ?'edit_active':''} `} style={{ border: isDirty || value ? '2px solid #0F62FE':""  }} >
                <input

                    value={ value?.length ? value : defaultValue ? defaultValue : ''}
                    onChange={handleChange}
                    className={'form-input' + ' ' + className}
                    name={name}
                    defaultValue={defaultValue}

                    placeholder={placeholder}
                    {...register(name, {
                        required:editActive ? false: true, pattern: validate?.pattern, max: validate?.max, min: validate?.min,
                        validate: validate?.validate, maxLength: validate?.maxLength, minLength: validate?.minLength
                    })}

                />

                <>
                {/* display error icon if there's an error
                if open is true display div with class name "custom-dropdown"
                */}
                    {<> {error && <ErrorIcon style={{ color: 'red' }} />}</>}
                    {open ? <ExpandLessOutlinedIcon size='40' onClick={handleOpen} /> : <ExpandMoreOutlinedIcon onClick={handleOpen} />}
                    {open && <div className='custom-dropdown'>
                        {data?.map((item, index) => <p key={index} onClick={() => handleAddValue(item)}>
                            {item}
                        </p>)}

                    </div>}
                </>

            </div>
            <div>

            </div>
        </div>
    )
}

export default FormDropdown