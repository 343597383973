import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './modal.scss'
import ImportTabs from './ImportTabs';
import { useForm, FormProvider } from 'react-hook-form'
import { handleError } from '../../../utils/error-handler';
import { useSelector } from 'react-redux';
import { usePostDataSetCSVMutation } from '../../../features/data/occurenceApiSlice';
import { toast } from 'react-toastify';

// styles for the Box component
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-40%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    display: 'grid',
    gap: '20px',
    alignItems: 'flex-start',
    boxShadow: 2,
    p: 4,
}

export default function ImportModal({ handleClose, open, download, setDownload, progress, setProgress, name, importData }) {

    const [error, setError] = React.useState(false);
    const {selectedDataSet = {}} = useSelector(state => state.dataset);
    const [postCSV, {status, isLoading, isError, isSuccess}] = usePostDataSetCSVMutation();

    React.useEffect(() => {
        
        // Sets the progress of the loader.
        if (status === "uninitialized") setProgress(p => p + 0);
        if (status === "pending") setProgress(p => p + 25);
        if (isLoading) setProgress(p => p + 25)
        if (status === "fulfilled") setProgress(p => p + 25)
        if (isSuccess) { setProgress(100)}

        if (isError) {
            setError(true)
            setProgress(100)
        }

    }, [status, setProgress, setDownload]);

    const methods = useForm({
        defaultValues: {name: ''}
    });

    // Destrucutres the form
    const { handleSubmit, watch } = methods
    const props = { progress, setProgress, download, setDownload, error, watch, name }

    // Submit a CSV file 
    const onSubmit = async (data) => {
        let form = new FormData()
        form.append('File', data?.file);
        try {
            if(selectedDataSet.import) {
                setDownload(!download)
                const response = 
                    await postCSV({url: selectedDataSet.import, form: form}).unwrap()
                toast.success(response.message);
            }
        } 
        catch (error) {
            handleError(error)
        }
        finally {handleClose()}
    }

    return (
        <FormProvider {...methods}>
            <Modal
                aria-labelledby="import model data"
                aria-describedby="import model data"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                {/* Fade is used to animate the modal */}
                <Fade in={open}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                     {/* Box is used to style the modal */}
                        <Box sx={style}>
                            <div className='modal-heading'>
                                <h3>Import Model Data</h3>
                                <CloseOutlinedIcon onClick={()=>handleClose(methods.reset)} />
                            </div>
                            <ImportTabs {...props} toggleModal={handleClose} />
                        </Box>
                    </form>
                </Fade>
            </Modal>

        </FormProvider>
    );
}
