import React from 'react'
import PropTypes from 'prop-types'
import {MdEditNote} from 'react-icons/md'


const EditAction = ({handleClick}) => {
    return (
        <div className='edit-action' onClick={handleClick} data_role='Edit'>
            {/* Edit Icon */}
            <MdEditNote />
        </div>
    )
}

export default EditAction
EditAction.propTypes = {
    handleClick: PropTypes.func.isRequired
}