import BackgroundImg from '../../../assets/loginbackgroundlarge.svg'
import { toast, ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Input from '../../../components/forms/PassWordInput'
import 'react-toastify/dist/ReactToastify.css'
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage, } from '../../../utils/localStorage'
import { useLoginMutation } from '../../../features/authentication/authApiSlice'
import { setCredentials } from '../../../features/authentication/authSlice'
import styled from '@emotion/styled'
import './login.scss'
import ArgueBlue2 from '../../../assets/Logo/ArgueBlue2.svg'
import { keys } from '../../../utils/constants'
import { handleError } from '../../../utils/error-handler'
import { makeId } from '../../../utils/random-string'

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * @name Login
 * @description This component is responsible for rendering the login page
 * @todo Add a remember me checkbox functionality
 *
 *
 */
const Login = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [login, { isLoading, error, }] = useLoginMutation()
  const onSubmit = async (payload) => {
    try {

      removeFromLocalStorage(keys.refresh)
      const response = await login(payload).unwrap()

      toast.success(response.message)
      addToLocalStorage(keys.isLoggedIn, true)
      addToLocalStorage(keys.access, response.data.access)

      addToLocalStorage(keys.refresh, response?.refresh)
      dispatch(setCredentials({ ...response.data, isLoggedIn: true }))

      const from = location.state?.from || "/home";
      navigate(from, { replace: true });
    }
    catch (err) {
      let code = err.status;
      if (code === 307) { // Change password
        let token = makeId(16); // random string
        navigate(`reset-password?change=true&token=${token}&email=${payload.email}`, { replace: true });
      }

      handleError(err)
    }
  }

  const checkErrors = (errs, name) => {
    const errors = Object.keys(errs)
    return errors.find(er => name === er)
  }


  return (
    <>
      <div className='min-h-screen md:flex'>

        <div className='md:w-1/2 '>
          <Background src={BackgroundImg}></Background>
        </div>

        <div className='md:w-1/2'>

          <LogoWrapper>
            <img src={ArgueBlue2} alt='Argue Logo' />
          </LogoWrapper>

          <div className='flex flex-col justify-center items-center'>
            <div className='form-header'>
              <h2>Welcome</h2>
              <p>Login to your account</p>
            </div>

            <form className='mx-3 md:w-2/4 md:mx-auto' onSubmit={handleSubmit(onSubmit)}>
              <div className='login-input'>
                <Input
                  errors={errors}
                  isDirty={dirtyFields.email}
                  label='Email Address'
                  type='email'
                  name='email'
                  register={register}
                  placeholder='Enter your email'
                  error={checkErrors(errors, 'email') ? checkErrors(errors, 'email') : error?.data?.detail && error?.data?.detail !== "Error with the password, ensure you enter the correct password"}

                  validate={
                    {
                      pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      required: true
                    }
                  }

                />
                {errors?.email?.type ? <ErrorMessage >Email is not valid</ErrorMessage> : error?.data?.detail && error?.data?.detail !== "Error with the password, ensure you enter the correct password" ? <ErrorMessage >Error with the email, ensure you enter the correct email address</ErrorMessage> : ""}
              </div>

              <div>
                <Input
                  isDirty={dirtyFields.password}
                  label='Password'
                  type='password'
                  name='password'
                  register={register}
                  placeholder='Password'
                  error={error?.data?.detail}
                // validate={
                //   {
                //     pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,

                //   }
                // }
                />
                {error?.data?.detail === "Error with the password, ensure you enter the correct password" ? <ErrorMessage >
                  Error with the password, ensure you enter the correct password
                </ErrorMessage> : ""}

              </div>

              <div className='login-action'>
                <div className='login-action__checkbox'>
                  <input type='checkbox' {...register('checkbox')} />
                  <p>Keep me logged in</p>
                </div>

                <Link to='/forgot-password' className='forgot-password'>
                  Forgot your password?
                </Link>
              </div>

              <button disabled={isLoading} className='btn-blue bg-sky-500' type='submit'>
                {isLoading ? 'Submitting...' : "Login"}
              </button>

            </form>
          </div>
        </div>

      </div>
      <ToastContainer />
    </>
  )
}

//image of the login page
//set as background image
export const Background = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  >*{
   padding:3.25rem 4rem;
  }
  @media (max-width: 52.25em) {
    display: none;

  }
`
export const LogoWrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding:  3.25rem 4rem;


p{
  color: #211357;
  font-weight: 400;
line-height: 120%;
height: 2.125rem;
margin-left: 0.62rem;
font-size: 1.875rem;
width:8.81rem;
}


`
export const ErrorMessage = styled.span`
color:#DA1E28;
font-size: 0.9rem;
padding:0;
margin:0
font-weight:400;
`


export default Login
