import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './modal.scss'
import ImportTabs from './ImportTabs';
import { useForm, FormProvider } from 'react-hook-form'
import { usePostCSVMutation } from '../../features/aircraft_template/aircraftApiSlice';
import { handleError } from '../../utils/error-handler';
import { toast } from 'react-toastify';

// styles for the Box component
const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-40%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    display: 'grid',
    gap: '20px',
    alignItems: 'flex-start',
    boxShadow: 2,
    p: 4,
}

export default function ImportModal({ handleClose, open, download, setDownload, progress, setProgress, name}) {

    //
    const [error, setError] = React.useState(false);
    const [postCSV, { isLoading, isSuccess, status, isError}] = usePostCSVMutation()

    React.useEffect(() => {

        // Sets the progress of the loader.
        if (status === "uninitialized") setProgress(p => p + 0);
        if (status === "pending") setProgress(p => p + 25);
        if (isLoading) setProgress(p => p + 25)
        if (status === "fulfilled") setProgress(p => p + 25)

        if (isSuccess) {
            setProgress(p => p + 25)
        }

        if (isError) {
            setProgress(p => 0)
            setDownload(false)
            setError(true)
        }
        
    }, [isLoading, isSuccess, setProgress, status, isError, setDownload]);

    // Resets the progress bar when the modal is closed.
    // Instanciates the form context.
    const methods = useForm({
        defaultValues: {
            aircraft_template_manager: ''
        }
    });

    //Destrucutres the form
    const { handleSubmit, watch } = methods
    const props = { progress, setProgress, download, setDownload, error, watch, name}

    // Submit a CSV file to the aircraft template manager
    const onSubmit = async (file) => {
        let form = new FormData()
        form.append('aircraft_template_manager', file.aircraft_template_manager)
        try {
            setDownload(!download)
            const response = await postCSV(form).unwrap()
            toast.success(response.message);
            methods.reset()
        } 
        catch (error) {
            handleError(error)
        }
    }

    return (
        //Form provider is used to pass the form context to the child components.
        <FormProvider {...methods}>
            <Modal
                aria-labelledby="import model data"
                aria-describedby="import model data"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                {/* Fade is used to animate the modal */}
                <Fade in={open}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                     {/* Box is used to style the modal */}
                        <Box sx={style}>
                            <div className='modal-heading'>
                                <h3>Import Model Data</h3>
                                <CloseOutlinedIcon onClick={handleClose} />
                            </div>
                            <ImportTabs modalToggle={handleClose} {...props} />
                        </Box>
                    </form>
                </Fade>
            </Modal>

        </FormProvider>
    );
}
