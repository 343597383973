import { createSlice } from "@reduxjs/toolkit";
import {occurrence , aircraft, location, airports} from "../../components/data_settings/dataset"
import { addToLocalStorage } from "../../utils/localStorage";
export const dataCountSlice = createSlice({
    name: "dataCount",
    initialState: {
        users: 0,
        aircraftTemplate: 0,
       user_roles:0,
       loading: false,
       fontSize : 0,
       dataset: {
            occurrence: occurrence.length,
            aircraft: aircraft.length,
            airport: airports.length,
            location: location.length,
       }
    },

    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload.length;
        },
        setAircraftTemplate: (state, action) => {
            state.aircraftTemplate = action.payload.length
        },
        setUserRole: (state, action) => {
            // length of the payload
            state.user_roles = action.payload.length
        },
        setDataset: (state, action) => {
            state.dataset = action.payload.length
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFontSize: (state, action) => {
            addToLocalStorage("fontSize", action.payload)
            state.fontSize = action.payload
        },


    },

});

export const { setUsers, setAircraftTemplate, setUserRole, setDataset , setLoading  , setFontSize ,} = dataCountSlice.actions;
export default dataCountSlice.reducer;