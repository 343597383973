import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Box } from '@mui/material'
import Search from '../../../components/search/Search'
import PageTitle from '../../../components/pagetitle/PageTitle'
import { Link } from 'react-router-dom'

import UserInfoWrapper from '../../../components/UserDashboardWrapper/UserInfoWrapper'
import { useState } from 'react'
import usePagination from '../../../utils/Pagination'
import LongMenu from '../../../components/menu/LongMenu'
import { Stack,  } from '@mui/material'
import Pagination  from '@mui/material/Pagination';
import {useSelector} from 'react-redux'
import PeopleAlt from '@mui/icons-material/PersonOutline'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setSearchResults } from '../../../features/user/userSlice'
import { Add } from '@mui/icons-material'
import { selectPrivileges, useLoadPrivilegesQuery } from '../../../features/identity/identityApiSlice'
import UsersAndRolesDataList from '../../../components/widgets/UsersAndRolesDataList'

const options = ['5 items', '10 items', '20 items', '50 items']

const data = [
  {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
  {name: 'User Management', link: '/users', Icon:PeopleAltOutlinedIcon },
  {name:"Privileges", link: '/identities/privileges', Icon:PeopleAlt },
]

export const ListPrivileges = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  // const {permissions:roles} = useSelector(state => state.user)

  const { isLoading: privilegeLoading } = useLoadPrivilegesQuery();
  const privileges = useSelector(selectPrivileges);

  const [PerPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)

  // Calculates the number of data per page.
  const count = Math.ceil(privileges?.length / parseInt(PerPage));

  //  initialize usePagination, passing in data to process and how many perpage
  let _data = usePagination(privileges, PerPage);

  //  set the page number
  const handleChange = (e, value) => {
      setPage(value);
      _data.jump(value);
  };

  const [query , setQuery] = useState(searchParams.get('search') || '')
  const _export = { options, setValue: setPerPage, handleChange , arrLength: privileges?.length };

  const handleSearch = (e) => {
    setQuery(e.target.value)
  }

  useEffect(() => {}, [])

  return (
    
    <>
    <div className='px-3 lg:px-5'>
      {/* Page header */}
      <IconBreadcrumbs data={data} />
      <div className='flex justify-between items-center mb-5'>
        <PageTitle
            icon={<FolderOutlinedIcon />}
            pagename='Privileges'/>

            <Link to='/identities/privileges/new'>
              <button className='btn-black-outline-two'>
                <Add />
              </button>
          </Link>
      </div>

      {/* List header */}
      <div className='header bg-black flex justify-end md:justify-between p-4 text-white items-center'>
       <div className='hidden md:block'>
          <Search placeholder='Filter data...' handleChange={handleSearch} value={query} />
       </div>

        <div className='flex text-white items-center gap-3'>
          <p className='mb-0'>
            {`${_data.begin + 1} - ${parseInt(_data.end)} of ${privileges?.length}`}
          </p>
          <LongMenu className={'text-white'} {..._export} />
        </div>
      </div>

      {/* Content */}
      <UsersAndRolesDataList type={"privilege"} data={_data} cells={['Name', 'Created on', 'Status']} />
      {/* <UserInfoWrapper type="role" data={_data} name="Name" middle="Date" status="Status"  /> */}

      <Stack spacing={3} direction={'column'} sx={{ padding: '0' }}>
        <Pagination count={count} page={page} onChange={handleChange}
            sx={{
                '& .css-1ddq0ly-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected ': {
                    borderBlockEnd: '2px solid #0138a1',
                    background: 'transparent',
                    borderRadius: 0,
                    fontSize: '16px'
                },
                background: '#fff',
                alignSelf: 'flex-end'
            }} />
      </Stack>
    </div>

   

    </>
  )
}
