import { apiSlice } from "../../app/api/apiSlice";

const apiDataApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createAirlab: builder.mutation({
            query: (body) => {
                return ({ 
                    url: `/`,
                    method: 'POST',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
        }),

        updateAirlab: builder.mutation({
            query: (data) => {
                const { id, ...body } = data
                return ({ 
                    url: `settings/thirdparty-api/airlabs/${id}/`,
                    method: 'PUT',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
        }),

        createAviation: builder.mutation({
            query: (body) => {
                return ({ 
                    url: `/`,
                    method: 'POST',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
        }),

        updateAviation: builder.mutation({
            query: (data) => {
                const { id, ...body } = data
                return ({ 
                    url: `settings/thirdparty-api/aviation-stack/${id}/`,
                    method: 'PUT',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
        }),
    })
})

// Hooks
export const {useCreateAirlabMutation, useUpdateAirlabMutation} = apiDataApiSlice

