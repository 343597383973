import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { selectPrivileges, selectRole, useActivateRoleQuery, useCreateRoleMutation, useDeactivateRoleQuery, useGetRoleQuery, 
    useLoadPrivilegesQuery, useUpdateRoleMutation } from "../../../features/identity/identityApiSlice";
import { useEffect } from "react";
import IconBreadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { AiOutlineHome } from "react-icons/ai";
import { PeopleAlt } from "@mui/icons-material";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Checkbox, FormControlLabel, Skeleton } from "@mui/material";
import { useState } from "react";
import AuditTrail from "../../../components/audittrail/AuditTrail";
import { useLoadAuditQuery } from "../../../features/audit-trail/auditTrailApiSlice";
import { Permission } from "../../../components";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../components/forms/PassWordInput";
import AlertDialog from "../../../components/Dialog/AlertDialog";
import { initialFormValues } from "./InitialFormValues";
import { handleError } from "../../../utils/error-handler";
import { toast } from "react-toastify";

const data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'Identity Management', link: '/users', Icon: PeopleAltOutlinedIcon },
    {name:"Roles", link: '/identities/roles', Icon: PeopleAlt },
    {name:"Role", link: '', Icon: PeopleAlt },
]

const dummyData = ['view', 'create', 'edit', 'delete', 'import', 'print', 'activate', 'deactivate']

export const ViewRole = () => {

    const {id} = useParams();
    const navigate = useNavigate()

    let role = useSelector(selectRole(id))

    const [audit, setAudit] = useState([]);
    const [skip, setSkip] = useState(true);
    const [edit, setEdit] = useState(false);

    // dialog
    const [type, setType] = useState(null);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);
    const [modalOk, setModal] = useState(false);
    const [prompt, setPrompt] = useState(false);
  
    const [skipActivate, setSkipActivate] = useState(true);
    const [skipDeactivate, setSkipDeactivate] = useState(true);

    const {data: activeResponse, error: activateError} 
        = useActivateRoleQuery(id, {skip: skipActivate})

    const {data: deactivateResponse, error: deactivateError} 
        = useDeactivateRoleQuery(id, {skip: skipDeactivate})
    
    // Activate and Deactivate Response
    if(deactivateResponse || activeResponse) {
        let message = activeResponse?.message || deactivateResponse?.message;
        toast.success(message);
        setTimeout(() => {navigate(-1)}, 500);
    }

    // Errors
    if(activateError || deactivateError) { handleError(activateError || deactivateError)}

    const handleOpen = (type) => {
        setOpen(true);
        setType(type);

        if(type == 1) { // clone
            setModal(true);
            setTitle('Clone Role profile')
            setPrompt('Are you sure you want to clone this Role profile');
        }

        if(type == 2) {
            setModal(false);
            setTitle('Deactivate Role profile')
            setPrompt('Are you sure you want to deactivate this Role profile');
        }

        if(type == 3) {
            setModal(true);
            setTitle('Activate Role profile')
            setPrompt('Are you sure you want to activate this Role profile');
        }
    }

    const onAction = (value) => {
        if(type == 1) {
            createNewRole();
        }

        if(type == 2) {
            setSkipDeactivate(false)
        }

        if(type == 3) {
            setSkipActivate(false)
        }
    }

    const [createRole, {isLoading: isCreating}] = useCreateRoleMutation();
    const [updateRole, {isLoading: isUpdating}] = useUpdateRoleMutation();

    // Audit
    const {data: auditResult} = useLoadAuditQuery('IDENTITY-MANAGER');

    const {data: selected, isLoading, isError} = useGetRoleQuery(id, {skip, 
        refetchOnMountOrArgChange: true});
    role = selected?.data || role;

    const methods = useForm({
        defaultValues: {
          role: '',
          report_manager: [],
          content_manager: [],
          user_profiles: [],
          user_roles: [],
          air_template_manager: [],
          data_Settings: [],
          billing_crm: [],
          api_service_manager: [],
        }
      }
    )
  
    /**
     * @constant
     * desrucutre the register method from the methods object
     */
    const {register, handleSubmit, setValue, formState: {dirtyFields}} = methods

    const onSubmit = async(data) => {

        let newModule = {};
        let userManagement = {};
    
        initialFormValues.modules.filter((module) => {
    
          let permissions = data[module.module_name]
          // Check if the permissions array includes the permission and set the value to true or false
          module.create = permissions?.includes('create')? "true" : "false"
          module.view = permissions?.includes('view') ? "true" : "false"
          module.export = permissions?.includes('export') ? "true" : "false"
          module.deactivate = permissions?.includes('deactivate')? "true" : "false"
          module.edit = permissions?.includes('edit') ? "true" : "false"
          module.delete = permissions?.includes('delete') ? "true" : "false"
          module.import = permissions?.includes('import') ? "true" : "false"
          module.print = permissions?.includes('print')? "true" : "false"
          module.activate = permissions?.includes('activate') ? "true" : "false";
    
          let name = module.module_name;
          delete module.module_name;
    
          if(['user_profiles', 'user_roles'].includes(name)) {
            userManagement[`${name}`] = module;
          }
          else {
            newModule[`${name}`] = module;
          }
        })
    
        newModule.user_management = userManagement;

        try {
            let form = {
                id: role.id,
                data: {
                    role: role.role,
                    ...newModule
                }
            }

          let response = await updateRole(form).unwrap();
          toast.success(response.message);
          // setTimeout(() => { navigate('/users/roles')}, 5000)
        } 
        catch (error) {
          handleError(error)
        }
      }
    
    const handleClose = () =>  setOpen(false)

    const createNewRole = async () => {
        try {
            let clone = {...role};
            clone.role = clone.role + ' CLONE';
            let response = await createRole(clone).unwrap();
            toast.success(response.message);
            setTimeout(() => { navigate('/users/roles')}, 5000)
        } 
        catch (error) {
            handleError(error)
        }
        finally { setOpen(false) }
    }

    useEffect(() => {

        if(!role) setSkip(false)
        else setValue('role', role.role);
    
        if(auditResult && !audit.length) {
            let audits = [];
            let results = auditResult.results || [];
            results.forEach(row => {
              let audit = {
                info: row.trace,
                time: row.created_at,
                name: row.response?.data?.first_name
              }
              audits.push(audit);
            });
            setAudit(audits);
        }

    }, [auditResult])

    return (
        <>
        <div className="px-3 lg:px-10">
            <IconBreadcrumbs data={data} />

            {
                isLoading && 
                    <div>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                    </div>
            }

            {
                role && 
                <div className='mb-4'>

                    <FormProvider {...methods} >
                        <form  onSubmit={handleSubmit(onSubmit)}>
                            <div className='bg-white p-5 py-3 mb-4'>

                                <div className='md:w-2/5 mb-6'>
                                <Input
                                    name='role'
                                    label= 'Role Title'
                                    isDirty={dirtyFields.role}
                                    placeholder='Enter Role Title'
                                    register={register} />
                                </div>

                                <Permission permit={dummyData} permissionsData={role} />
                        
                            </div>

                           <div className="bg-white p-5">
                            {
                                role.is_active ?
                                    edit ?
                                        <div className='mb-4 flex justify-between items-center'>
                                            <button type='submit' className='bg-primary px-8 py-3 text-white'>
                                                {isUpdating ? 'Updating...' : 'Update'}
                                            </button>
                                            <button className="px-8 py-3 text-white bg-red-500 mr-3" onClick={() => setEdit(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        :
                                        <div className="bg-white flex justify-between items-center">
                                            <div>
                                                <button type="button" className="px-8 py-3 text-white bg-primary mr-3" onClick={() => setEdit(true)}>
                                                    Edit Role</button>
                                                <button type="button" className="px-8 py-3 border-sky-600 border-solid border text-sky-600" onClick={() => handleOpen(1)}>
                                                    Clone Role</button>
                                            </div>
                                            <button type="button" className="px-8 py-3 text-white bg-red-500 mr-3" onClick={() => handleOpen(2)}>
                                                Deactivate
                                            </button>
                                        </div>
                                    : 
                                    <button type='button' className='px-8 py-3 bg-primary text-white' onClick={() => handleOpen(3)}>
                                        Activate
                                    </button>
                            }
                           </div>
                        </form>
                    </FormProvider>

                    <AuditTrail dataInfo={audit} />
                </div>
            }

            {
                isError && 
                    <div className="bg-white p-4 text-center text-red-500 font-bold rounded">
                        <h4>There's an error why loading Role. Please try again</h4>
                    </div>
            }

            <AlertDialog open={open} message={prompt} isOk={modalOk}
                onAction={onAction} onClose={handleClose} title={title}></AlertDialog>
        </div>
        </>
    )
}