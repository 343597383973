import React from 'react'
import { TableHead as TH, TableCell, TableRow } from '@mui/material'

const TableHead = ({headings}) => {
  return (

         <TH>
          <TableRow>
            {
                headings?.map((heading, key)=> <TableCell key={key} style={{color: '#7E7E7E', fontWeight: '400'}}>{heading}</TableCell>)
            }
          </TableRow>
        </TH>

  )
}

export default TableHead