// component having an icon and a text
import styled
    from "styled-components";

 const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-inline-start: ${(props) => props.marginInlineStart ? props.marginInlineStart : "0px"};
    margin-block-end: ${(props) => props.marginBlockEnd ? props.marginBlockEnd : "2rem"};
    margin-block-start: ${(props) => props.marginBlockStart ? props.marginBlockStart : "0px"};
    margin-inline-end: ${(props) => props.marginInlineEnd ? props.marginInlineEnd : "0px"};
    h2{
        margin-inline-start: 1rem;
        font-size: 22px;
        line-height: 35px;
        font-weight: 700;
        color: #161616;
        &.media{
            margin-inline-start: 1.125rem;
        }
    }
    svg{
        font-size: ${(props) => props.fontSize ? props.fontSize : "1.5rem"};
        color: ${(props) => props.color ? props.color : "#161616"};
    }

`


export {HeaderContainer}
