import React from 'react'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorIcon from '@mui/icons-material/Error';
// import { style } from '@mui/system';


/**
 *
 * @param {object} props
 * @returns {jsx}
 */
const Input = ({ initialRendering, disabled, defaultValue, borderBottom, editActive, errorMessage, isDirty, error, validate, label, type, name, value, handleChange, className, placeholder, register, dialog }) => {


    const [visible, setVisible] = React.useState(false)
    const handleOff = () => setVisible(false)
    const handleOn = () => setVisible(true)
    // const isNotPassword = () => type !== 'password'
    return (
        <div className='password-input form title'>

            <label htmlFor={label} className='form-label'>
                {label}
            </label>

            <div className={`form-input pass-input ${className} ${borderBottom ? 'edit_active' : ''}`}
                // if input is dirty, set a different style to the border
                style={{
                    border: isDirty && !error ? '2px solid #0F62FE' : error || errorMessage ? "2px solid #DA1E28" : '', borderBlockEnd: dialog ? "1px solid #0F62FE" : '', background: initialRendering ? "#fff" : "#f4f4f4"
                }}  >
                <input
                    maxLength={name === "phone_number" ? 14 : ''}
                    value={value}
                    onChange={handleChange}
                    className={`form-input ${className}`}
                    type={visible ? 'text' : type}
                    name={name}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    {...register(name, {
                        required: editActive ? false : true, pattern: validate?.pattern, max: validate?.max, min: validate?.min,
                        validate: validate?.validate, maxLength: validate?.maxLength, minLength: validate?.minLength
                    })}

                />
                {errorMessage && <p className='error'> {errorMessage} </p>}

                <>
                    {/*
                check if input is of password type:
                if true display password icon
                if theres an error, display error icon and paint red
                 */}
                    {error && <ErrorIcon style={{ color: 'red', marginInlineEnd: "7px" }} />}
                    {type === "password" && <>{visible ? <VisibilityIcon className='cursor-pointer' onClick={handleOff} /> : <VisibilityOffIcon className='cursor-pointer' onClick={handleOn} />}</>}
                </>
            </div>
        </div>
    )
}

export default Input