
import TabPanel from '../TabPanel'
import Tabs from '../Tabs'
import { occurrence } from '../dataset'
import '../datasettings.scss';
import '../../../buttons.scss';
import AuditTrail from '../../audittrail/AuditTrail';
import { HeaderContainer } from '../../header/Header.style';
import SchemaIcon from '@mui/icons-material/SchemaOutlined';
import IconBreadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import {AiOutlineHome} from 'react-icons/ai';
import { useDispatch, useSelector} from "react-redux";
import { useEffect, useState } from 'react';
import { useActivateDatasetQuery, useLoadDataQuery } from '../../../features/data/occurenceApiSlice';
import { handleError } from '../../../utils/error-handler';
import { useLoadAuditQuery } from '../../../features/audit-trail/auditTrailApiSlice';
import { toast } from 'react-toastify';
import { setActive, setDatasetCategory, setSelected, setUrl } from '../../../features/data/dataSettingsSlice';

const types = occurrence.map(item => item.name);

const Occurrence = ({ _export}) => {

  const _data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'Data Settings', link: '/data-settings', Icon: SchemaIcon},
    {name: "Occurrence Creation Data Set", link: '/data-settings' , Icon: SchemaIcon},
  ]

  const dispatch = useDispatch();

  // Audit
  const [audit, setAudit] = useState([]);
  const {data: auditResult} = useLoadAuditQuery('DATA-SETTINGS-MANAGER');
  
  // Prep data
  const {url, active} = useSelector((state) => state.dataset);

  const [skip, setSkip] = useState(true);
  const {data: result, error, isLoading, isFetching, refetch} = useLoadDataQuery(url, {skip})
  const models = types.includes(active) ? result?.data : [];

  const loadData = () => {if(url) setSkip(false)}
  if(error) handleError(error);

  const setCurrent = () => {
    let value = occurrence[0];
    dispatch(setUrl(value.url)) 
    dispatch(setSelected(value)); 
    dispatch(setActive(value.name))
  }

  useEffect(() => {

    if(url) loadData()

    if(auditResult) {
      let audits = [];
      let results = auditResult.results || [];
      results.forEach(row => {
        let audit = {
          info: row.trace,
          time: row.created_at,
          name: row.response?.data?.first_name
        }
        audits.push(audit);
      });

      setAudit(audits);
    }

    if(!types.includes(active)) {
      setCurrent();
    }

  }, [url, auditResult])
  
  _export = {data: models, 
    isFetching, isLoading, error, ..._export, refresh: refetch}

  return (
    <>
    <div className='px-3 lg:px-10'>
      <IconBreadcrumbs data={_data} />
      
        <HeaderContainer>
          <SchemaIcon />
          <h2>Occurrence Creation Data Settings</h2>
        </HeaderContainer>

        <div className="oc-page bg-white">
          <Tabs dataTypeHeaders={occurrence} />
          <TabPanel {..._export} />
        </div>
        
        <AuditTrail dataInfo={audit} />
    </div>
    </>
  )
}








export default Occurrence


