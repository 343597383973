import { AiOutlineHome } from "react-icons/ai"
import FlightIcon from '@mui/icons-material/Flight'
import IconBreadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import { PageTitle } from "../../../components"
import { IconButton, Pagination, Stack } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { Link } from "react-router-dom"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useGetAircraftTemplatesQuery } from "../../../features/aircraft_template/aircraftApiSlice"
import usePagination from "../../../utils/Pagination"
import LongMenu from "../../../components/menu/LongMenu"
import { useState } from "react"
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import '../../../components/aircraftmanager/styles/index.scss';
import Spinner from "../../../components/Spinner/Spinner"
import ModelTable from "../../../components/Model/ModelTable"
import AircraftImportModal from "../AircraftImportModal"

const _data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'Aircraft Template Manager', link: '/aircraft_template' , Icon: FlightIcon},
]

const options = ['5 items', '10 items', '20 items', '50 items']

export const ListAircraftModels = () => {

    const { data, isSuccess, isLoading, isError } = 
        useGetAircraftTemplatesQuery({ refetchOnMountOrArgChange: true })

    const [modalOpened, setModalOpened] = useState(false);
    const toggleModal = () => {
        setModalOpened(!modalOpened)
    }

    const rows = data?.data || [];
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const count = Math.ceil(rows.length / parseInt(perPage));
   
    let _rows = usePagination(rows, perPage);
    const handleChange = (value) => {
        setPage(value);
        _rows.jump(value);
    }

    const _export = { options, setValue: setPerPage, handleChange, arrLength: rows.length };

    return (
        <>
        <IconBreadcrumbs data={_data}/>
        <div className='mb-6 aircraft-heading'>
            <PageTitle
                icon={<FlightIcon className='createusericon' />}
                pagename='Aircraft Template Manager' />
        </div>

         {/* Header */}
         <header className='md:flex justify-between items-center text-center mb-4'>
            <div className='basis-2/5 text-center'>
                <div className='catl-container bg-primary p-5 mb-4 rounded'>
                    <div className='text-info'>
                        <h4 className='text-white text-center font-bold'>
                            Create New Aircraft Model Data
                        </h4>

                        <p className=' text-white text-center font-bold'>
                            Add new data model to the aircraft template
                        </p>
                    </div>

                    <Link to={'create'} className='mx-auto'>
                        <IconButton className='icon-button'>
                            <span>Create Aircraft Model Data</span>
                            <AddIcon />
                        </IconButton>
                    </Link>
                </div>
            </div>

            <IconButton className='icon-button' onClick={toggleModal}>
                <span>import Aircraft Model Data</span>
                <FileDownloadOutlinedIcon />
            </IconButton>
        </header>

        {/* Search bar */}
        <div className='create-aircraft-bottom header bg-sky-700 flex justify-end md:justify-between p-4 lg:px-6 text-white items-center'>
                <div className='create-aircraft-heading-left flex items-center'>
                    <PersonOutlinedIcon />
                    <h5>Aircraft Model Data</h5>
                </div>

                <div className='create-aircraft-heading-right flex gap-5 items-center'>
                    <div className='create-aircraft-heading-input'>
                        <SearchOutlinedIcon />
                        <input placeholder='Search input text' />
                    </div>
                    
                    {/* dynamic data */}
                    <p>{`${_rows.begin + 1} - ${parseInt(_rows.end)} of ${rows.length}`}</p>
                    <LongMenu {..._export} />
                </div>
        </div>

         {/* Content  */}
         {
            _rows?.currentData().length < 0 
                    ? <Spinner /> : <ModelTable flight={_rows} reports={_rows} page={page} />
        }

        {/* Pagination  */}
        <Stack spacing={3} direction={'column'} sx={{ padding: '0' }}>
            <Pagination count={count} page={page} onChange={handleChange}
                sx={{
                    '& .css-1ddq0ly-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected ': {
                        borderBlockEnd: '2px solid #0138a1',
                        background: 'transparent',
                        borderRadius: 0,
                        fontSize: '16px'
                    },
                    background: '#fff',
                    alignSelf: 'flex-end'
                }} />
        </Stack>

        <AircraftImportModal isOpened={modalOpened} toggleModal={toggleModal} />
        </>
    )
}

export default ListAircraftModels