import { Pagination, Stack } from "@mui/material";
import "./paginate.scss";

const Paginate = ({page, count, onChange}) => {
    return(
        <>
            <div className="py-3 mb-3 flex justify-end">
                <Pagination count={count} page={page} onChange={onChange} className="paginate" variant="text" />
            </div>
        </>
    )
}

export default Paginate;