import { apiSlice } from "../../app/api/apiSlice";


// Inject endpoints into the apiSlice
const AircraftTemplateApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        getAircraftTemplates: builder.query({
            query: () => ({
                url:  "aircraft-template-manager/",
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: ['aircrafts'],
            keepUnusedDataFor: 240
        }),
        
        getAircraftTemplate: builder.query({
            query: (id) => ({
                url:  `aircraft-template-manager/${id}/`,
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: ['Aircraft'],
        }),

        postTemplate: builder.mutation({
            query: body => ({
                method: "POST",
                url: "aircraft-template-manager/",
                body: body,
                headers: {superadmin: 'superadmin'}
            }),

            invalidatesTags: ['Aircraft']
        }),

        postCSV: builder.mutation({
            query: body => ({
                method: "POST",
                url: "aircraft-template-manager/aircraft_template_manager_import/",
                body,
                headers: {superadmin: 'superadmin'},
            }),

            invalidatesTags: ['Aircraft']
        }),

        deleteTemplate: builder.mutation({
            query: id => ({
                method: "DELETE",
                url: `aircraft-temp-manager/${id}/`,
            }),
            invalidatesTags: ['Aircraft']
        }),

        // deactivate template
        deactivateTemplate: builder.mutation({
            query: id => ({
                method: "PATCH",
                url: `aircraft-temp-manager/${id}/`,
                body: { "is_active": false }
            }),
            invalidatesTags: ['aircrafts', 'aircraft']
        }),

        deactivateTemplateModel: builder.query({
            query: id => ({
                method: "GET",
                url: `aircraft-template-manager/${id}/deactivate/`,
                headers: {superadmin: 'superadmin'},
            }),
            invalidatesTags: ['Aircraft']
        }),

        // activate template
        activateTemplate: builder.mutation({
            query: id => ({
                method: "PATCH",
                url: `aircraft-temp-manager/${id}/`,
                body: {}
            }),
            invalidatesTags: ['Aircraft']
        }),

        activateTemplateModel: builder.query({
            query: id => ({
                method: "GET",
                url: `aircraft-template-manager/${id}/activate/`,
                headers: {superadmin: 'superadmin'},
            }),
            invalidatesTags: ['Aircraft']
        }),

        updateTemplate: builder.mutation({
            query: body => ({
                method: "PUT",
                body: body.data,
                headers: {superadmin: 'superadmin'},
                url: `aircraft-template-manager/${body.id}/`,
            }),
            invalidatesTags: ['Aircraft']
        }),

        updateTemplateImage: builder.mutation({
            query: body => ({
                method: "PUT",
                body: body.data,
                headers: {superadmin: 'superadmin'},
                url: `aircraft-template-manager/${body.id}/update_3d_image/`,
            }),
            invalidatesTags: ['Aircraft']
        }),

        importAirlabs: builder.query({
            query: () => ({
                url:  'aircraft-template-manager/airlab_import/',
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: ['Aircraft']
        }),

        importAviationStacks: builder.query({
            query: () => ({
                url:  'aircraft-template-manager/aviationstack_import/',
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: ['Aircraft']
        }),
    }),
});

// Hooks
export const {
    useUpdateTemplateMutation, useGetAircraftTemplateQuery, 
    usePostTemplateMutation, usePostCSVMutation, useActivateTemplateMutation, 
    useDeactivateTemplateMutation, useGetAircraftTemplatesQuery,
    useImportAirlabsQuery, useImportAviationStacksQuery,
    useActivateTemplateModelQuery, useDeactivateTemplateModelQuery,
    useUpdateTemplateImageMutation} = AircraftTemplateApiSlice

