import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './modal.scss'
import TabPanel from './TabPanel';
import { useDropzone } from 'react-dropzone'
import FileUpload from './FileUpload';
import ProgressBar from './ProgressBar';
import {useFormContext} from 'react-hook-form'
import  ImportApiModal from './API/ImportApiModal';

function a11yProps(index) {
    return { id: `role-tab-${index}`, 'aria-controls': `role-tab-${index}`};
}

export default function ImportTabs({download, progress, error, watch, name, modalToggle}) {

    const formContext = useFormContext()
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const onDrop = React.useCallback((acceptedFiles) => {
        formContext.setValue("aircraft_template_manager", acceptedFiles[0])
    }, [])

    // Dropzone 
    const { getRootProps, getInputProps, isDragActive, 
        fileRejections } = useDropzone({ onDrop, accept: ".csv", multiple: false })
        
    const Props = { getInputProps, isDragActive, fileRejections, getRootProps, error }

    return (
        <Box sx={{ width: '100%'  }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="import role data">
                    <Tab label="File" {...a11yProps(0)} />
                    <Tab label="API" {...a11yProps(1)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                {
                    download &&
                        <div className='modal-content'>
                            <h4>importing model data</h4>
                            <ProgressBar value={progress} />
                            {progress === 100 && <p>Data import completed</p>}
                        </div>
                }

                {
                    progress < 10 && <FileUpload {...Props} />
                }

                {
                    (progress < 10 && watch().aircraft_template_manager) 
                        && <button type="submit" className='os-btn-blue bg-primary'>
                                import
                            </button>
                }
            </TabPanel>

            <TabPanel value={value} index={1}>
                {
                    name === "dataSettings" 
                        ? null : <ImportApiModal modalToggle={modalToggle}/>
                }
            </TabPanel>

        </Box>
    );
}



