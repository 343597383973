import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { tags } from "../../utils/constants";

export const identityApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.query({
            query: () => ({
                url:  'identity-manager/roles/',
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: [tags.role]
        }),

        getRole: builder.query({
            query: (id) => ({
                url:  `identity-manager/roles/${id}/`,
                headers: {superadmin: 'superadmin'}
            }),
        }),

        createRole: builder.mutation({
            query: body => ({
                url: `identity-manager/roles/`,
                method: 'POST',
                body,
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.role]
        }),

        updateRole: builder.mutation({
            query: body => ({
                url: `identity-manager/roles/${body.id}/`,
                method: 'PUT',
                body: body.data,
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.role]
        }),

        loadPrivileges: builder.query({
            query: () => ({
                url: 'identity-manager/privileges/',
                headers: {superadmin: 'superadmin'}
            })
        }),

        getPrivilege: builder.query({
            query: (id) => ({
                url: `identity-manager/privileges/${id}`,
                headers: {superadmin: 'superadmin'}
            })
        }),

        createPrivilege: builder.mutation({
            query: body => ({
                url: `identity-manager/privileges/`,
                method: 'POST',
                body,
                headers: {superadmin: 'superadmin'}
            }),
        }),

        loadActions: builder.query({
            query: () => ({
                url: 'identity-manager/actions/',
                headers: {superadmin: 'superadmin'}
            })
        }),

        deactivateRole: builder.query({
            query: id => ({
                url: `/identity-manager/roles/${id}/deactivate/`,
                method: 'GET',
                headers: {superadmin: 'superadmin'}
            }),
        }),

        activateRole: builder.query({
            query: id => ({
                url: `/identity-manager/roles/${id}/activate/`,
                method: 'GET',
                headers: {superadmin: 'superadmin'}
            }),
        }),
    })
})

// selectors
const rolesResult = identityApiSlice.endpoints.getRoles.select();
export const selectRoles = createSelector(rolesResult, (state) => state.data?.data);
export const selectRole = (id) => createSelector(selectRoles, (state) => {
    return state?.find((item) => item.id == id);
})

const privilegesResult = identityApiSlice.endpoints.loadPrivileges.select();
export const selectPrivileges = createSelector(privilegesResult, (state) => state.data?.data);
export const selectPrivilege = (id) => createSelector(selectPrivileges, (state) => {
    return state?.find((item) => item.id == id);
})

const actionsResult = identityApiSlice.endpoints.loadActions.select();
export const selectActions = createSelector(actionsResult, (state) => state.data?.data);
export const selectAction = (id) => createSelector(selectActions, (state) => {
    return state?.find((item) => item.id == id);
})

// hooks
export const { 
    useGetRolesQuery, useCreateRoleMutation, useLoadPrivilegesQuery,
    useCreatePrivilegeMutation, useGetRoleQuery, useGetPrivilegeQuery,
    useLoadActionsQuery, useUpdateRoleMutation, useActivateRoleQuery, useDeactivateRoleQuery} = identityApiSlice
