import * as React from 'react';

import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const ProgressBar = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', }}>
      <CircularProgress variant="determinate" {...props}
        // Renders a circular progress bar.
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',

          },

          zIndex: 10,


          width: "100%",
          borderRadius: "50%",
        }}
        size={150}
        thickness={3}
        {...props}
      />
      <CircularProgress
      variant="determinate"
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#E3E3E3' : '#308fe8'),
          [`& .${circularProgressClasses.circle}`]: {
            // strokeLinecap: 'round',

          },
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,



          width: "100%",
          borderRadius: "50%",
        }}
        size={150}
        thickness={3}
        value={100}

      >
        </CircularProgress>

        <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',


        }}
        size={100}
      >

        <Typography variant="caption" component="div" sx={{fontSize: 40, color: '#161616' , fontWeight:"700" , background:"#fff", }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default ProgressBar




