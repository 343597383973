import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'

import {  ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './user.scss'
import { Link, useNavigate } from 'react-router-dom'
import { SmallImage } from '../../../components/images/SmallImage.styled'
import { HeaderContainer } from '../../../components/header/Header.style'
import { PeopleAltOutlined } from '@mui/icons-material'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { selectUsers, useGetUsersByPageQuery } from '../../../features/user/userApiSlice'
import { selectRoles, useGetRolesQuery } from '../../../features/identity/identityApiSlice'
import { TableBodySkeleton } from '../../../components/skeleton/Skeleton'
import { getProfile } from '../../../utils/profile-url'
import capitalize from '../../../utils/capitalize'
import { FiUsers, FiUser } from "react-icons/fi";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';


/**
 * @constant
 * @name data
 * @description This data will populate the breadcrumbs
 * @type {[{name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: *}, {name: string, link: string, Icon: string}]}
 */
  const data = [
    {name: 'Dashboard', link: '/home' , Icon:DashboardOutlinedIcon},
    {name: 'User Management', link: '/users' , Icon:PeopleAltOutlined},
]

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * @name UserDashboard
 * @description This component will render the user dashboard page
 * @example <UserDashboard />
 *
 */

  const UserDashboard = () => {

    const navigate = useNavigate()
    const {isLoading: rolesLoading} = useGetRolesQuery()
    const roles = useSelector(selectRoles);

    const {isLoading: userLoading} = useGetUsersByPageQuery()
    const users = useSelector(selectUsers);

    const openRole = (id) => {
      navigate(`/users/roles/${id}`)
    }
    
    const openUser = (id) => {
      navigate(`/users/${id}`)
    }


    return (
      <>
        <div className='px-3 lg:px-10'>
          <IconBreadcrumbs data={data} />
          <HeaderContainer>
            <FiUsers className='w-6 h-6' />
            <h2>User Management</h2>
          </HeaderContainer>

          <div className='lg:flex gap-3'>

            {/* Users  */}
            <Box className='lg:w-2/4 mb-4'>

              {/* Header  */}
              <div className='header py-6 px-[42px] bg-primary rounded mb-[21px]'>
                
                <div className='md:flex justify-between items-center mb-8'>
                  <div className='text-white'>
                    <p className='font-bold text-white text-lg'>Create New User</p>
                    <p>Add new user to your system</p>
                  </div>

                  <div className='flex gap-2 text-white items-center justify-center'>
                    {
                      userLoading
                        ? <Skeleton width={50} height={30} sx={{background:"#fff"}}  /> 
                        : <FiUsers className='w-6 h-6'/>}

                    <div className='count font-bold text-[32px]'>
                      {
                        userLoading 
                          ? <Skeleton variant="circular" sx={{background:"#fff"}}  height={40} width={40} /> 
                          : users?.length
                      }
                    </div>

                    <div className='font-normal text-lg'>
                      {
                        userLoading
                          ? <Skeleton width={50} height={30} sx={{background:"#fff"}}  /> 
                          : "Users"
                      }
                    </div>
                  </div>
                </div>

                <Link to='/users/new' className=''>
                  <button className='btn btn-primary'>
                    <p>Create User</p> 
                    <p>+</p>
                  </button>
                  {/* <button className='px-[14px] py-[13px] bg-white flex gap-8 text-primary text-sm'>
                    <p>Create User</p> 
                    <p>+</p>
                  </button> */}
                </Link>
              </div>

              {/* Data  */}
              <div className='flex justify-between items-start bg-primary p-6' style={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}>
                <div className='mb-0 text-white flex items-center gap-1'>
                  <FiUser className='w-4 h-4'/> <span className='font-bold text-xs'>Users</span>
                </div>

                <Link to='/users/all'>
                  { userLoading ? 
                    <Skeleton variant="text"sx={{background:"#fff"}} width={80} height={40} />
                    : <button className='px-[14px] py-3 bg-white text-primary'>View More</button>}
                </Link>
              </div>

              <TableContainer component={Paper} sx={{ minHeight: "410px", paddingBlockEnd: '30px' }}>
                <Table sx={{}} aria-label='simple table'>
                  
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align='right'>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      userLoading 
                        ? <TableBodySkeleton user={true} />
                        : users?.slice(0, 5).map((item) => {
                          const name = item.first_name ? `${item.first_name} ${item.last_name}` : 'N/A'
                          const active = item.is_active ? 'Active' : 'Inactive'

                          return (
                            <TableRow className='cursor-pointer' key={item.id} onClick={() => openUser(item.id)}>
                              <TableCell sx={{borderBottomStyle: 'none'}}>
                                <div className='flex items-center'>
                                  <SmallImage src={getProfile(item.profile_image)} /> <strong className='pl-3'>{name}</strong>
                                </div>
                              </TableCell>

                              <TableCell align='right' sx={{borderBottomStyle: 'none'}}>
                                <span style={{ color: !item?.is_active ? "#DA1E28" : '#14662D' }}>{active}</span>
                              </TableCell>
                            </TableRow>
                          )
                        })
                    }

                  </TableBody>
                </Table>
              </TableContainer>

            </Box>

            {/* Roles  */}
            <Box className='lg:w-2/4 mb-4'>

              {/* Header  */}
              <div className='header py-6 px-[42px] bg-black rounded items-center mb-[21px]'>
                
                <div className='md:flex justify-between mb-5'>
                  <div className='text-white mb-4'>
                    <h2 className='font-bold text-white text-lg'>Create New Role</h2>
                    <p>Add new role to your system</p>
                  </div>

                  <div className='flex gap-2 text-white items-center '>
                    {
                      rolesLoading 
                        ? <Skeleton width={50} height={30} sx={{background:"gray"}}  /> : <FolderOutlinedIcon  className='w-6 h-6' />}

                    <div className='count font-bold text-[32px]'>
                        {rolesLoading 
                          ? <Skeleton variant="circular" sx={{background:"gray"}}  height={40} width={40} /> : roles?.length}
                    </div>

                    <div  className='font-normal text-lg'> 
                      {
                        rolesLoading ? <Skeleton width={50} height={30} sx={{background:"gray"}}  /> :"Roles"}
                    </div>
                  </div>
                </div>

                <Link to='/users/roles/new' className='inline-block'>
                  <button className='btn bg-white text-black' style={{border: '1px solid black'}}>
                    Create Role <span>+</span>
                  </button>
                </Link>
              </div>

              {/* Data */}
              <div className='flex justify-between items-start bg-black p-6' style={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}>
                <div className='mb-0 text-white flex items-center gap-1'>
                  <FolderOutlinedIcon className='w-4 h-4' /> <span className='text-xs font-bold'>Roles</span>
                </div>

                <Link to='/users/roles'>
                  { rolesLoading ? <Skeleton variant="text"sx={{background:"#fff"}} width={80} height={40} />:
                  <button className='px-[14px] py-3 bg-white'>View More</button>}
                </Link>
              </div>

              <TableContainer component={Paper} sx={{ minHeight: "410px", paddingBlockEnd: '30px' }} >
                <Table sx={{}} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{"Title"}</TableCell>
                      <TableCell align='right'>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      rolesLoading 
                        ? <TableBodySkeleton user={false} />  
                        : roles?.slice(0, 5).map((model, index) =>
                          <TableRow sx={{ margin: "40px" }} className='cursor-pointer' key={index} onClick={() => openRole(model.id)}>
                            <TableCell sx={{borderBottomStyle: 'none'}}>
                              <div className='user-info'>
                                <h4 style={{ marginLeft: 0 }}> {capitalize(model.role)}</h4>
                              </div>
                            </TableCell>
                            <TableCell align='right' sx={{borderBottomStyle: 'none'}}>
                              <span style={{ color: model.is_active ? "#14662D" : '#DA1E28' }}>{model.is_active ? "Active" : "Inactive"}</span>
                            </TableCell>
                          </TableRow>)
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </div>
    
        <ToastContainer />
      </>
    )
}

export default UserDashboard
