import React , { useState, useEffect }  from 'react'

import { FiUser, } from 'react-icons/fi'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../../../components/Spinner/Spinner'
import '../create/newuser.scss'
import './user_profile.scss'
import UploadImage from '../../../components/uploadphoto/UploadImage'
import { useForm, FormProvider } from 'react-hook-form'
import SelectRole from '../../../components/forms/SelectRole'
import Input from '../../../components/forms/PassWordInput'
import { useDispatch  ,  useSelector} from 'react-redux';
import { Form, useNavigate, useParams } from 'react-router-dom'
import {  useUpdateUserMutation , useGetCurrentUserQuery, selectProfile, useUpdateProfileImageMutation} from '../../../features/user/userApiSlice'
import { setEditActive, setRole } from '../../../features/user/userSlice'
import ButtonContainer from '../../../components/ButtonContainer/ButtonContainer'
import styled from '@emotion/styled'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { AiOutlineHome } from 'react-icons/ai'
import PeopleAlt from '@mui/icons-material/PersonOutline';
import {HeaderContainer} from '../../../components/header/Header.style'
import { Avatar } from '@mui/material'
import AuditTrail from '../../../components/audittrail/AuditTrail'
import { handleError } from '../../../utils/error-handler'
import { getProfile } from '../../../utils/profile-url'


const ProfileSettings = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [roles, setRoles] = useState([]);

    const userData = useSelector(selectProfile);
    
    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        role: '',
        profile_image: '',
    }

    //initialize form
    const methods = useForm({
        defaultValues: initialValues
    });

    const { register, formState: { errors, dirtyFields }, handleSubmit, setValue, getValues } = methods

    useEffect(() => {
        if(userData) {
            setValue('first_name', userData?.first_name)
            setValue('last_name', userData?.last_name)
            setValue('email', userData?.email)
            setValue('phone_number', userData?.phone_number)
            setValue('password', userData?.password)
            setValue('profile_image', userData?.profile_image);

            let roles = userData?.roles;
            roles = roles ? Object.keys(roles) : [];

            setRoles(roles)
            setValue('role', roles.length ? roles[0] : '');
        }

    }, [userData])

    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [isImage, setIsImage] = useState(true)

    // const {editActive} = useSelector(state => state.user)
    const [updateUser, { isLoading, }] = useUpdateUserMutation()
    const [updateProfile, {isLoading: isUploading}] = useUpdateProfileImageMutation()

    const handleClickOpen = () => {setOpen(true)}

    const handleSave = async (e) => {

        const data = {}
        let profile = null;

        // Prep Data
        for (const key in initialValues) {
            if (getValues(key) !== userData[key]) {
                if (key === 'profile_image') {
                    profile = getValues(key)[0];
                }
                else {
                    data[key] = getValues(key)
                }
            }
        }

        try {
            let response;
            if(profile) {
                let profileForm = new FormData();
                profileForm.append('profile_image', profile);
                response = await updateProfile(profileForm).unwrap();
                data['profile_image'] = response.data?.profile_image;
            }

            response = await updateUser({id: userData.id, form: data}).unwrap()
            toast.success(response.message)
            navigate(-1)
        } 
        catch (error) {
            handleError(error);
        }
    }

    const checkErrors = (errs, name) => {
        const errors = Object.keys(errs)
        return errors.find(er => name === er)
    }

    const data = [
        { name: 'Dashboard', link: '/home', Icon: AiOutlineHome },
        { name: "Profile settings", link: '/users/all', Icon: PeopleAlt },
    ]

    if (!userData) return <Spinner />

    return (
        <>
            <div className='px-3 lg:px-10'>
                <IconBreadcrumbs data={data} />

                <HeaderContainer>
                    <PeopleAlt />
                    <h2>Profile settings</h2>
                </HeaderContainer>

                <FormProvider  {...methods}>
                    <form onSubmit={handleSubmit(handleSave)} autoComplete="off" className='mt-4'>
                        <div className='md:flex gap-5 mb-4'>

                            {/* Information */}
                            <div className='lg:w-3/5 bg-white p-3 mb-4'>

                                {/* Name */}
                                <div className='row md:flex gap-5'>
                                    <div className='input-and-error mb-4 flex-1'>
                                        <Input
                                            isDirty={dirtyFields.first_name}
                                            label='First Name'
                                            type='text'
                                            name='first_name'
                                            placeholder='Enter your first Name'
                                            register={register}
                                            error={checkErrors(errors, 'first_name')}
                                            initialRendering = {!edit}
                                            validate={
                                                {
                                                pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                                                minLength: 3,
                                                maxLength: 20
                                                }
                                            }
                                         />

                                        {errors?.first_name?.required && <p>First Name is required</p>}
                                        {errors?.first_name && <p>Please input a valid name</p>}
                                    </div>

                                    <div className='input-and-error mb-4 flex-1'>
                                        <Input
                                            isDirty={dirtyFields.last_name}
                                            label='Last Name'
                                            type='text'
                                            name='last_name'
                                            register={register}
                                            placeholder='Enter your last name'
                                            initialRendering = {!edit}
                                            validate={
                                            {
                                                pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                                                minLength: 2,
                                                maxLength: 20
                                            }
                                            }
                                            error={checkErrors(errors, 'last_name')}
                                        />

                                        {errors?.last_name?.required && <p>Last  Name is required</p>}
                                        {errors?.last_name && <p>Please input a valid name</p>}
                                    </div>
                                </div>

                                {/* Email */}
                                <div className='md:flex gap-5'>
                                    <div className='input-and-error mb-4 flex-1'>
                                    <Input
                                        isDirty={dirtyFields.email}
                                        label='Email Address'
                                        type='email'
                                        name='email'
                                        placeholder='Enter your email address'
                                        register={register}
                                        initialRendering = {!edit}
                                        validate={
                                        {
                                            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                        }
                                        }
                                        error={checkErrors(errors, 'email')}
                                    />
                                    {errors?.email?.required && <p>Email is required</p>}
                                    {errors?.email && <p>Please input a valid email</p>}
                                    </div>

                                    <div className='input-and-error mb-4 flex-1'>
                                    <Input
                                        isDirty={dirtyFields.phone_number}
                                        error={checkErrors(errors, 'phone_number')}
                                        label='Phone Number'
                                        type='text'
                                        name='phone_number'
                                        register={register}
                                        placeholder='+234 8149181923'
                                        initialRendering = {!edit}
                                        validate={
                                        {
                                            pattern: /^(((\+234\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+234\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+234\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
                                            maxLength: 15
                                        }
                                        } />

                                    {errors?.phone_number?.required && <p>Phone number is required</p>}
                                    {errors?.phone_number && <p>Please input a valid phone number</p>}
                                    </div>
                                </div>

                                {/* Roles */}
                                <SelectRole register={register} setValue={setValue} isDirty={'datarole'} title='Role Profile'
                                    defaults={roles} borderBottom={!edit} />
                            </div>

                            {/* Image */}
                            <div className='lg:w-2/5 mb-4'>
                                {
                                    edit
                                        ?   <UploadImage accept={{ "image/*": ['.png', '.jpg'] }}
                                                name="profile_image" 
                                                label="File Upload"
                                                className='items-center'
                                                img={getProfile(userData?.profile_image)}
                                                icon={FiUser} text={'Upload Photo'} rounded={true} />

                                        :   <ImageContainer onClick={() => setIsImage(false)}> 
                                                {
                                                    userData?.profile_image 
                                                    ? <img src={getProfile(userData?.profile_image)} alt='profile' />
                                                    : <Avatar />
                                                }
                                            </ImageContainer>
                                }
                            
                            </div>

                        </div>

                        {
                            edit && 
                            <div className='bg-white p-5 mb-4 flex justify-between'>
                                <button className='bg-primary py-3 px-5 text-white mr-4' type='submit'>
                                    {isLoading || isUploading ? 'Submitting...' : 'Save'}
                                </button>

                                <button className='cancel p-3 border-2 border-black text-red-500' type='button' onClick={() => setEdit(!edit)}>
                                    Cancel
                                </button>
                            </div>
                        }

                    </form>
                </FormProvider>

                {!edit && <div className='buttoncontainer'>
                    <ButtonContainer>
                        <button type='button' className='bg-primary px-10 py-3 text-white' onClick={() => setEdit(!edit)}>
                            {'Edit'}
                        </button>
                    </ButtonContainer>
                </div>}
            </div>
            
            <ToastContainer />
        </>
    )
}

export default ProfileSettings

//create rounded image component
const ImageContainer = styled.div`
  width:12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`