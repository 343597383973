import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function Linear() {
  return (
    <Box sx={{ width: '100%', color:'grey.700' }}>
      <LinearProgress color="inherit" />
    </Box>
  );
}