
import TextField from "@mui/material/TextField";

const SearchBar = ({setSearchQuery, open}) => (

  open &&  <form>
      <TextField
        id="filled-basic"
        className="search-bar"
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        variant="filled"
        placeholder="Search for data type."
        //border color
        InputProps={{
          style: {

            width: "100%",
            fontSize: "0.875rem",
            fontWeight: "400",
        }}}
        focused
        color="secondary"

      />

    </form>
  );

  export default SearchBar