import React from 'react'
import PropTypes from 'prop-types'
import './actions.scss'
import DeleteIcon from '@mui/icons-material/Delete';


const DeleteAction = ({handleClick}) => {
  return (
    <div className='delete-action' onClick={handleClick} data_role='Deactivate'>
        <DeleteIcon />
    </div>
  )
}

export default DeleteAction

DeleteAction.propTypes = {
    handleClick: PropTypes.func.isRequired
}