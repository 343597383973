import styled from 'styled-components'
import Icon from '../../assets/Logo/user-icon.png'



export const SmallImage = styled.div`
width: 32px;
height: 32px;
border-radius: 50%;
background-image: url(${(props) =>props?.src}) ,url(${Icon});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
background-color: #D9D9D9;


`