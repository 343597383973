export const occurrence = [
    {name:"Occurrence Type", url: "settings/occurrence/type", 
        import: 'settings/occurrence/type/import_occurence_type_data'},

    {name:"Investigation Status", url: "settings/occurrence/investigation",
        import: 'settings/occurrence/operations/import_operation_type_data'},

    {name:"Occurrence Category", url: "settings/occurrence/category",
        import: 'settings/occurrence/category/import_occurence_category_data'},

    {name:"Report Status", url: "settings/occurrence/report", 
        import: 'settings/occurrence/report/import_report_status_data'},

    {name:"Highest Injury Level", url: "settings/occurrence/injury",
        import: 'settings/occurrence/injury/import_highest_level_injury_data'}, 

    {name:"Type of Operation", url: "settings/occurrence/operations",
        import: 'settings/occurrence/operations/import_operation_type_data'}, 

    {name:"Flight Phase", url: "settings/occurrence/flight-phase",
        import: 'settings/occurrence/flight-phase/import_flight_phase_data'},
]

export const aircraft = [
    {name: "Damage to Aircraft", url: "settings/aircraft-data/damages",
        import: 'settings/aircraft-data/aircraft/import_aircraft_type_data'},

    {name:"Aircraft Type" , url: 'settings/aircraft-data/aircraft', 
        import: 'settings/aircraft-data/aircraft/import_aircraft_type_data'}, 

    { name:"Aircraft Manufacturer", url: "settings/aircraft-data/manufacturer", 
        import: 'settings/aircraft-data/manufacturer/import_manufacture_data'}
]

export const API = [
    {name: "Airlabs", url: "settings/thirdparty-api/airlabs"}, 
    {name: "Aviation stack", url: "settings/thirdparty-api/aviation-stack"},
]

export const airline = [
    {name:"Airlines", url: "settings/datasets/airline",
        import: 'settings/datasets/airline/import_airline_data'}
]

export const location = [
    {name:"Country", url:"settings/location/country"}, 
    {name:"States", url:"settings/location/state"},
    {name:"Cities", url:"settings/location/city"}
]

export const airports = [
   {name: "Airports", url: "settings/datasets/airport", 
    import: 'settings/datasets/airport/import_airport_data'}
]


