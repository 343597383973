import { createBrowserRouter } from "react-router-dom";
import { NotLoggedIn } from "./components/auth/NotLoggedIn";
import {
  AircraftTemplateManger,
  DataSettings, ForgotPassword, Home,
  ListRoles, ListUsers, Login, NewRole,
  NewUser, ResetPassword, UserDashboard, AircraftCreationForm,
  DisplayAircraftData, ProfileSettings, User
} from "./themes";
import { Protected } from "./components/auth/Protected";
import { Dashboard } from "./pages/layout/Dashboard";
import { IdentityIndex } from "./pages/identities/IdentityIndex";
import { AddPrivilege } from "./pages/identities/privilege/AddPrivilege";
import { ListPrivileges } from "./pages/identities/privilege/ListPrivileges";
import { ViewPrivilege } from "./pages/identities/privilege/ViewPrivilege";
import { ViewRole } from "./pages/identities/role/ViewRole";
import Occurrence from "./components/data_settings/Occurrence/Occurrence";
import Aircraft from "./components/data_settings/AircraftDataSet/Aircraft";
import APISettings from "./components/data_settings/API/APISettings";
import Airline from "./components/data_settings/Airline/Airline";
import Airport from "./components/data_settings/Airport/Airport";
import LocationDataset from "./components/data_settings/Location/LocationDataSet";

export const routes = createBrowserRouter([
  {
    path: '',
    element: <NotLoggedIn><Login /></NotLoggedIn>,
  },

  {
    path: 'forgot-password',
    element: <ForgotPassword />
  },

  {
    path: 'reset-password',
    element: <ResetPassword />
  },

  {
    path: 'home',
    element: <Protected><Dashboard /></Protected>,

    children: [
      {
        path: '',
        element: <Home />
      }
    ]
  },

  // User module
  {
    path: 'users',
    element: <Protected> <Dashboard /> </Protected>,

    children: [
      {
        path: '',
        element: <UserDashboard />
      },

      {
        path: 'new',
        element: <NewUser />
      },

      {
        path: 'all',
        element: <ListUsers />
      },

      {
        path: 'roles',
        element: <ListRoles />
      },

      {
        path: 'roles/new',
        element: <NewRole />
      },

      {
        path: 'roles/:id',
        element: <ViewRole />
      },

      {
        path: ':id',
        element: <User />
      }
    ]
  },

  // Identity module
  {
    path: 'identities',
    element: <Protected><Dashboard /></Protected>,

    children: [
      {
        path: '',
        element: <IdentityIndex />
      },

      {
        path: 'roles',
        element: <ListRoles />
      },

      {
        path: 'roles/new',
        element: <NewRole />
      },

      {
        path: 'roles/:id',
        element: <ViewRole />
      },

      {
        path: 'privileges',
        element: <ListPrivileges />
      },

      {
        path: 'privileges/new',
        element: <AddPrivilege />
      },

      {
        path: 'privileges/:id',
        element: <ViewPrivilege />
      },

    ]
  },

  // Data setting module
  {
    path: 'data-settings',
    element: <Protected> <Dashboard /> </Protected>,

    children: [
      {
        path: '',
        element: <DataSettings />
      },

      {
        path: 'occurrence-creation-data-set',
        element: <Occurrence />
      },
      {
        path: 'aircraft-data-set',
        element: <Aircraft />
      },

      {
        path: 'api-settings',
        element: <APISettings />
      },

      {
        path: 'airline-data-set',
        element: <Airline />
      },

      {
        path: 'airport-data-set',
        element: <Airport />
      },

      {
        path: 'location-data-set',
        element: <LocationDataset />
      }
    ]
  },

  // Aircraft Template module
  {
    path: 'aircraft-template',
    element: <Protected> <Dashboard /> </Protected>,

    children: [
      {
        path: '',
        element: <AircraftTemplateManger />
      },

      {
        path: 'create',
        element: <AircraftCreationForm />
      },

      {
        path: ':id',
        element: <DisplayAircraftData />
      }
    ]
  },

  // Profile 
  {
    path: 'profile',
    element: <Protected><Dashboard /></Protected>,
    children: [
      {
        path: ':id',
        element: <ProfileSettings />
      }
    ]
  }
]);