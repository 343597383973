import { Dialog } from "@mui/material"
import './Dialog.scss'


/**
 *
 * @param {boolean} open
 * @param {function} handleClose
 * @param {string} title
 * @param {string} question
 * @param {boolean} deactivateStatus
 * @example

 }}
 * @returns
 */
const DeactivateDialog = ({ open, handleClose, title, question, deactivateStatus, isLoading, setOpen}) => {

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="data-deactivation"
        aria-describedby="data-deactivation"
        PaperProps={{ sx: { minHeight: 200, maxWidth: 'fit-content',
         display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around' }} }
         className="shadow-lg shadow-white"
      >
        <div className='dt-deactivate shadow-lg shadow-white'>
          <div>
            <h4 className="font-bold">{title}</h4>
            <p>{question}</p>
          </div>

          <div className=''>
            <button onClick={()=>setOpen(false)} className={ deactivateStatus ? 'px-[53px] border border-primary py-4 text-primary' : 'py-4 px-[53px] border border-red-700 bg-white text-red-700'}>No</button>
            <button onClick={handleClose} className={deactivateStatus ? 'bg-red-700 text-white py-4 px-[53px]' : 'py-4 px-[53px] bg-primary text-white'} type='submit'>{isLoading ? "Please wait...": "Yes"}</button>
          </div>
        </div>

      </Dialog>
    )



  }

  export default DeactivateDialog;