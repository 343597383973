import { createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { tags } from "../../utils/constants";

export const userApiSlice = apiSlice.injectEndpoints({
    
    endpoints: (builder) => ({

        registerUser: builder.mutation({
            query: body => ({
                url: '/user/',
                method: 'POST',
                body,
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.user]
        }),

        getCurrentUser: builder.query({
            query: () => '/user/',
            providesTags: [tags.profile],
            transformResponse: (response) => response.data
        }),

        loadUserProfile: builder.query({
            query: (id) => `user/${id}/`,
            headers: {superadmin: 'superadmin'},
            transformResponse: (response) => response.data
        }),
        
        getAllUsers: builder.query({
            query: () => '/user/allusers/',
        }),

        getUsersByPage: builder.query({
            query: (page = 1)   => ({
                url: `/user/?page=${page}`,
                method: 'GET',
                params: {page},
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: [tags.user], 
        }),

        updateProfileImage: builder.mutation({
            query: body => ({
                url: `user/update_profile_picture/`,
                method: 'PUT',
                body,
                headers: {superadmin: 'superadmin'},
            }),
        }),

        getUser: builder.query({
            query: id => ({
                url:  `user/${id}/`,
                headers: {superadmin: 'superadmin'}
            }),
        }),

        updateUser: builder.mutation({
            query: data => {
                const {id, form} = data;
                return ({
                    url: `/user/${id}/`,
                    method: 'PATCH',
                    body: form,
                    headers: {superadmin: 'superadmin'}
                })
            },
            invalidatesTags: [tags.profile]
        }),

        deactivateUser: builder.query({
            query: id => ({
                url: `/user/${id}/deactivate_user/`,
                method: 'GET',
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.user]
        }),

        activateUser: builder.query({
            query: id => ({
                url: `/user/${id}/activate_user/`,
                method: 'GET',
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.user]
        }),

        getUserPermissions: builder.query({
            query: () => '/user/role-permission/',
            providesTags: ['userPermissions'],
            keepUnusedDataFor: 240,
        }),

        postUserRolePermissions: builder.mutation({
            query: body => ({
                url: `/user/role-permission/`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['userPermissions']
        }),

        getRolesById: builder.query({
            query: id => `/user/role-permission/${id}/`,
        }),

        updateRoleName: builder.mutation({
            query: body => ({
                url: `/user/role-permission/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
            invalidatesTags: ['userPermissions']
        }),

        getAllUsersGroup : builder.query({
            query: () => '/user/allusersgroup/',
        }),
    })
})

// selectors
const usersResult = userApiSlice.endpoints.getUsersByPage.select()
export const selectUsers = createSelector(usersResult, (state) => state.data?.data);

const profileResult = userApiSlice.endpoints.getCurrentUser.select()
export const selectProfile = createSelector(profileResult, (state) => {
    return state.data?.length ? state.data[0] : null
});


// Hooks
export const { useRegisterUserMutation, useGetCurrentUserQuery,
    useGetAllUsersQuery, useGetUserQuery, useUpdateUserMutation, useGetUserPermissionsQuery, 
    usePostUserRolePermissionsMutation, useGetRolesByIdQuery, useUpdateRoleNameMutation, 
    useGetUsersByPageQuery, useGetAllUsersGroupQuery, useLoadUserProfileQuery, useUpdateProfileImageMutation,
    useActivateUserQuery, useDeactivateUserQuery } = userApiSlice;


