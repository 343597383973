import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { selectRoles, useGetRolesQuery } from '../../features/identity/identityApiSlice';

/**
 *
 * @param {Object} props
 * @param {import('react-hook-form').Register} props.register
 * @param {import('react-hook-form').SetValue} props.setValue
 * @param {String} props.title
 * @param {Boolean} props.borderBottom
 * @param {String} props.placeholder
 * @returns {JSX.Element}
 * @example <SelectRole register={register} setValue={setValue} title="Role" borderBottom={true} placeholder="Select Role(s)"/>
 *
 *
 */
const SelectRole = ({ register, setValue, title, borderBottom, placeholder = "Select Role(s)", defaults = []}) => {

    const {isLoading} = useGetRolesQuery()
    const roles = useSelector(selectRoles);

    const [open, setOpen] = useState(false)
    const [selectedRole, setSelectedRole] = useState(defaults)
    const [initialRender, setInitialRender] = useState(borderBottom)

    useEffect(() => {
        if(defaults.length) {
            setSelectedRole(defaults)
        }
        // setSelectedRole(prev => {
        //     // check if userRoles is an array
        //    return  typeof userRoles === typeof [] ?
        //    // if it is an array, concat the array to the previous state
        //      [...prev, ...userRoles] :
        //         // if it is not an array, simply return the previous state
        //      [...prev]} )
    }, [defaults])

    const handleSelection = (data) => {
            setSelectedRole(
                prev => {
                    const filteredArray = prev.filter(item => item !== data)
                    let newRole =  [...filteredArray, data]
                    // cancat all items in array to a string
                    const string = newRole.join(',')
                    setValue('role', string)
                    return newRole 
                })

        setOpen(false)
        setInitialRender(false)
    }

    const handleRemoveSelection = (data) => {
        setSelectedRole(
            prev => {
                const filteredArray = prev.filter(item => item !== data)
                return filteredArray
            })
        setOpen(false)
    }

    const checkActive = role => {
        return selectedRole.find(rol => role === rol)
    }

    const handleClick = ()=> setOpen(!open)

    return (
        <div className='form title'>

            <label htmlFor={title} className='form-label'> {title} </label>
            <div className='select-role' style={{border:selectedRole.length && !initialRender  ? "2px solid #0f62fe":"", borderBlockEnd: borderBottom ? "1px solid #0F62FE":''}}>
            
                <div className='select-role-header' style={{background: borderBottom ? "#fff":"#f4f4f4" }} >
                    <input  {...register('datarole')} name='datarole' disabled placeholder={placeholder} className='capitalize'/>

                    <div className='selected-role'>
                        {selectedRole.length > 0 ? selectedRole?.map((item, index) => {
                            return (
                                <div key={index}className='selected-role-list flex' >
                                    <p className={`${open && 'remove_background'} capitalize`} >{item}</p>
                                    {open && <HighlightOffIcon onClick={() => handleRemoveSelection(item)} />}
                                </div>
                            )
                        }) :<p style={{paddingBlockStart:".5rem" , color:"#7e7e7e", fontSize:"0.875rem"}}> {"Select Role(s)"}</p>}
                    </div>

                    {
                        borderBottom 
                            ? null
                            : <div className={'add-icon bg-primary'} onClick={handleClick} ><AddIcon /></div>
                    }
                </div>

                { 
                    open && 
                        <div className='list-roles'>
                            {roles?.map((model, index) =>
                                <p className={checkActive(model.role) && 'active-tab'}
                                key={index} onClick={() => checkActive(model.role) ? handleRemoveSelection(model.role) : handleSelection(model.role)} >
                                    {model.role}</p>
                            )}
                        </div>
                }

            </div>
        </div>
    )
}

export default SelectRole