
import React from 'react'
import { DataSettingsTitle } from '../../components/settingstitle/DataSettingsTitle'
// import DataSettingsHeader from '../../components/settingstitle/DataSettingsHeader'
import './dataSettings.scss'
import Grid from '@mui/material/Grid'
import IconBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
// import Paper from '@mui/material/Paper'
// import { Link } from 'react-router-dom'
// import OccurrenceCreation from '../../components/occuencecreation/OccurrenceCreation'
import { occurrence, aircraft, airline, airports, location, API } from '../../components/data_settings/dataset'
import {AiOutlineHome} from 'react-icons/ai'
import SchemaIcon from '@mui/icons-material/SchemaOutlined';
import { HeaderContainer } from '../../components/header/Header.style'
import {useOccurrenceTypeGetQuery , useInvestigationStatusGetQuery  , useOccurrenceCategoryGetQuery ,
    useReportStatusGetQuery, useHighestInjuryLevelGetQuery , useTypeOfOperationGetQuery, useFlightPhaseGetQuery} from '../../features/data/dataSettingsApiSlice'
import { setOccurrenceDataSet  } from '../../features/data/dataSettingsSlice'
import { useDispatch , useSelector } from 'react-redux'


const DataSettings = () => {

    const dispatch = useDispatch()
    const data = [
        {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
        {name: 'Data Settings', link: '/data-settings' , Icon:SchemaIcon},
    ]

    // const {data:occurrenceType , isLoading: occurrenceTypeLoading , error:occurrenceTypeError , isFetching:occurrenceTypeRefetch
    // } = useOccurrenceTypeGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:investigationStatus , isLoading: investigationStatusLoading , error:investigationStatusError , isFetching:investigationStatusRefetch
    // } = useInvestigationStatusGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:occurrenceCategory, isLoading: occurrenceCategoryLoading , error:occurrenceCategoryError , isFetching:occurrenceCategoryRefetch
    // } = useOccurrenceCategoryGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:reportStatus, isLoading: reportStatusLoading , error:reportStatusError , isFetching:reportStatusRefetch
    // } = useReportStatusGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:highestInjuryLevel, isLoading: highestInjuryLevelLoading , error:highestInjuryLevelError , isFetching:highestInjuryLevelRefetch
    // } = useHighestInjuryLevelGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:typeOfOperation, isLoading: typeOfOperationLoading , error:typeOfOperationError , isFetching:typeOfOperationRefetch
    // } = useTypeOfOperationGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )
    // const {data:flightPhase, isLoading: flightPhaseLoading , error:flightPhaseError , isFetching:flightPhaseRefetch
    // } = useFlightPhaseGetQuery(
    //     undefined, {refetchOnMountOrArgChange: true}
    // )

    // React.useEffect(() => {

    //     dispatch(setOccurrenceDataSet({occurrenceType : {data: occurrenceType, loading: occurrenceTypeLoading, error: occurrenceTypeError, isFetching: occurrenceTypeRefetch}
    //         ,  investigationStatus: {data: investigationStatus, loading: investigationStatusLoading, error: investigationStatusError, isFetching: investigationStatusRefetch}
    //         ,  occurrenceCategory : {data: occurrenceCategory, loading: occurrenceCategoryLoading, error: occurrenceCategoryError, isFetching: occurrenceCategoryRefetch}
    //         , reportStatus: {data: reportStatus, loading: reportStatusLoading, error: reportStatusError, isFetching: reportStatusRefetch}
    //         , highestInjuryLevel : {data: highestInjuryLevel, loading: highestInjuryLevelLoading, error: highestInjuryLevelError, isFetching: highestInjuryLevelRefetch}
    //         , typeOfOperation: {data: typeOfOperation, loading: typeOfOperationLoading, error: typeOfOperationError, isFetching: typeOfOperationRefetch}
    //         , flightPhase: {data: flightPhase, loading: flightPhaseLoading, error: flightPhaseError, isFetching: flightPhaseRefetch}
    //         }))

    // }, [occurrenceType, investigationStatus, occurrenceCategory, reportStatus, highestInjuryLevel, typeOfOperation, flightPhase, occurrenceTypeLoading, investigationStatusLoading, occurrenceCategoryLoading, reportStatusLoading, highestInjuryLevelLoading, typeOfOperationLoading, flightPhaseLoading, occurrenceTypeError, investigationStatusError, occurrenceCategoryError, reportStatusError, highestInjuryLevelError, typeOfOperationError, flightPhaseError, occurrenceTypeRefetch, investigationStatusRefetch, occurrenceCategoryRefetch, reportStatusRefetch, highestInjuryLevelRefetch, typeOfOperationRefetch, flightPhaseRefetch, dispatch]
    // )

// const {occurrenceDataSet = {}} = useSelector(state => state.dataset)
// calculate the total number of dataset in the system
// const totalDataset = Object.keys(occurrenceDataSet).reduce((acc, key) => {
//     return acc + occurrenceDataSet[key]?.data?.length || 0
// }, 0)

    const details = [
        {
            id: 1,
            title: "Occurrence Creation Data Set",
            subtitle: 'Manage your data set',
            dataset: occurrence.length,
        },

        {
            id: 2,
            title: "Aircraft Data Set",
            subtitle: 'Add a new role to your system',
            dataset: aircraft.length,
        },

        {
            id: 3,
            title: "API Settings",
            subtitle: 'Add a new role to your system',
            dataset:API.length
        },

        {
            id: 4,
            title: "Airline Data Set",
            subtitle: 'Add a new role to your system',
            dataset: airline.length,
        },

        {
            id: 5,
            title: "Location Data Set",
            subtitle: 'Add a new role to your system',
            dataset: location.length
        },

        {
            id: 6,
            title: "Airport Data Set",
            subtitle: 'Add a new role to your system',
            dataset: airports.length
        }
    ]

    return (
        <>
            <div className='px-3 lg:px-10'>
                <IconBreadcrumbs data={data} />
                <HeaderContainer>
                    <SchemaIcon />
                    <h2>Data Set Settings</h2>
                </HeaderContainer>
                <Grid container spacing="48">
                    {
                        details.map(detail =>
                            <DataSettingsTitle number={detail.dataset} title={detail.title} subtitle={detail.subtitle} key={detail.id} />
                        )
                    }
                </Grid>
            </div>
        </>

    )
}

export default DataSettings
