import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    user: {},
    users: [],
    editActive: false,
    roles: [],
    permissions: [],
    allUsersGroup: [],
    searchResults: "",
}

const userSlice = createSlice({
    name: 'user',
    initialState,

    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload
        },

        setAllUsers: (state, { payload }) => {
            state.users = payload
        },
        setEditActive: (state, { payload }) => {
            state.editActive = payload
        },
        setRoles: (state, { payload }) => {
            state.permissions = payload
        },
        setRole: (state, { payload }) => {
            state.roles = payload
        },
        setAllUsersGroup: (state, { payload }) => {
            state.allUsersGroup = payload
        },
        setSearchResults:(state,{payload}) =>{
            state.searchResults = payload
        }
    }

})


export const { setUser, setAllUsers, setEditActive, setRoles , setRole , setAllUsersGroup , setSearchResults} = userSlice.actions
export const selectUser = state => state.user
export default userSlice.reducer

