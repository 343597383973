import React from 'react'
import CreateAircraftModel from '../../components/aircraftmanager/CreateAircraftModel'
import ListAircraftModels from './view/ListAircraftModels'

const AircraftTemplateManager = () => {
  return (
    <div className='px-3 lg:px-10'>
       <CreateAircraftModel />
    </div>
  )
}

export default AircraftTemplateManager