import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from '@emotion/styled';


const LongMenu = ({ options, setValue, arrLength, className}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        setValue((prevVal) => e.target.innerText ? e.target.innerText : prevVal)
        setAnchorEl(null)
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                sx={{ minWidth: '0px', width: 'min-content' }}
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVertIcon className={className} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                      width: '20ch',
                    },
                  }}
            >
                <div>
                    <SmallParagraph>Show up to</SmallParagraph>
                    {options.map((option) => (


                        <MenuItem key={option} selected={option === options[0]} onClick={(e)=>{ handleClose(e)}}>
                            {option}
                        </MenuItem>

                    ))}
                </div>
            </Menu>
        </div>
    );
}


const SmallParagraph = styled.p`
  text-align:center;
  font-size:12px;
  color:inherit;
`

export default LongMenu
