import './sidebar.scss'

import { Drawer } from './SideDrawer';
import ArgueBlue from '../../assets/Logo/ArgueBlue2.svg'
import Argue from '../../assets/Logo/argue.png'
import Report from '../../assets/Logo/report.png'
import React from 'react'
import DrawerList from './DrawerList';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';




const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };




  return (
    <>
      <Drawer as={`aside`} variant="permanent" open={open}>
        <div className='mt-[32px] md:ml-[24px] flex gap-1 items-center'>
          <img src={Argue} alt="" className='w-[75px] h-[20px] transition-all ' />
          {open &&
            <img src={Report} alt="" className='h-[20px] transition-all' />
          }
        </div>
        <List component="nav" className={`transition-all ${open ? 'md:mx-4' : 'md:mx-6'} flex flex-col justify-between h-[90vh]`}>

          <DrawerList open={open} pathName={pathname} />

          <div className='mb-10 -ml-2'>
            <div className='flex gap-2 justify-center items-center'>
              <IconButton onClick={toggleDrawer} className='p-0'>
                {open ?
                  <ArrowCircleLeftOutlinedIcon className='text-black w-8 h-8' />
                  :
                  <ArrowCircleRightOutlinedIcon className='text-black w-8 h-8' />
                }

              </IconButton>
              {open &&
                <p className='text-sm text-black'>Collapse Menu</p>
              }
            </div>
          </div>
        </List>
      </Drawer>
    </>
  )
}

export default Sidebar