import { apiSlice } from "../../app/api/apiSlice";
import { occurrence, aircraft, airline, location, airports } from '../../components/data_settings/dataset'

const query = (url) => {
    return {
        url: `${url}/`,
        method: 'GET'
    }
}

const mutation = (url, method, data) => {
    return {
        url: `${url}/`,
        method,
        body: data
    }
}

const idMutation = (url, id, method, body) => {
    return {
        url: `${url}/${id}/`,
        method,
        body: body.data
    }
}

const idQuery = (url, id) => {
    return {
        url: `${url}/${id}/`,
        method: 'GET'
    }
}

const dataSettingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        //Occurrence data settings
        // get occurrence type
        // GET /api/settings/occurrencetype/
        occurrenceTypeGet: builder.query({
            query: () => query(occurrence[0].url),
            // keepUnusedDataFor 30 minutes
            keepUnusedDataFor: 30 * 60 * 1000,
            providesTags: ['occurrenceType'],
        }),

        // POST /api/settings/occurrencetype/
        occurrenceTypePost: builder.mutation({
            query: (body) => mutation(occurrence[0].url, 'POST', body),
            invalidatesTags: ['occurrenceType']
        }),
        
        // PUT /api/settings/occurrencetype/:id/
        occurrenceTypePut: builder.mutation({
            query: (body) => idMutation(occurrence[0].url, body.id, 'PUT', body),
            invalidatesTags: ['occurrenceType']
        }),
        // DELETE /api/settings/occurrencetype/:id/
        occurrenceTypeDelete: builder.mutation({
            query: (body) => idMutation(occurrence[0].url, body.id, 'DELETE', body),
            invalidatesTags: ['occurrenceType']
        }),
        // PATCH /api/settings/occurrencetype/:id/
        occurrenceTypePatch: builder.mutation({
            query: (body) => idMutation(occurrence[0].url, body.id, 'PATCH', body),
            invalidatesTags: ['occurrenceType']
        }),
        occurrenceTypeGetId: builder.query({
            query: (id) => idQuery(occurrence[0].url, id),
        }),

        // get investigation status
        // GET /api/settings/investigationstatus/
        investigationStatusGet: builder.query({
            query: () => query(occurrence[1].url),
            providesTags: ['investigationStatus'],
            keepUnusedDataFor: 30 * 60 * 1000,

        }),
        // POST /api/settings/investigationstatus/
        investigationStatusPost: builder.mutation({
            query: (body) => mutation(occurrence[1].url, 'POST', body),
            invalidatesTags: ['investigationStatus']
        }),
        // PUT /api/settings/investigationstatus/:id/
        investigationStatusPut: builder.mutation({
            query: (body) => idMutation(occurrence[1].url, body.id, 'PUT', body),
            invalidatesTags: ['investigationStatus']
        }),
        // DELETE /api/settings/investigationstatus/:id/
        investigationStatusDelete: builder.mutation({
            query: (body) => idMutation(occurrence[1].url, body.id, 'DELETE', body),
            invalidatesTags: ['investigationStatus']
        }),
        // PATCH /api/settings/investigationstatus/:id/
        investigationStatusPatch: builder.mutation({
            query: (body) => idMutation(occurrence[1].url, body.id, 'PATCH', body),
            invalidatesTags: ['investigationStatus']
        }),
        // get occurrence status
        // GET /api/settings/occurrencecategory/
        occurrenceCategoryGet: builder.query({
            query: () => query(occurrence[2].url),
            providesTags: ['occurrenceCategory'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/occurrencecategory/
        occurrenceCategoryPost: builder.mutation({
            query: (body) => mutation(occurrence[2].url, 'POST', body),
            invalidatesTags: ['occurrenceCategory']
        }),
        // PUT /api/settings/occurrencecategory/:id/
        occurrenceCategoryPut: builder.mutation({
            query: (body) => idMutation(occurrence[2].url, body.id, 'PUT', body),
            invalidatesTags: ['occurrenceCategory']
        }),
        // DELETE /api/settings/occurrencecategory/:id/
        occurrenceCategoryDelete: builder.mutation({
            query: (body) => idMutation(occurrence[2].url, body.id, 'DELETE', body),
            invalidatesTags: ['occurrenceCategory']
        }),
        // PATCH /api/settings/occurrencecategory/:id/
        occurrenceCategoryPatch: builder.mutation({
            query: (body) => idMutation(occurrence[2].url, body.id, 'PATCH', body),
            invalidatesTags: ['occurrenceCategory']
        }),
        // get report status
        // GET /api/settings/reportstatus/
        reportStatusGet: builder.query({
            query: () => query(occurrence[3].url),
            providesTags: ['reportStatus'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/reportstatus/
        reportStatusPost: builder.mutation({
            query: (body) => mutation(occurrence[3].url, 'POST', body),
            invalidatesTags: ['reportStatus']
        }),
        // PUT /api/settings/reportstatus/:id/
        reportStatusPut: builder.mutation({
            query: (body) => idMutation(occurrence[3].url, body.id, 'PUT', body),
            invalidatesTags: ['reportStatus']
        }),
        // DELETE /api/settings/reportstatus/:id/
        reportStatusDelete: builder.mutation({
            query: (body) => idMutation(occurrence[3].url, body.id, 'DELETE', body),
            invalidatesTags: ['reportStatus']
        }),
        // PATCH /api/settings/reportstatus/:id/
        reportStatusPatch: builder.mutation({
            query: (body) => idMutation(occurrence[3].url, body.id, 'PATCH', body),
            invalidatesTags: ['reportStatus']
        }),

        //get highest injury level
        // GET /api/settings/highestinjurylevel/
        highestInjuryLevelGet: builder.query({
            query: () => query(occurrence[4].url),
            providesTags: ['highestInjuryLevel'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/highestinjurylevel/
        highestInjuryLevelPost: builder.mutation({
            query: (body) => mutation(occurrence[4].url, 'POST', body),
            invalidatesTags: ['highestInjuryLevel']
        }),
        // PUT /api/settings/highestinjurylevel/:id/
        highestInjuryLevelPut: builder.mutation({
            query: (body) => idMutation(occurrence[4].url, body.id, 'PUT', body),
            invalidatesTags: ['highestInjuryLevel']
        }),
        // DELETE /api/settings/highestinjurylevel/:id/
        highestInjuryLevelDelete: builder.mutation({
            query: (body) => idMutation(occurrence[4].url, body.id, 'DELETE', body),
            invalidatesTags: ['highestInjuryLevel']
        }),
        // PATCH /api/settings/highestinjurylevel/:id/
        highestInjuryLevelPatch: builder.mutation({
            query: (body) => idMutation(occurrence[4].url, body.id, 'PATCH', body),
            invalidatesTags: ['highestInjuryLevel']
        }),
        // get type of operation
        // GET /api/settings/typeofoperation/
        typeOfOperationGet: builder.query({
            query: () => query(occurrence[5].url),
            providesTags: ['typeOfOperation'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/typeofoperation/
        typeOfOperationPost: builder.mutation({
            query: (body) => mutation(occurrence[5].url, 'POST', body),
            invalidatesTags: ['typeOfOperation']
        }),
        // PUT /api/settings/typeofoperation/:id/
        typeOfOperationPut: builder.mutation({
            query: (body) => idMutation(occurrence[5].url, body.id, 'PUT', body),
            invalidatesTags: ['typeOfOperation']
        }),
        // DELETE /api/settings/typeofoperation/:id/
        typeOfOperationDelete: builder.mutation({
            query: (body) => idMutation(occurrence[5].url, body.id, 'DELETE', body),
            invalidatesTags: ['typeOfOperation']
        }),
        // PATCH /api/settings/typeofoperation/:id/
        typeOfOperationPatch: builder.mutation({
            query: (body) => idMutation(occurrence[5].url, body.id, 'PATCH', body),
            invalidatesTags: ['typeOfOperation']
        }),
        // get flight phase
        // GET /api/settings/flightphase/
        flightPhaseGet: builder.query({
            query: () => query(occurrence[6].url),
            providesTags: ['flightPhase'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/flightphase/
        flightPhasePost: builder.mutation({
            query: (body) => mutation(occurrence[6].url, 'POST', body),
            invalidatesTags: ['flightPhase']
        }),
        // PUT /api/settings/flightphase/:id/
        flightPhasePut: builder.mutation({
            query: (body) => idMutation(occurrence[6].url, body.id, 'PUT', body),
            invalidatesTags: ['flightPhase']
        }),
        // DELETE /api/settings/flightphase/:id/
        flightPhaseDelete: builder.mutation({
            query: (body) => idMutation(occurrence[6].url, body.id, 'DELETE', body),
            invalidatesTags: ['flightPhase']
        }),
        // PATCH /api/settings/flightphase/:id/
        flightPhasePatch: builder.mutation({
            query: (body) => idMutation(occurrence[6].url, body.id, 'PATCH', body),
            invalidatesTags: ['flightPhase']
        }),


        //Aircraft data settings
        // get damage to aircraft
        // GET /api/settings/damagetoaircraft/
        damageToAircraftGet: builder.query({
            query: () => query(aircraft[0].url),
            providesTags: ['damageToAircraft'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/damagetoaircraft/
        damageToAircraftPost: builder.mutation({
            query: (body) => mutation(aircraft[0].url, 'POST', body),
            invalidatesTags: ['damageToAircraft']
        }),
        // PUT /api/settings/damagetoaircraft/:id/
        damageToAircraftPut: builder.mutation({
            query: (body) => idMutation(aircraft[0].url, body.id, 'PUT', body),
            invalidatesTags: ['damageToAircraft']
        }),
        // DELETE /api/settings/damagetoaircraft/:id/
        damageToAircraftDelete: builder.mutation({
            query: (body) => idMutation(aircraft[0].url, body.id, 'DELETE', body),
            invalidatesTags: ['damageToAircraft']
        }),
        // PATCH /api/settings/damagetoaircraft/:id/
        damageToAircraftPatch: builder.mutation({
            query: (body) => idMutation(aircraft[0].url, body.id, 'PATCH', body),
            invalidatesTags: ['damageToAircraft']
        }),
        // get aircraft type
        // GET /api/settings/aircrafttype/
        aircraftTypeGet: builder.query({
            query: () => query(aircraft[1].url),
            providesTags: ['aircraftType'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/aircrafttype/
        aircraftTypePost: builder.mutation({
            query: (body) => mutation(aircraft[1].url, 'POST', body),
            invalidatesTags: ['aircraftType']
        }),
        // PUT /api/settings/aircrafttype/:id/
        aircraftTypePut: builder.mutation({
            query: (body) => idMutation(aircraft[1].url, body.id, 'PUT', body),
            invalidatesTags: ['aircraftType']
        }),
        // DELETE /api/settings/aircrafttype/:id/
        aircraftTypeDelete: builder.mutation({
            query: (body) => idMutation(aircraft[1].url, body.id, 'DELETE', body),
            invalidatesTags: ['aircraftType']
        }),
        // PATCH /api/settings/aircrafttype/:id/
        aircraftTypePatch: builder.mutation({
            query: (body) => idMutation(aircraft[1].url, body.id, 'PATCH', body),
            invalidatesTags: ['aircraftType']
        }),

        //aircraft manufacturer
        aircraftManufacturerGet: builder.query({
            query: () => query(aircraft[2].url),
            providesTags: ["manufacturer"],
            keepUnusedDataFor: 30 * 60 * 1000
        }),

        //POST /api/settings/aircraftmanufacturer

        // POST /api/settings/aircrafttype/
        aircraftManufacturerPost: builder.mutation({
            query: (body) => mutation(aircraft[2].url, 'POST', body),
            invalidatesTags: ['manufacturer']
        }),
        // PUT /api/settings/aircraftManufacturer/:id/
        aircraftManufacturerPut: builder.mutation({
            query: (body) => idMutation(aircraft[2].url, body.id, 'PUT', body),
            invalidatesTags: ['manufacturer']
        }),
        // DELETE /api/settings/aircraftManufacturer/:id/
        aircraftManufacturerDelete: builder.mutation({
            query: (body) => idMutation(aircraft[2].url, body.id, 'DELETE', body),
            invalidatesTags: ['manufacturer']
        }),
        // PATCH /api/settings/aircraftManufacturer/:id/
        aircraftManufacturerPatch: builder.mutation({
            query: (body) => idMutation(aircraft[2].url, body.id, 'PATCH', body),
            invalidatesTags: ['manufacturer']
        }),



        //airline data settings
        // get airline
        // GET /api/settings/airplane/
        airlineGet: builder.query({
            query: () => query(airline[0].url),
            providesTags: ['airline'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/airplane/
        airlinePost: builder.mutation({
            query: (body) => mutation(airline[0].url, 'POST', body),
            invalidatesTags: ['airline']
        }),
        // PUT /api/settings/airplane/:id/
        airlinePut: builder.mutation({
            query: (body) => idMutation(airline[0].url, body.id, 'PUT', body),
            invalidatesTags: ['airline']
        }),
        // DELETE /api/settings/airplane/:id/
        airlineDelete: builder.mutation({
            query: (body) => idMutation(airline[0].url, body.id, 'DELETE', body),
            invalidatesTags: ['airline']
        }),
        // PATCH /api/settings/airplane/:id/
        airlinePatch: builder.mutation({
            query: (body) => idMutation(airline[0].url, body.id, 'PATCH', body),
            invalidatesTags: ['airline']
        }),


        //location data settings
        // get country
        // GET /api/settings/country/
        countryGet: builder.query({
            query: () => query(location[0].url),
            providesTags: ['country'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/country/
        countryPost: builder.mutation({
            query: (body) => mutation(location[0].url, 'POST', body),
            invalidatesTags: ['country']
        }),
        // PUT /api/settings/country/:id/
        countryPut: builder.mutation({
            query: (body) => idMutation(location[0].url, body.id, 'PUT', body),
            invalidatesTags: ['country']
        }),
        // DELETE /api/settings/country/:id/
        countryDelete: builder.mutation({
            query: (body) => idMutation(location[0].url, body.id, 'DELETE', body),
            invalidatesTags: ['country']
        }),
        // PATCH /api/settings/country/:id/
        countryPatch: builder.mutation({
            query: (body) => idMutation(location[0].url, body.id, 'PATCH', body),
            invalidatesTags: ['country']
        }),
        // get state
        // GET /api/settings/state/
        stateGet: builder.query({
            query: () => query(location[1].url),
            providesTags: ['state'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/state/
        statePost: builder.mutation({
            query: (body) => mutation(location[1].url, 'POST', body),
            invalidatesTags: ['state']
        }),
        // PUT /api/settings/state/:id/
        statePut: builder.mutation({
            query: (body) => idMutation(location[1].url, body.id, 'PUT', body),
            invalidatesTags: ['state']
        }),
        // DELETE /api/settings/state/:id/
        stateDelete: builder.mutation({
            query: (body) => idMutation(location[1].url, body.id, 'DELETE', body),
            invalidatesTags: ['state']
        }),
        // PATCH /api/settings/state/:id/
        statePatch: builder.mutation({
            query: (body) => idMutation(location[1].url, body.id, 'PATCH', body),
            invalidatesTags: ['state']
        }),
        // get city
        // GET /api/settings/city/
        cityGet: builder.query({
            query: () => query(location[2].url),
            providesTags: ['city'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/city/
        cityPost: builder.mutation({
            query: (body) => mutation(location[2].url, 'POST', body),
            invalidatesTags: ['city']
        }),
        // PUT /api/settings/city/:id/
        cityPut: builder.mutation({
            query: (body) => idMutation(location[2].url, body.id, 'PUT', body),
            invalidatesTags: ['city']
        }),
        // DELETE /api/settings/city/:id/
        cityDelete: builder.mutation({
            query: (body) => idMutation(location[2].url, body.id, 'DELETE', body),
            invalidatesTags: ['city']
        }),
        // PATCH /api/settings/city/:id/
        cityPatch: builder.mutation({
            query: (body) => idMutation(location[2].url, body.id, 'PATCH', body),
            invalidatesTags: ['city']
        }),
        // get airport
        // GET /api/settings/airport/
        airportGet: builder.query({
            query: () => query(airports[0].url),
            providesTags: ['airport'],
            keepUnusedDataFor: 30 * 60 * 1000,
        }),
        // POST /api/settings/airport/
        airportPost: builder.mutation({
            query: (body) => mutation(airports[0].url, 'POST', body),
            invalidatesTags: ['airport']
        }),
        // PUT /api/settings/airport/:id/
        airportPut: builder.mutation({
            query: (body) => idMutation(airports[0].url, body.id, 'PUT', body),
            invalidatesTags: ['airport']
        }),
        // DELETE /api/settings/airport/:id/
        airportDelete: builder.mutation({
            query: (body) => idMutation(airports[0].url, body.id, 'DELETE', body),
            invalidatesTags: ['airport']
        }),
        // PATCH /api/settings/airport/:id/
        airportPatch: builder.mutation({
            query: (body) => idMutation(airports[0].url, body.id, 'PATCH', body),
            invalidatesTags: ['airport']
        }),
        // get airport
        // GET /api/settings/airport/

        //get by id
        //investigation status
        investigationStatusGetById: builder.query({
            query: body => idQuery(occurrence[1].url, body.id),
            providesTags: ['investigationStatus']
        }),
        //occurrence category
        occurrenceCategoryGetById: builder.query({
            query: body => idQuery(occurrence[2].url, body.id),
            providesTags: ['occurrenceCategory']
        }),
        //report status
        reportStatusGetById: builder.query({
            query: body => idQuery(occurrence[3].url, body.id),
            providesTags: ['reportStatus']
        }),
        //higest injury level
        higestInjuryLevelGetById: builder.query({
            query: body => idQuery(occurrence[4].url, body.id),
            providesTags: ['higestInjuryLevel']
        }),
        //type of operation
        typeOfOperationGetById: builder.query({
            query: body => idQuery(occurrence[5].url, body.id),
            providesTags: ['typeOfOperation']
        }),
        //flight phase
        flightPhaseGetById: builder.query({
            query: body => idQuery(occurrence[6].url, body.id),
            providesTags: ['flightPhase']
        }),
        //occurrence type

        // aircraft data settings
        // GETbyId /api/settings/aircraftdata/:id/
        damageToAircraftGetById: builder.query({
            query: body => idQuery(aircraft[0].url, body.id),
            providesTags: ['damageToAircraft']
        }),
        // aircraft type
        // GETbyId /api/settings/aircrafttype/:id/
        aircraftTypeGetById: builder.query({
            query: body => idQuery(aircraft[1].url, body.id),
            providesTags: ['aircraftType']
        }),

        //airline
        // GETbyId /api/settings/airline/:id/
        airlineGetById: builder.query({
            query: body => idQuery(aircraft[2].url, body.id),
            providesTags: ['airline']
        }),

        //location
        countryGetById: builder.query({
            query: body => idQuery(location[0].url, body.id),
            providesTags: ['country']
        }),
        stateGetById: builder.query({
            query: body => idQuery(location[1].url, body.id),
            providesTags: ['state']
        }),
        cityGetById: builder.query({
            query: body => idQuery(location[2].url, body.id),
            providesTags: ['city']
        }),

        //airport
        airportGetById: builder.query({
            query: body => idQuery(airports[0].url, body.id),
            providesTags: ['airport']
        }),






















        // // GET /api/settings/<dynamic_url>/
        // getAll: builder.query({
        //     query: body => ({
        //        url: `${body.url}/`,
        //        method:'GET'
        //     }),
        //    // dynamic invalidation
        //    invalidatesTags: (result, error, arg) => {
        //     if (result) {
        //         return ['dataset']
        //     }

        // }


        // }),
        // // Get /api/settings/<dynamic_url>/:id/
        // getById: builder.query({
        //     query: body => `${body.url}/${body.id}/`
        // }),

        // // POST /api/settings/<dynamic_url>/
        // create: builder.mutation({
        //     query: body => ({
        //         url: `/${body.url}/`,
        //         method: 'POST',
        //         body: body.data
        //     }),
        //     invalidatesTags:['dataset']


        // }),

        // //Update any data settings
        // // PUT /api/settings/<dynamic_url>/:id
        // update: builder.mutation({
        //     query: body => ({
        //         url: `${body.url}/${body.id}/`,
        //         method: 'PUT',
        //         body: body.data
        //     }),
        //     invalidatesTags:['dataset']

        // }),

        // // DELETE /api/settings/<dynamic_url>/:id/
        // deleteById: builder.mutation({
        //     query: body => ({
        //         url:`${body.url}/${body.id}/`,
        //         method:'DELETE',
        //         body: body.data
        //     }),
        //     invalidatesTags:['dataset']
        // }),

        // // PATCH /api/settings/<dynamic_url>/:id/
        patchById: builder.mutation({
            query: body => ({
                url: `${body.url}/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
            invalidatesTags: ['dataset']
        }),


        //API Settings "Airlabs" and "Aviation Stack"
        //GET Airlab /api/settings/airlab/
        airlabsGet: builder.query({
            query: () => "settings/airlab/",
            // keepUnusedDataFor 1 hour
            keepUnusedDataFor: 60 * 60 * 1000,
        }),


        //POST Airlab /api/settings/airlab/
        airlabsPost: builder.mutation({
            query: body => ({
                url: "settings/airlab/",
                method: 'POST',
                body: {
                    api_key: body.airlab_api_key
                }
            }),
        }),

        //Airlab Delete /api/settings/airlab/:id/
        airlabsDeleteById: builder.mutation({
            query: (id) => ({
                url: `settings/aviationstack/${id}/`,
                method: 'DELETE',

            }),
        }),

        // Airlab put /api/settings/airlab/:id/
        airlabsPut: builder.mutation({
            query: body => ({
                url: `settings/airlab/${body.id}/`,
                method: 'PUT',
                body: {
                    api_key: body.data.airlab_api_key,
                }
            }),
        }),

        //Airlab update /api/settings/airlab/:id/
        airlabsUpdate: builder.mutation({
            query: body => ({
                url: `settings/airlab/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
        }),


        //GET Aviation Stack /api/settings/aviationstack/
        aviationGet: builder.query({
            query: () => "settings/aviationstack/",
            keepUnusedDataFor: 60 * 60 * 1000,
        }),

        //POST Aviation Stack /api/settings/aviationstack/
        aviationPost: builder.mutation({
            query: body => ({
                url: "settings/aviationstack/",
                method: 'POST',
                body: {
                    api_key: body.aviation_api_key,
                    data_offset: body.data_offset,
                    data_per_page: body.data_per_page
                }
            }),
        }),

        //Aviation Stack Delete /api/settings/aviationstack/:id/
        aviationDeleteById: builder.mutation({
            query: (id) => ({
                url: `settings/aviationstack/${id}/`,
                method: 'DELETE',

            }),
        }),

        // Aviation Stack put /api/settings/aviationstack/:id/
        aviationPut: builder.mutation({
            query: body => ({
                url: `settings/aviationstack/${body.id}/`,
                method: 'PUT',
                body: {
                    api_key: body.data.aviation_api_key,
                    data_offset: body.data.data_offset,
                    data_per_page: body.data.data_per_page
                }
            }),
        }),

        //Aviation Stack update /api/settings/aviationstack/:id/
        aviationUpdate: builder.mutation({
            query: body => ({
                url: `settings/aviationstack/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
        }),





        activate: builder.mutation({
            query: body => ({
                url: `${body.url}/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
        }),

        deactivate: builder.mutation({
            query: body => ({
                url: `${body.url}/${body.id}/`,
                method: 'PATCH',
                body: body.data
            }),
        }),

        //CSV Import
        //Occurrence Creation DataSet
        //POST /api/occurrence/
        occurrenceTypeImport: builder.mutation({
            query: body => mutation("settings/occurrencetype/import", "POST", body),
            invalidatesTags: ['occurrenceType']
        }),
        occurrenceCategoryImport: builder.mutation({
            query: body => mutation("settings/occurrencecategory/import", "POST", body),
            invalidatesTags: ['occurrenceCategory']
        }),
        investigationStatusImport: builder.mutation({
            query: body => mutation("settings/investigationstatus/import", "POST", body),
            invalidatesTags: ['investigationStatus']
        }),
        highestInjuryLevelImport: builder.mutation({
            query: body => mutation("settings/highestinjurylevel/import", "POST", body),
            invalidatesTags: ['highestInjuryLevel']
        }),
        typeOfOperationImport: builder.mutation({
            query: body => mutation("settings/typeofoperation/import", "POST", body),
            invalidatesTags: ['typeOfOperation']
        }),
        flightPhaseImport: builder.mutation({
            query: body => mutation("settings/flightphase/import", "POST", body),
            invalidatesTags: ['flightPhase']
        }),
        reportStatusImport: builder.mutation({
            query: body => mutation("settings/reportstatus/import", "POST", body),
            invalidatesTags: ['reportStatus']
        }),
        aircraftManufacturerImport: builder.mutation({
            query: body => mutation("settings/manufacturer/import", "POST", body),
            invalidatesTags: ['manufacturer']
        }),

        //Aircraft Type
        aircraftTypeImport : builder.mutation({
            query: body => mutation("settings/aircrafttype/import", "POST", body),
            invalidatesTags: ['aircraftType']
        }),
        damageToAircraftImport : builder.mutation({
            query: body => mutation("settings/damagetoaircraft/import", "POST", body),
            invalidatesTags: ['damageToAircraft']
        }),
        airlineImport : builder.mutation({
            query: body => mutation("settings/airline/import", "POST", body),
            invalidatesTags: ['airline']
        }),
        airportImport : builder.mutation({
            query: body => mutation("settings/airport/import", "POST", body),
            invalidatesTags: ['airport']
        })

    })


})

export const {
    useOccurrenceTypeImportMutation,
    useOccurrenceCategoryImportMutation,
    useInvestigationStatusImportMutation,
    useHighestInjuryLevelImportMutation,
    useTypeOfOperationImportMutation,
    useFlightPhaseImportMutation,
    useReportStatusImportMutation,
    useAircraftManufacturerImportMutation,
    useAircraftTypeImportMutation,
    useDamageToAircraftImportMutation,
    useAirlineImportMutation,
    useAirportImportMutation,
} = dataSettingsApiSlice







export const { useActivateMutation, useAircraftTypeDeleteMutation, useAircraftTypeGetByIdQuery, useAircraftTypeGetQuery, useAircraftTypePatchMutation
    , useAircraftTypePostMutation, useAircraftTypePutMutation, useAirlabsDeleteByIdMutation, useAirlabsGetQuery, useAirlabsPostMutation,
    useAirlabsPutMutation, useAirlabsUpdateMutation, useAviationDeleteByIdMutation, useAviationGetQuery, useAviationPostMutation, useAviationPutMutation, useAviationUpdateMutation,
    useDeactivateMutation, useAirlineDeleteMutation, useAirlineGetByIdQuery, useAirlineGetQuery, useAirlinePatchMutation, useAirlinePostMutation, useAirlinePutMutation,
    useAirportDeleteMutation, useAirportGetByIdQuery, useAirportGetQuery, useAirportPatchMutation, useAirportPostMutation, useAirportPutMutation,
    useCountryDeleteMutation, useCountryGetByIdQuery, useCountryGetQuery, useCountryPatchMutation, useCountryPostMutation, useCountryPutMutation,
    useStateDeleteMutation, useStateGetByIdQuery, useStateGetQuery, useStatePatchMutation, useStatePostMutation, useStatePutMutation,
    useCityDeleteMutation, useCityGetByIdQuery, useCityGetQuery, useCityPatchMutation, useCityPostMutation, useCityPutMutation,
    useDamageToAircraftDeleteMutation, useDamageToAircraftGetByIdQuery, useDamageToAircraftGetQuery, useDamageToAircraftPatchMutation, useDamageToAircraftPostMutation, useDamageToAircraftPutMutation,
    useOccurrenceCategoryDeleteMutation, useOccurrenceCategoryGetByIdQuery, useOccurrenceCategoryGetQuery, useOccurrenceCategoryPatchMutation, useOccurrenceCategoryPostMutation, useOccurrenceCategoryPutMutation,
    useReportStatusDeleteMutation, useReportStatusGetByIdQuery, useReportStatusGetQuery, useReportStatusPatchMutation, useReportStatusPostMutation, useReportStatusPutMutation,
    useOccurrenceTypeDeleteMutation, useOccurrenceTypeGetQuery, useOccurrenceTypePatchMutation, useOccurrenceTypePostMutation, useOccurrenceTypePutMutation,
    useOccurrenceTypeGetIdQuery,
    useFlightPhaseDeleteMutation, useFlightPhaseGetByIdQuery, useFlightPhaseGetQuery, useFlightPhasePatchMutation, useFlightPhasePostMutation, useFlightPhasePutMutation,
    useHigestInjuryLevelGetByIdQuery, useHighestInjuryLevelDeleteMutation, useHighestInjuryLevelGetQuery, useHighestInjuryLevelPatchMutation, useHighestInjuryLevelPostMutation, useHighestInjuryLevelPutMutation,
    useInvestigationStatusDeleteMutation, useInvestigationStatusGetByIdQuery, useInvestigationStatusGetQuery, useInvestigationStatusPatchMutation, useInvestigationStatusPostMutation, useInvestigationStatusPutMutation,
    useTypeOfOperationDeleteMutation, useTypeOfOperationGetByIdQuery, useTypeOfOperationGetQuery, useTypeOfOperationPatchMutation, useTypeOfOperationPostMutation, useTypeOfOperationPutMutation,
    usePatchByIdMutation,
    useAircraftManufacturerGetQuery, useAircraftManufacturerPostMutation, useAircraftManufacturerPutMutation, useAircraftManufacturerPatchMutation, useAircraftManufacturerDeleteMutation,


} = dataSettingsApiSlice