import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { grey, blue, black } from '@mui/material/colors'
import { createGlobalStyle } from 'styled-components'
import { getFromLocalStorage } from '../utils/localStorage'
import { useSelector } from 'react-redux'


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'PT Sans',
      textTransform: 'none',
      fontSize: 15,
    },
  },
  palette: {
    primary: {
      main: grey[700],
      dark: grey[100],
    },
    secondary: {
      main: '#0F62FE',
      dark: blue[900],
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        sx: {
          px: 1,
        },
        variant: 'subtitle2',
        textTransform: 'capitalize',
      },
    },
    MuiStack: {
      defaultProps: {
        sx: {
          px: 2,
          py: 1,
        },
        spacing: 2,
        direction: 'row',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: (theme) => theme.palette.primary.main,
        },
        underline: 'none',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        p: 0,
        disableRipple: true,
      },
      variant: 'text',
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

const GlobalStyle = createGlobalStyle`
html {
  font-size: ${(props) => props.fontSize}px;
}
`

const AppThemeProvider = (prop) => {
  const {fontSize: font = 16} = useSelector((state) => state.dataCount)
  const [fontSize, setFontSize] = React.useState(16)
  React.useEffect(() => {
    const fontSize = font ? font : getFromLocalStorage('fontSize')
    if (fontSize) {
      setFontSize(fontSize)
    }
    } , [font]
  )

  return (
  <ThemeProvider theme={theme}>{prop.children}
  <GlobalStyle fontSize={fontSize} />
  </ThemeProvider>
  )

}


export default AppThemeProvider
