import {
  PersonOutline,

} from '@mui/icons-material'
import flight from './Vector.png'
import FlightIcon from '@mui/icons-material/Flight'
import FolderOutlined from '@mui/icons-material/FolderOutlined'
import Role from '../../assets/roleIcon.svg'
import './widgets.scss'
import { Link } from 'react-router-dom'
import { Paper } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setDataset } from '../../features/data/dataSettingsSlice'
import Skeleton from '@mui/material/Skeleton'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';



const Widgets = ({ type }) => {
  const dispatch = useDispatch()


  const { dataset, users, aircraftTemplate, user_roles, loading } = useSelector(state => state.dataCount)

  const datasets = {
    "Occurrence Data Set": dataset.occurrence,
    "Airport Data Set": dataset.airport,
    "Aircraft Data Set": dataset.aircraft,
    "Location Data Set": dataset.location
  }

  let data



  switch (type) {

    case 'user':
      data = {
        title: 'User Management',
        isUser: true,
        link: 'View More',
        button1: 'Create New User',
        button2: 'Create New Role',
        icon: <PeopleAltOutlinedIcon />,
        icon2: <FolderOutlined />,
        name: "Users",
        count: users,
        roles: user_roles,
        to: '/users',
        to2: '/users/new',
        to3: '/users/roles/new',
      }
      break

    case 'workflow':
      data = {
        title: 'Workflow Management',
        isUser: false,
        link: 'View More',
        to: '/workflow',

      }
      break
    case 'data':
      data = {
        title: 'Data Settings',
        isUser: false,
        link: 'View More',
        icon: <img src={flight} alt='module icon' />,
        icon2: <img src={Role} alt='module icon' />,
        name: "Data Sets",
        count: 6,
        to: '/data-settings',
      }
      break
    case 'template':
      data = {
        title: 'Aircraft Template Manager',
        isUser: false,
        link: 'View More',
        button1: 'Create New Data',
        button2: 'Import Data',
        icon: <FlightIcon />,
        icon2: <img src={Role} alt='module icon' />,
        name: "Aircraft Model Data",
        count: aircraftTemplate,
        to: '/aircraft-template',
        to2: '/aircraft-template/create',
        to3: '/aircraft-template',
      }
      break

    default:
      break
  }



  return (
    <Paper sx={{
      height: "13.625rem", boxShadow: "0px 2px 6px #E0E0E0", width: "100%", backgroundColor: "#fff", padding: "1.5rem"
    }}  >

      <div className='widget'>


        <div className='widget-first'>
          <p className='text-[14px] font-bold font-pt'>{data.title}</p>
          <div className='link'>
            {loading ? <Skeleton variant="text" width={100} />
              : <Link to={data.to} className=''>
                {data.link}
              </Link>}

          </div>
        </div>

        <div className='flex gap-x-[22px] items-center'>
          <div className='flex gap-2 items-center'>
            {data.icon && <div className='icon'>{data.icon} </div>}
            {
              loading ? <Skeleton variant="circular" width={30} height={30} /> :
                <>
                  <h3 className=''>{data.count ?? 0}</h3>

                </>
            }
            {loading ? <Skeleton variant="text" width={100} /> : <p>{data.name}</p>}
          </div>
          {data.title === "User Management" &&
            <div className='flex gap-2 items-center'>
              {data.icon2}
              <div className='user-widget'>
                {loading ? <Skeleton variant="circular" width={30} height={30} /> : <h3>{data.roles ?? 0}</h3>}
                {loading ? <Skeleton variant="text" width={100} /> : <p>Roles</p>}
              </div>
            </div>

          }

        </div>


        <div className='widget-last'>
          {data.name === "Data Sets" ?
            <div className='data-sets flex flex-wrap gap-x-4 gap-y-1'>

              {
                Object.keys(datasets).map((item, index) => {
                  return (
                    <Link key={index} to={`/data-settings/${(item.replaceAll(/\s/g, "-")).toLowerCase()}`} onClick={() => dispatch(setDataset(item + " Set"))}
                      className={`data-set data-set-${index} gap-2 py-1.5 px-2.5 flex items-center`}>

                      <p className='font-bold text-sm font-pt'>{datasets[item]}</p>
                      <p className='text-xs font-normal font-pt'>{item}</p>
                    </Link>
                  )
                }

                )}



            </div> :
            <div className='btns'> {data.button1 &&
              <Link to={data.to2} className={data.name === "Occurrence Report" || data.name === "Media"
                ? "btn1" : "btn2"}>
                {data.button1}
              </Link>}
              {data.button2 && <Link to={data.to3} className='btn3'>{data.button2}</Link>}
            </div>
          }
        </div>
      </div>

    </Paper>
  )
}

export default Widgets
