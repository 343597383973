/**
 * @description Generate a 8 character password with at least one number, one uppercase letter and one lowercase letter and one special character
 * @returns {string} password
 * @example  generatePassword() // returns "aB1!@#$%^&*"
 */


const generatePassword = (length = 8) => {
    let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]\:;?><,./-=",
        retVal = "";

    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
}

export default generatePassword;