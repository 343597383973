
import Search from '../../../components/search/Search'
import './list.scss'
import PageTitle from '../../../components/pagetitle/PageTitle'
import { Link } from 'react-router-dom'
import { useState  , useEffect} from 'react'
import usePagination from '../../../utils/Pagination'
import LongMenu from '../../../components/menu/LongMenu'
import PeopleAlt from '@mui/icons-material/PersonOutline'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import {useSelector} from 'react-redux'
import { selectUsers, useGetUsersByPageQuery } from '../../../features/user/userApiSlice'
import UsersAndRolesDataList from '../../../components/widgets/UsersAndRolesDataList'
import Paginate from '../../../components/Paginate/Paginate'

const data = [
  {name: 'Dashboard', link: '/home' , Icon:AiOutlineHome},
  {name: 'User Management', link: '/users' , Icon:PeopleAltOutlinedIcon},
  {name:"User Profiles", link: '/users/all' , Icon:PeopleAlt},
]

const options = ['5 items', '10 items', '20 items', '50 items']

const ListUsers = () => {

  const [filterData, setFilterData] = useState([]);
  const {isLoading: userLoading} = useGetUsersByPageQuery()
  const users = useSelector(selectUsers);

  //
  const [page, setPage] = useState(1)
  const [PerPage, setPerPage] = useState(10)

  // Calculates the number of data per page.
  const count = Math.ceil(filterData.length 
    ? filterData.length : users?.length / parseInt(PerPage));

  let _data = usePagination(filterData.length ? filterData : users, PerPage) || [];
  
  //  set the page number
  const handleChange = (e, value) => {
      setPage(value);
      _data.jump(value);
  };

  const _export = { options, setValue: setPerPage, 
    handleChange, arrLength: filterData.length || users?.length };

  //
  const handleSearch = (e) => {
    let value = (e.target.value)
    if (value) {
      const result = users.filter((item) => { 
        return item.first_name.toLowerCase().includes(value.toLowerCase()) 
      })
      setFilterData(result)
    }
    else {
      setFilterData([])
    }
  }

  useEffect(() => {}, [])

  return (
    <>
      <div className='px-3 lg:px-10'>

         {/* Page header */}
        <IconBreadcrumbs data={data} />
        <div className='flex justify-between items-center mb-5'>
          <PageTitle
            icon={<PeopleAlt  />}
            pagename='User Profiles'/>

          <Link to='/users/new'>
            <button className='os-btn-white'>Create New User</button>
          </Link>
        </div>

        {/* List header */}
        <div className='header bg-primary flex justify-end md:justify-between p-4 pl-6 text-white items-center rounded-t'>
          <div className='hidden md:block'>
            <Search placeholder='Search for User' handleChange={handleSearch} />
          </div>

          <div className='flex text-white items-center gap-3'>
            <p className='mb-0'>
              {`${_data.begin + 1} - ${parseInt(_data.end)} of ${users?.length}`}
            </p>
            <LongMenu className={'text-white'} {..._export} />
          </div>
        </div>

        {/* Content */}
        <UsersAndRolesDataList type={'user'} data={_data} cells={['Name', 'Role', 'Status']} />
        
        {/* Pagination */}
        <div className='bg-white'>
          <hr className='mx-4' />
          <Paginate count={count} page={page} onChange={handleChange} />
        </div>
      </div>
    </>
   
  )
}

export default ListUsers
