import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './modal.scss'
import TabPanel from './TabPanel';
import { useDropzone } from 'react-dropzone'
import FileUpload from './FileUpload';
import ProgressBar from './ProgressBar';
import {useFormContext} from 'react-hook-form'
import ImportApiModal from './API/ImportApiModal';
import { useSelector } from 'react-redux';

function a11yProps(index) {
    return {
        id: `role-tab-${index}`,
        'aria-controls': `role-tab-${index}`,
    };
}

export default function ImportTabs({download, progress, error, watch, name, toggleModal}) {

    const formContext = useFormContext()
    const [value, setValue] = React.useState(0);
    const [checkForFile , setCheckForFile] = React.useState(false)

    const handleChange = (e, val) => setValue(val)

    const onDrop = React.useCallback((acceptedFiles) => {
        formContext.setValue('file', acceptedFiles[0])
        setCheckForFile(true)
    }, [])

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop, multiple: false});

    const Props = { getInputProps, isDragActive, fileRejections, getRootProps, error, name}

    return (
        <Box sx={{ width: '100%'  }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="import role data">
                    <Tab label="File" {...a11yProps(0)} />
                    <Tab label="API" {...a11yProps(1)} />
                </Tabs>
            </Box>
            
            <TabPanel value={value} index={0}>
                {
                    download 
                        ?
                            <div className='modal-content'>
                                <h4>importing model data</h4>
                                <ProgressBar value={progress} />
                                {
                                    progress === 100 && !error
                                        ?  <p>Data import completed</p> 
                                        : error 
                                            ?  
                                            <p className='small-text-error'>
                                                Opps, error with the selected file format, ensure your file is *.csv format
                                            </p> :null
                                }
                            </div>
                        :
                        progress < 10 && <FileUpload {...Props} />
                }

                {
                    checkForFile && progress < 1  
                        ? <button type="submit" className='os-btn-blue bg-primary'>import</button> : null
                }
            </TabPanel>
            
            <TabPanel value={value} index={1}>
               <div  style={{height: "300px", width: "100%"}}>
                    { !name && <ImportApiModal modalToggle={toggleModal} /> }
                </div>
            </TabPanel>
        </Box>
    );
}



