import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/authentication/authSlice'
import { getFromLocalStorage } from '../../utils/localStorage'
import { keys, tags } from '../../utils/constants'
import { redirect } from 'react-router-dom'

const baseQuery = fetchBaseQuery({

    // baseUrl: 'http://167.99.188.134/api/',
    baseUrl: 'https://webserviceapi.argueglobal.com/api/',

    // baseUrl: 'http://127.0.0.1:8000/api/',
    credentials: "same-origin",
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.access || getFromLocalStorage(keys.access)
        if (token) {
            headers.set("Authorization", `JWT ${token}`)
        }

        return headers
    }
})


const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401) {

        // let refreshToken = getFromLocalStorage(keys.refresh);
        // const response = await baseQuery({ url: '/user/token/refresh/', method: 'POST', body: { "refresh": refreshToken } }, api, extraOptions)
        // if (response?.data) {
        //     api.dispatch(setCredentials({ ...response.data }))
        //     result = await baseQuery(args, api, extraOptions)
        // } 
        // else {
        //     api.dispatch(logOut())
        //     redirect('/');
        // }

        api.dispatch(logOut())
        redirect('/');
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: Object.values(tags),
    endpoints: builder => ({}),
    reducerPath: 'web-service',
    refetchOnReconnect: true,
    refetchOnFocus: true,
})