import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { selectActions, selectPrivilege, selectPrivileges, selectRole, useGetPrivilegeQuery, useGetRoleQuery, useLoadActionsQuery, useLoadPrivilegesQuery } from "../../../features/identity/identityApiSlice";
import { useEffect } from "react";
import IconBreadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { AiOutlineHome } from "react-icons/ai";
import { PeopleAlt } from "@mui/icons-material";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Checkbox, FormControlLabel, Skeleton } from "@mui/material";
import { useState } from "react";
import { useLoadAuditQuery } from "../../../features/audit-trail/auditTrailApiSlice";
import AuditTrail from "../../../components/audittrail/AuditTrail";

const data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'Identity Management', link: '/users', Icon: PeopleAltOutlinedIcon },
    {name:"Privileges", link: '/identities/privileges', Icon: PeopleAlt },
    {name:"Privilege", link: '', Icon: PeopleAlt },
]

export const ViewPrivilege = () => {

    const {id} = useParams();
    let privilege = useSelector(selectPrivilege(id))

    // Audit
    const [audit, setAudit] = useState([]);
    const {data: auditResult} = useLoadAuditQuery('IDENTITY-MANAGER');
    
    const [skip, setSkip] = useState(true)
    const {data: selected, isLoading, isError} = useGetPrivilegeQuery(id, {skip});
    privilege = selected?.data || privilege;

    const { isLoading: privilegeLoading } = useLoadActionsQuery()
    const actions = useSelector(selectActions);

    const [allowedActions, setSelection] = useState(
        privilege ? Object.keys(privilege.allowed_actions) : []);

    const handleChange = (item) => {
        const selected = [...allowedActions];
        const index = selected.findIndex(i => i === item);

        if(index != -1) {
            selected.splice(index, 1);
        }
        else {
            selected.push(item);
        }

        // //
        setSelection(selected);
    }

    const isAdded = (item) => {
        return allowedActions.includes(item);
    }

    const prepAuditData = () => {
        if(auditResult) {
            let audits = [];
            let results = auditResult.results || [];
            results.forEach(row => {
              let audit = {
                info: row.trace,
                time: row.created_at,
                name: row.response?.data?.first_name
              }
              audits.push(audit);
            });
            setAudit(audits);
        }
    }

    useEffect(() => {
        if(!privilege) setSkip(false)
        prepAuditData();
    }, [auditResult])

    return (
        <>
            <div className="p-3 lg:p-5">
                <IconBreadcrumbs data={data} />
                {
                    isLoading && 
                        <div>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </div>
                }

                {
                    privilege && 
                    <div className='bg-white p-5 mb-4'>
                        <h4>Name</h4>
                        <p className="mb-4">{privilege.priviledge}</p>

                        <h4>Created on</h4>
                        <p className="mb-4">{privilege.added_on}</p>

                        <p>Allowed Actions</p>

                        <div className="mb-4">
                            {
                                Object.keys(privilege.allowed_actions).map((item, index) => {
                                    return <FormControlLabel key={index} control={<Checkbox onChange={() => handleChange(item)} checked={true} />} label={item} />
                                })
                            }
                        </div>

                        <button className='bg-sky-600 py-3 px-5 text-white mr-4 hidden' type='submit'>
                            Submit
                        </button>

                        <AuditTrail dataInfo={audit} />
                    </div>
                }

                {
                    isError && <div className="p-5 bg-white">
                        <h4 className="text-red-500">There's an error why loading Role. Please try again</h4>
                    </div>
                }
            </div>
        </>
    )
}