import React, { useState } from 'react'
import ApiPannelDropdown from './ApiPannelDropdown'
import Input from '../../../forms/PassWordInput'
import { useForm, FormProvider } from 'react-hook-form'
import { useImportAirlabsQuery, useImportAviationStacksQuery } from '../../../../features/aircraft_template/aircraftApiSlice'
import { handleError } from '../../../../utils/error-handler'
import { toast } from 'react-toastify'


const ApiPannel = ({ modalToggle }) => {

    const methods = useForm()
    const { formState: { dirtyFields } } = methods

    const [index, setIndex] = useState(null);
    const [item, setItem] = React.useState(null)
    const dropdowns = ["Airlabs Aircraft API", "Aviation Stack"]

    // Reqests
    const [airlabSkip, setAirlabSkip] = useState(true)
    const [aviationSkip, setAviationSkip] = useState(true)
    
    const {data: airlabs, error: airlabError} = useImportAirlabsQuery({
        skip: airlabSkip
    })

    const {data: aviations, error: aviationError} = useImportAviationStacksQuery({
        skip: aviationSkip
    })

    const onSelect = (item) => {
        setIndex(item)
        setItem(dropdowns[item])
    }

    const onSubmit = (e) => {
        if(index == 0) { // airlab
            setAirlabSkip(false)
            handleResponse(airlabs, airlabError)
        }
        else { // aviation
            setAviationSkip(false)
            handleResponse(aviations, aviationError)
        }
    }

    const handleResponse = (data, error) => {
        if(error) handleError(error)
        else {
            let message = data?.length ? data[0].message : ''
            if(message) toast.success(message)
        }
        
        modalToggle() // close modal
    }

    const getLabel = () => {
        let label = '';
        if(index == 0) label = 'Import' ;
        if(index == 1) label = 'Continue' ;
        return label;
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='select-api'>
                    <div className='api-pannel-header'>
                        <h4>Select API</h4>
                    </div>
                    <div className='api-pannel-body'>
                        <ApiPannelDropdown data={dropdowns} setItem={onSelect} item={item} />

                        <div className='api-pannel-body-input'>

                            {
                                index === 0 && <Input
                                    name="airlabs"
                                    register={methods.register}
                                    label="Airline code"
                                    isDirty={dirtyFields["airlab"]}
                                    placeholder="Airline code"

                                />
                            }

                        </div>
                        <div className='api-pannel-body-input mb-0'>
                            {
                                index === 1 && <Input
                                    name="aviation"
                                    register={methods.register}
                                    label="Aircraft Model Data"
                                    isDirty={dirtyFields["aviation"]}
                                    placeholder="Enter the number of pages"
                                />
                            }

                            {
                                index === 1 && <p className='mb-0'> Note: A page contains 1000 aircraft data model </p>
                            }

                        </div>

                    </div>

                    {
                        index != null && 
                            <div className='pb-4'>
                                <button type="button" className='os-btn-blue bg-primary' onClick={onSubmit}>
                                    {getLabel()}
                                </button>
                            </div>
                    }
                </div>
            </form>
        </FormProvider>
    )
}

export default ApiPannel
