import { Link } from 'react-router-dom';
import { useSelector  , useDispatch} from 'react-redux';

import { setActive, setSelected, setUrl } from '../../features/data/dataSettingsSlice';

export const Tabs = ({dataTypeHeaders}) => {
    
    const dispatch = useDispatch()
    const {  active = "" } = useSelector(state => state.dataset)

    const clickFunction = (value) => {
        dispatch(setUrl(value.url)) // to be removed
        dispatch(setSelected(value)); 
        dispatch(setActive(value.name)) // to be removed
    }

    return (
        <div className="tb-submenu">
            {
                dataTypeHeaders.map((data, index) => 
                    <Link key={index} className={`tb-submenu-item ${(active === data.name) && 'active-tab'}`} onClick={() => clickFunction(data)} >{data.name}</Link>)
            }
        </div>
    )
}

export default Tabs;