import OccurenceTypeList from "./TabPanelDescriptionList"
import OccurrenceTypeHeader from "./TabPanelDescriptionHeader"
import {
  useState, useMemo, createContext, useEffect
} from "react"
import usePagination from "../../utils/Pagination"
import { useSearchParams } from "react-router-dom"

// Create context
export const TabPanelContext = createContext()

const options = ['5 items', '10 items', '20 items', '50 items']

const TabPanel = ({ data, error, isLoading, 
  isFetching, create, editData, deactivateData, type = "API", importData, refresh }) => {

  const [params] = useSearchParams()
  const [query, setQuery] = useState(params.get('search') || '')

  const handleChangeSearch = (e) => { setQuery(e) }
  const [searchResult, setDatasetSearchResult] = useState([])

  useEffect(() => {
    if (query) {
      const result = data.filter((item) => { 
        return item.name.toLowerCase().includes(query.toLowerCase()) 
      })
      setDatasetSearchResult(result)
    }
    else {
      setDatasetSearchResult([])
    }
  }, [query, data])

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(8)

  let _data = usePagination(data, perPage);
  let searchData = usePagination(searchResult, perPage);
  const count = Math.ceil(data?.length / parseInt(perPage));

  //  Set the page number
  const handleChange = (e, value) => {
    setPage(value);
    if (searchData.currentData().length > 0) {
      searchData.jump(value)
    }
    else {
      _data.jump(value)
    }
  };

  const _export = { options, setValue: setPerPage, handleChange };

  const value = useMemo(() => ({
    importData, handleChangeSearch, type, refresh, create, perPage, page, setPage, handleChange,
    count, _data: searchData?.currentData()?.length > 0 ? searchData : _data,
    data, _export, arrLength: data?.length}), [importData, isLoading, data, create, page, perPage, _data]);

  return (

    <TabPanelContext.Provider value={value}>

      <div className='tp-container'>
        <OccurrenceTypeHeader />
        <OccurenceTypeList />
      </div>

    </TabPanelContext.Provider>
  )
}



export default TabPanel