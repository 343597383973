import { SearchOutlined } from '@mui/icons-material'
import './search.scss'

const Search = ({ placeholder, value, handleChange }) => {
  return (
    <div className='search'>
      <SearchOutlined className='searchicon' />
      <input type='text' name='search' className='text-black' placeholder={placeholder}
        onChange={handleChange} value={value} />
    </div>
  )
}

export default Search
