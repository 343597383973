import React from 'react'
import '../aircraftmanager/styles/index.scss';

const IconButton = ({handleClick, children,className}) => {
  return (
    <button className={`${className} os-btn-white`} onClick={handleClick}>
        {children}
    </button>
  )
}

export default IconButton