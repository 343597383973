
import UsersWelcome from '../../components/userswelcome/UsersWelcome'
// import ProfileUpdateNotification from '../../components/profileupdate/ProfileUpdateNotification'
import Widgets from '../../components/widgets/Widgets'

import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import { useEffect, useState } from 'react'
import { getFromLocalStorage } from '../../utils/localStorage';
import './home.scss'
import { Box, Grid, Skeleton } from '@mui/material'
import UserProfilePopup from '../../components/Modals/UserProfilePopup'
import { selectProfile, useGetCurrentUserQuery } from '../../features/user/userApiSlice';
import { setUser } from '../../features/user/userSlice';
/**
 *
 * @returns {JSX.Element}
 * @name Home
 * @description This component will render the home page
 * @example <Home />
 *
 */
const Home = () => {

  // profile
  const [roles, setRoles] = useState({});
  const user = useSelector(selectProfile);
  const [permissions, setPermissions] = useState({});

  const hasTrue = (model) => {
    if(!model) return false;

    let bool = false;
    let keys = Object.keys(model);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if(model[key] == 'true') {
        bool = true;
        break
      }
    }

    return bool;
  }

  const hasPermision = (permission) => {
    let bool = false;
    let model = permissions[permission];

    if(model) {
      
      if(permission == 'user_management') {
        
        // Check roles
        bool = hasTrue(model.user_roles);

        // Check profile
        if(!bool) {
          bool = hasTrue(model.user_profiles);
        }
      }
      else {
        bool = hasTrue(model);
      }
    }

    return bool;
  }

  useEffect(() => {
    if(user) {
      setRoles(user.roles);
      let role = Object.keys(user.roles)[0];
      setPermissions(user.roles[role])
    }
  }, [user])


  return (
    <>
      <div className='p-3 lg:p-10'>
        {
          user &&
            <UsersWelcome names={user.first_name} /> 
        }

        {
          !user &&
            <div className='flex justify-between items-center'>
              <Skeleton variant="rectangular" width={300} height={110} />
              <Skeleton variant="text" width={200} height={90} />
            </div>
        }

        <div className='py-5 md:flex gap-5'>
          <div className={(!hasPermision('air_template_manager') ? 'hidden' : '') + ' mb-4 flex-1'}>
            <Widgets type='template' />
          </div>

          <div className={(!hasPermision('data_Settings') ? 'hidden' : '') + ' mb-4 flex-1'}>
          {/* <div className={ ' mb-4 flex-1'}> */}

            <Widgets type='data' />
          </div>

          <div className={(!hasPermision('user_management') ? 'hidden' : '') + ' mb-4 flex-1'}>
            <Widgets type='user' />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Home
