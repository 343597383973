import React from 'react'
import '../aircraftmanager/styles/index.scss'


const AircraftHeading = ({title, icon}) => {
    const Icon = icon
    return (
        <div className='aircraft-heading'>
            <Icon />
            <h3 className='font-bold'>{title}</h3>
        </div>
    )
}

export default AircraftHeading