import React from 'react'
import './button_container.scss'

const ButtonContainer = ({children , noShadow }) => {
  return (
    <div className={`${noShadow ? 'no-shadow' : ""} button-container`}>
        {children}
    </div>
  )
}

export default ButtonContainer