import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as Lk } from 'react-router-dom'
import './bread.scss'



/**
 *
 * @param {Array} data array of objects- {name: string, url: string, Icon: ReactNode}
 * @returns jsx
 * @example <Breadcrumbs data={data} />
 * @description This component is used to display the breadcrumbs
 *
 *
 */
export default function IconBreadcrumbs({ data }) {
    //last index of the array
    const lastIndex = data?.length - 1

    return (
        <div role="presentation" className='bread' >
            <Breadcrumbs aria-label="breadcrumb" color="#7E7E7E" sx={{
                marginBlock: "2rem", paddingInlineStart: "0",
                '& .MuiBreadcrumbs-separator': {
                    marginInlineStart: "0.875rem",
                    marginInlineEnd: "1rem",
                }
            }}>

                {data?.map((item, index) => {
                    const { Icon } = item
                    return (
                        index === lastIndex ?
                            <Typography key={index} className='text-sm' color="#7E7E7E">{item.name}</Typography> :
                            <Lk to={item.link} key={index} >
                                <Link underline="hover" color="#0F62FE"
                                    sx={{ fontSize: "12px", display: 'flex', alignItems: 'center', paddingInline: 0 }}
                                    key={index}>
                                    <Icon className="w-4 h-4" />
                                    <span className='font-pt text-sm ml-1'>{item.name}</span>
                                </Link>
                            </Lk>
                    )

                })
                }
            </Breadcrumbs>

        </div>
    );
}