import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TBody from './TableBody';
import THead from './TableHead';
import TableBodySkeleton from './Skeletons/TableBody';
import { TableCell, TableHead, TableRow } from '@mui/material';


/**
 *
 * @param {{object}} props.rows
 * @returns
 */

export default function ModelTable({reports, flight, headings}) {

  const fdata = flight.currentData().filter((item, index) => Object.keys(item)[0] === 'model')
  const rdata = flight.currentData().filter((item, index) => Object.keys(item)[0] !== 'model')

  return (
    <>
    <TableContainer component={Paper} sx={{width:'100%', padding:'0', overflowX: 'auto', whiteSpace: 'nowrap'}} className='px-10'>
      <Table aria-label="report table">
        <THead headings={headings}></THead>
        <TableBody>
          { 
            flight?.currentData().length > 0 
            ? flight.currentData().map((f, index) => <TBody flight={f} key={index}/>) : <TableBodySkeleton />
          }

          {/* {
            reports?.currentData().length > 0 
            ? rdata.map((report, index) => <TBody report={report} key={index}/>) : <TableBodySkeleton />
          } */}

        </TableBody>
        
      </Table>
    </TableContainer>
    </>
  );
}
