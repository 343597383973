import React from 'react'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell'
import { Skeleton } from '@mui/material';

const TableBodySkeleton = () => {

  return (

 [1,2,3].map(item=><TableRow

    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >


    <TableCell >
    <Skeleton width="100%" height={50} variant="text" animation="wave" />

    </TableCell>
    <TableCell >
    <Skeleton width="100%" height="100%" variant="text" />
    </TableCell>
    <TableCell >
    <Skeleton width="100%" height="100%" variant="text" animation="wave" />
    </TableCell>
    <TableCell >
    <Skeleton width="100%" height="100%" variant="text"/>
    </TableCell>
    <TableCell >
    <Skeleton width="100%" height="100%" variant="text" animation="wave" />
    </TableCell>
  </TableRow>)

  )
}

export default TableBodySkeleton