
/**
 * @description Add an item to sessionstorage
 * @function
 * @param {string} name  name referencing to a particular value that would be used to get the value
 * @param {string} value
 * @example addToloacalStorage('token', 'qwcniwdhbjfocjksicbsicsjcebfwopdki920wkbwifwif')
 */
 export const addToSessionStorage = (name , value) => {
    localStorage.setItem(name, JSON.stringify(value))
   }

/**
 * @description remove item from session storage
 * @param {string} name
 */
export const removeFromSessionStorage = (name) => {
    localStorage.removeItem(name)
   };


/**
 * @description get item from session storage
 * @param {string} name
 */
export const getFromSessionStorage = (name) => {
    const result = localStorage.getItem(name);
    const refresh = result ? JSON.parse(result) : null;
    return refresh;
   }


   export const addToLocalStorage = (name , value) => {
      localStorage.setItem(name, JSON.stringify(value))
     }

  /**
   * @description remove item from session storage
   * @param {string} name
   */
  export const removeFromLocalStorage = (name) => {
      localStorage.removeItem(name)
     };


  /**
   * @description get item from local storage
   * @param {string} name
   */
  export const getFromLocalStorage = (name) => {
      const result = localStorage.getItem(name);
      return (result && result !== 'undefined') ? JSON.parse(result) : null;
   }




//    sessionStorage.setItem("lastname", "Smith");
// sessionStorage.getItem("lastname");

