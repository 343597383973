import { Dialog } from "@mui/material";
import './Dialog.scss'




const FormDialog = ({ title,open, handleClose, children }) => {
  return (<Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="data-creation"
    aria-describedby="data-creation"
    PaperProps={{ sx: { minHeight: 575, maxWidth: '640px' } }}

  >
    <div className='create-type'>

      <h4 className='create-type-heading'>
        {"Create " + title}
      </h4>
     {children}
    </div>
  </Dialog>)
}




export default FormDialog;