import React from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import FolderOutlined from '@mui/icons-material/FolderOutlined'
import './DataSettingsTitle.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDataset } from '../../features/data/dataSettingsSlice'


export const DataSettingsTitle = (props) => {
    let { title, number } = props
    const dispatch = useDispatch()

    return (
        <Grid item xs={12} md={6} lg={4} >
            <Paper sx={{ boxShadow: "0px 2px 6px #E0E0E0" }}>
                <div className="p-6 space-y-8">
                    <div className="">
                        <h3 className='text-sm font-bold'>{title}</h3>
                    </div>
                    <div className="flex gap-x-2 items-center">
                        <FolderOutlined className='w-6 h-6' />
                        <h3 className='text-lg font-bold'>{number}</h3>
                        <p className='text-secondary text-sm'>Data Set</p>
                    </div>
                    <div>
                        <Link
                            to={`/data-settings/${(title.replaceAll(/\s/g, "-")).toLowerCase()}`}
                            onClick={() => dispatch(setDataset(title))}>
                            <button className='btn-blue' >view more</button>
                        </Link>
                    </div>
                </div>
            </Paper>
        </Grid>
    );

}
