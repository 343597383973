import { Box, Typography } from '@mui/material'
import './userswelcome.scss'

const UsersWelcome = ({ names }) => {
  return (
    <div className='bg-primary w-2/5 text-white px-4 pt-2 pb-7 rounded-lg'>
      <h3 className='text-[32px] leading-[51px] font-bold py-2 text-white'>Welcome, {names}</h3>
      <p className='text-[14px] font-pt'>Continue from where you stopped the last time</p>
    </div>
    // <Box
    //   sx={{
    //     backgroundColor: '#0F62FE',
    //     color: '#fff',
    //     padding: '0.65rem 1.25rem',
    //     height: '6.5rem',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //     borderRadius:"4px",
    //     boxShadow:"0px 2px 6px #E0E0E0"
    //   }}

    //   className='md:w-2/5'>

    //   <Typography variant='h1' sx={{ fontSize: '2rem', color:'#fff', marginBottom:"0.625rem", fontWeight:"700" }}>
    //     <span style={{paddingInlineEnd:"4px"}}>Welcome,</span>
    //    <span>{`${"  " + names}`}</span>
    //   </Typography>
    //   <p className='home-header__text'>
    //     Continue from where you stopped the last time
    //   </p>
    // </Box>
  )
}

export default UsersWelcome
