import React from 'react'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const ApiPannelDropdown = ({item , setItem, data}) => {
    // dropdown is closed by default
    const [open, setOpen] = React.useState(false)
    const handleClick = (data) => {
        setItem(data)
        setOpen(!open)

    }
    return (
        <div className='api-dropdown'>

            <div onClick={() => setOpen(!open)} className='api-dropdown-select' style={{borderBlockEnd: item ? "1px solid #0F62FE" : ""}}>
                <p className='api-dropdown-select-text'>{item ?item :"Choose an option"}</p>
                {open ? <ExpandLessOutlinedIcon size='40' /> : <ExpandMoreOutlinedIcon size='40' />}
            </div>
            
            {
                open && <div className='api-dropdown-options'>
                    {
                        data?.map((item, index) => <p key={index} className='cursor-pointer' onClick={() => handleClick(index)}>
                            {item}
                        </p>)
                    }
                </div>
            }


        </div>
    )
}

export default ApiPannelDropdown