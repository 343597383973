import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./permission.scss";
import CheckBoxGrids from "./CheckBoxGrids";

/**
 * @returns {Element.jsx}
 * @param {Array} permit
 * @name ModifiedPermission
 * @description This component is used to display the permissions in the form of accordion
 * @example <ModifiedPermission permit={permit} />
 *
 */
export default function Permission({ permit = [], permissionsData = {} }) {

  const [expanded, setExpanded] = React.useState({
    UserManagement: false,
    DataSettings: false,
    AircraftTemplateManager: false,
    APIServiceManager: false,
    BillingCRM: false,
  });

  /**
   * @param {Event} e
   * @param {String} name
   * @description This function is used to dynamically set margin bottom of the accordion
   * @example handleChange(e,'OccurrenceReportManager')
   * @returns {void}
   * @name handleChange
   *
   */
  const handleChange = (e, name) => {
    e.stopPropagation();
    setExpanded((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <>
  
    <div>

      {/* Occurrence Report Manager */}
      {/* <Accordion sx={{ boxShadow: 'inset 0px 1px 0px #E0E0E0' }}  >
        <AccordionSummary
        onClick={(e)=>handleChange(e,'OccurrenceReportManager')}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
          sx={{paddingBlockEnd:expanded.OccurrenceReportManager  ? "0rem" : "2rem"}}

        >
          <Typography sx={{ fontSize: '1rem', fontWeight: '700', color: '#161616' }}>
            Occurrence Report Manager
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: '#FFFFFF', }}>
          <Typography sx={{ color: '#7E7E7E', fontWeight: '700', fontSize: '0.875rem', marginBlockEnd: '0.75rem' }}>Report Management - Permissions</Typography>
          <CheckBoxGrids permit={permit.slice(0, permit.length - 2)} name="ReportManager" checkData={permissionsData?.ReportManager} />

          <Typography sx={{ color: '#7E7E7E', fontWeight: '700', fontSize: '0.875rem',marginBlockEnd: '0.75rem'  }}>Content Management - Permissions</Typography>

          <CheckBoxGrids permit={permit.slice(0, permit.length - 2)} name="ContentManager" checkData={permissionsData?.ContentManager} />
        </AccordionDetails>
      </Accordion> */}

      {/* User Management */}
      <Accordion sx={{ boxShadow: "inset 0px 1px 0px #E0E0E0" }}>
        <AccordionSummary
          onClick={(e) => handleChange(e, "UserManagement")}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ paddingBlockEnd: expanded.UserManagement ? "0rem" : "2rem" }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold", color: "#161616" }}>
            User Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#F4F4F4", padding:"1.5rem 2.6rem", marginBlockEnd: "1rem"}}>
          <div style={{marginBlockEnd:"2.2rem" }}>
            <Typography
              sx={{
                color: "#7E7E7E",
                fontWeight: "700",
                fontSize: "0.875rem",
                marginBlockEnd: "0.75rem",
              }}
            >
              User Profiles
            </Typography>
            <CheckBoxGrids
              permit={permit}
              name="user_profiles"
              checkData={permissionsData?.user_management?.user_profiles}
            />
          </div>
          <Typography
            sx={{
              color: "#7E7E7E",
              fontWeight: "700",
              fontSize: "0.875rem",
              marginBlockEnd: "0.75rem",
            }}
          >
            User Roles:
          </Typography>
          <CheckBoxGrids
            permit={permit}
            name="user_roles"
            checkData={permissionsData?.user_management?.user_roles}
          />
        </AccordionDetails>
      </Accordion>

      {/* Aircraft Template Manager */}
      <Accordion sx={{ boxShadow: "inset 0px 1px 0px #E0E0E0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e) => handleChange(e, "AircraftTemplateManager")}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ paddingBlockEnd: expanded.AircraftTemplateManager ? "0rem" : "2rem" }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "700", color: "#161616" }}
          >
            Aircraft Template Manager
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#F4F4F4", padding:"1.5rem 2.6rem", marginBlockEnd:"1rem"}}>
          <CheckBoxGrids
            checkData={permissionsData?.air_template_manager}
            permit={permit.slice(0, permit.length - 2)}
            name="air_template_manager"
          />
        </AccordionDetails>
      </Accordion>

      {/* Media Library */}
      {/* <Accordion sx={{ boxShadow: 'inset 0px 1px 0px #E0E0E0' }} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e)=>handleChange(e,'MediaLibrary')}
          aria-controls='panel2a-content'
          id='panel2a-header'
          sx={{paddingBlockEnd:expanded.MediaLibrary  ? "0rem" : "2rem"}}
        >
          <Typography sx={{ fontSize: '1rem', fontWeight: '700', color: '#161616' }}>
            Media Library
          </Typography>
        </AccordionSummary>
       <AccordionDetails sx={{ background: '#F4F4F4' }}>
          <Typography sx={{ color: '#7E7E7E', fontWeight: '700', fontSize: '0.875rem',marginBlockEnd: '0.75rem' }}> Permissions</Typography>
          <CheckBoxGrids permit={permit.slice(0, permit.length - 2)} name="MediaLibrary" />
        </AccordionDetails>
      </Accordion> */}

      {/* Workflow Template Manager */}
      {/* <Accordion sx={{boxShadow: 'inset 0px 1px 0px #E0E0E0'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography sx={{ fontSize: '1rem', fontWeight: '700',color: '#161616' }}>
            Workflow Template Manager
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{background:'#F4F4F4', px:5.2}}>
          <Typography sx={{color:'#7E7E7E', fontWeight:'700', fontSize:'0.875rem',marginBlock:'0.75rem'}}>Permissions</Typography>
          <CheckBoxGrids permit={permit} name="WorkflowManager" />
        </AccordionDetails>
      </Accordion> */}

      {/* Datasettings */}
      <Accordion sx={{ boxShadow: "inset 0px 1px 0px #E0E0E0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e) => handleChange(e, "DataSettings")}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ paddingBlockEnd: expanded.DataSettings ? "0rem" : "2rem" }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "700", color: "#161616" }}
          >
            Data Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#F4F4F4" ,padding:"1.5rem 2.6rem",marginBlockEnd:"1rem"}}>
          <CheckBoxGrids
            permit={permit.slice(0, permit.length - 2)}
            name="data_Settings"
            checkData={permissionsData?.data_Settings}
          />
        </AccordionDetails>
      </Accordion>

      {/* Billing CRM */}
      <Accordion sx={{ boxShadow: "inset 0px 1px 0px #E0E0E0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e) => handleChange(e, "BillingCRM")}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ paddingBlockEnd: expanded.BillingCRM ? "0rem" : "2rem" }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "700", color: "#161616" }}
          >
            Billing CRM
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#F4F4F4" ,padding:"1.5rem 2.6rem",marginBlockEnd:"1rem" }}>
          <CheckBoxGrids
            checkData={permissionsData?.billing_crm}
            permit={permit.slice(0, permit.length - 2)}
            name="billing_crm"
          />
        </AccordionDetails>
      </Accordion>

      {/* API Service Manager*/}
      <Accordion sx={{ boxShadow: "inset 0px 1px 0px #E0E0E0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={(e) => handleChange(e, "APIServiceManager")}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ paddingBlockEnd: expanded.APIServiceManager ? "0rem" : "2rem" }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "700", color: "#161616" }}
          >
            API Service Manager
          </Typography>
        </AccordionSummary>
        
        <AccordionDetails sx={{ background: "#F4F4F4", padding:"1.5rem 2.6rem",marginBlockEnd:"1rem" }}>
          <CheckBoxGrids
            checkData={permissionsData?.api_service_manager}
            permit={permit.slice(0, permit.length - 2)}
            name="aPI_service_manager"
          />
        </AccordionDetails>
      </Accordion>
    </div>

    </>
  );
}
