import * as React from 'react';
import Box from '@mui/material/Box';
import './modal.scss'

// Returns a div for the data upload system
const  TabPanel = (props) =>{
    const { children, value, index, ...other } = props;

    return (
      <div
        // eslint-disable-next-line jsx-a11y/aria-role
        role="Data upload system"
        hidden={value !== index}
        id={`role-data-${index}`}
        aria-labelledby={`role-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
          {children}
          </Box>
        )}
      </div>
    );
  }

  export default TabPanel