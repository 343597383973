import { Skeleton, TableRow, TableCell } from '@mui/material'

const TableBodySkeleton = ({user})=>

   [1,2,3,4].map((result) => {

     return (
       <TableRow component="a" key={result.id}
         href={`/users/profile/${result.id}`}
       >
         <TableCell component='th' scope='row'>
           <div className='user-info'>
             {user === true ? <Skeleton width={40} height={40} variant="circular" animation="wave" />: null}
             <h4>
                <Skeleton width={100} height="100%" variant="text" animation="wave" />
             </h4>
           </div>
         </TableCell>
         <TableCell align='right'><p>
         <Skeleton variant="text" sx={{alignText : "right"}} />
            </p></TableCell>
       </TableRow>
     )
   })



export {TableBodySkeleton}