import React from 'react'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import './pagetitle.scss'

const PageTitle = ({ icon, pagename }) => {
  return (
    <div className='pageheader'>
      {icon}
      <div className='pagetitle ml-2 title'>{pagename}</div>
    </div>
  )
}

export default PageTitle
