import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer from '../features/authentication/authSlice'
import userReducer from '../features/user/userSlice'
import datasetReducer from '../features/data/dataSettingsSlice'
import dataCountReducer from '../features/datacount/dataCountSlice.js'
import sidebarReducer from '../features/sidebar/sidebarSlice'
import aircraftReducer from '../features/aircraft_template/aircraftSlice'
import urlReducer from '../features/url/UrlSlice'

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        user: userReducer,
        dataset:datasetReducer,
        dataCount:dataCountReducer,
        sidebar:sidebarReducer,
        aircraft:aircraftReducer,
        url:urlReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})

export default store