import {createSlice} from '@reduxjs/toolkit';

const aircraftSlice = createSlice({
    name: 'aircraft',
    initialState: {
        aircrafts: [],
        singleAircraft: [],
        aircraft: {}
    },
    reducers: {
        setAircrafts: (state, action) => {
            state.aircrafts = action.payload;
        },

        setAircraft: (state, action) => {
            state.aircraft = action.payload;
        },

        setAircraftById: (state, action) => {
            state.singleAircraft = state.aircrafts.filter(aircraft => aircraft.id === action.payload);
        },
    }

});

export const { setAircraft, setAircraftById, setAircrafts} = aircraftSlice.actions;
export default aircraftSlice.reducer;