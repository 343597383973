import React  from 'react'
import { Grid } from '@mui/material'

import Checkbox from '@mui/material/Checkbox';
import {useFormContext , Controller} from 'react-hook-form'
import {blue} from '@mui/material/colors'
import './permission.scss'


/**
 * @param {Array} permit - array of permissions to be displayed
 * @param {String} name - name of the permission
 * @param {Array} checkData - array of permissions to be checked
 * @returns {JSX.Element}
 * @constructor
 * @component
 * @example <CheckBoxGrids permit={["create", "edit", "delete"]} name="UserProfile" checkData={["create", "edit"]}/>
 */
const CheckBoxGrids = ({permit, name, checkData = []}) => {

  /**
   * @type {import('react-hook-form').UseFormMethods}
   * @property {import('react-hook-form').Control} control
   * @property {import('react-hook-form').Register} register
   *
   */
  const { control, register} = useFormContext()

  return (
    
    <div className='flex flex-wrap gap-5 justify-between'>
      { 
        permit.map((item, index) => {

          // check for item in checkData
          let checked = false;
          if(Object.keys(checkData).length) {
            checked = checkData[item] == 'true';
          }
    
        // if(checkData.length > 0){
        //   checkData.forEach((data) =>{
        //     //change data name to "edit" if it is "change"
        //     if(data === "change"){
        //       data = "edit"
        //     }
        //     //change data name to "create" if it is "add"
        //     if(data === "add"){
        //       data = "create"
        //     }

        //     if(data === item){
        //       checked = true
        //     }
        //   })
        // }

          return(

              <Grid className='w-fit' item sx={{ display: 'flex', alignItems: 'center', gap: 1 }} key={index} >
                
                  <Controller
                      name={name}
                      control={control}
                      id={index}
                      register={register}
                      value={item}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          {...register(`${name}`)}
                          name={name}
                          value={item}
                          defaultChecked={checked}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: "1.25rem",}," &.Mui-checked": { color: blue[600] },
                          "&.Mui-disabled": { color: "#b0b0b0" }, paddingInlineStart: "0px"
                          }}
                          inputProps={{ 'aria-label': 'permission creation' }}
                        />
                    )}
                  />
              <label htmlFor='permission creation' className='capitalize'>{item}</label>
            </Grid>
          )
        })
      }
    </div>
  )
}

export default CheckBoxGrids