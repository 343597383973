import { createSlice } from "@reduxjs/toolkit";

export const urlSlice = createSlice({
    name: "url",
    initialState: {
        url: "http://20.102.27.112:8000",
    },
    reducers: {
        setUrl: (state, action) => {
            state.url = action.payload;
        },
    }



});

export const { setUrl } = urlSlice.actions;
export default urlSlice.reducer;