import { Avatar } from "@mui/material"
import { getProfile } from "../../utils/profile-url"
import { useNavigate } from "react-router-dom"
import capitalize from "../../utils/capitalize";

const UsersAndRolesDataList = ({type, data, cells}) => {

    const navigate = useNavigate();
    let _data = data.currentData() || []

    const openUser = (type, id) => {
        if(type == 'user') {
            navigate(`/users/${id}`);
        }

        if(type == 'role') {
            navigate(`/users/roles/${id}`);
        }
    }

    return (
        <>
            <div className="bg-white">
                <div className="flex justify-between p-3 px-6 text-slate-500">
                    <div className="basis-1/3 text-sm">{cells[0]}</div>
                    <div className="basis-1/3 text-sm text-center">{cells[1]}</div>
                    <div className="basis-1/3 text-sm text-end">{cells[2]}</div>
                </div>

                <div>
                    {
                        _data.map((item, index) => {
                            return (
                            <div className="p-3 px-6 flex justify-between" key={item.id}>
                                    <div className="basis-1/3 cursor-pointer font-bold" onClick={() => openUser(type, item.id)}>
                                        {
                                            type == 'user' && 
                                            <p className="flex mb-0 gap-3 items-center cursor-pointer font-bold">
                                                {
                                                    item.profile_image
                                                        ? <img src={getProfile(item.profile_image)} className="w-8 h-8 rounded-full" alt="Profile" />
                                                        : <Avatar sx={{width: '32px', height: '32px'}} />
                                                }

                                                { item.last_name + ' ' + item.first_name }
                                            </p>
                                        }

                                        {
                                            type == 'role' && <span>{capitalize(item.role)}</span>
                                        }
                                    </div>

                                    <div className={type == 'user' ? 'text-sky-500 basis-1/3 text-center' : 'basis-1/3 text-center'}>
                                        {
                                            type == 'user' && 
                                                <span>{ capitalize(Object.keys(item.roles)[0]) || 'N/A' }</span>
                                        }

                                        {type == 'role' && <span>0</span>}

                                        {type == 'privilege' && <span>{item.added_on}</span>}
                                    </div>

                                    <div className="basis-1/3 text-end">
                                        <span style={{ color: !item?.is_active ? "#DA1E28" : '#14662D' }}>
                                            {item?.is_active ? 'Active' : 'Inactive'}
                                        </span>
                                    </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default UsersAndRolesDataList