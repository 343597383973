import React from 'react'
import PageTitle from '../../../components/pagetitle/PageTitle'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { FiUser, } from 'react-icons/fi'
import { toast, ToastContainer } from 'react-toastify'
import DeactivateDialog from '../../../components/Dialog/DataSettings/DeactivateDialog'
import Spinner from '../../../components/Spinner/Spinner'
import '../../../pages/user/create/newuser.scss'
import './user.scss'
import UploadImage from '../../../components/uploadphoto/UploadImage'
import { useForm, FormProvider } from 'react-hook-form'
import SelectRole from '../../../components/forms/SelectRole'
import Input from '../../../components/forms/PassWordInput'
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserQuery, useUpdateUserMutation } from '../../../features/user/userApiSlice'
import { useSelector } from 'react-redux'
import { setEditActive , setRole} from '../../../features/user/userSlice'
import ButtonContainer from '../../../components/ButtonContainer/ButtonContainer'
import styled from '@emotion/styled'
import { useActivateUserQuery, useDeactivateUserQuery } from '../../../features/user/userApiSlice'
import IconBreadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {AiOutlineHome} from 'react-icons/ai'
import PeopleAlt from '@mui/icons-material/PersonOutline';
import { handleError } from '../../../utils/error-handler'
import { getProfile } from '../../../utils/profile-url'
import AuditTrail from '../../../components/audittrail/AuditTrail'
import { useLoadAuditQuery } from '../../../features/audit-trail/auditTrailApiSlice'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const User = () => {

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [audit, setAudit] = useState([]);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    profile_image: '',
  })

  const {data: response, isSuccess, isLoading: userLoading} = useGetUserQuery(id, {
    refetchOnMountOrArgChange: true})

  const user = response?.data;
  const [updateUser, { isLoading }] = useUpdateUserMutation()

  const [skipActivate, setSkipActivate] = useState(true);
  const [skipDeactivate, setSkipDeactivate] = useState(true);
  
  const {data: activeResponse, isLoading: activateLoading,
    error: activateError} = useActivateUserQuery(user?.id, {skip: skipActivate})
  const {data: deactivateResponse, isLoading: deactivateLoading,
    error: deactivateError} = useDeactivateUserQuery(user?.id, {skip: skipDeactivate})

  // Activate and Deactivate Response
  if(deactivateResponse || activeResponse) {
    let message = activeResponse?.message || deactivateResponse?.message;
    toast.success(message);
    setTimeout(() => {
      navigate(-1)
    }, 500);
  }

  // Errors
  if(activateError || deactivateError) { handleError(activateError || deactivateError)}
  
  // Audit
  const {data: auditResult} = useLoadAuditQuery('USER-MANAGEMENT');
  
  //initialize form
  const methods = useForm({defaultValues: initialValues});
  const { register, formState: { errors, dirtyFields }, handleSubmit, setValue, getValues, } = methods

  const data = [
    {name: 'Dashboard', link: '/home' , Icon: AiOutlineHome},
    {name: 'User Management', link: '/users' , Icon:PeopleAltOutlinedIcon},
    {name:"User Profiles", link: '/users/all' , Icon:PeopleAlt},
    //{name: `${userData?.user?.first_name} ${userData?.user?.last_name}`, link: '/newuser' , Icon:""},
  ]

  // Update
  const handleSave = async (e) => {

    const form = new FormData()
    for (const key in initialValues) {
      if (getValues(key) !== user[key]) {
        if (key === 'profile_image') {
          if (getValues(key)) {
            if (getValues(key)[0]?.path) form.append('profile_image', getValues(key)[0])
          }
        }
        else {
          form.append(key, getValues(key))
        }
      }
    }

    try {
      const response = await updateUser({ form, id }).unwrap()
      toast.success(response.message);
      setEdit(!edit)
      navigate(-1)
    } 
    catch (error) {
      handleError(error)
    }
  }

  const checkErrors = (errs, name) => {
    const errors = Object.keys(errs)
    return errors.find(er => name === er)
  }

  const handleClickOpen = () => {setOpen(true)}

  const handleDeactivate = () => {
    setOpen(false)
    setSkipDeactivate(false)
  }

  const handleActivate = async () => {
    setOpen(false)
    setSkipActivate(false);
  }

  // Prompt data
  const deactivatePrompt = {
    handleClose: handleDeactivate, open, title: 'Deactivate User', 
    question: 'Are you sure you want to deactivate this user?'
  }

  const activatePrompt = {
    handleClose: handleActivate, open, title: 'Activate User',
    question: 'Are you sure you want to activate this user?'
  }

  const dialogData = user?.is_active ? deactivatePrompt : activatePrompt

  // 
  useEffect(() => {
    if (isSuccess) {
      setValue('first_name', user?.first_name)
      setValue('last_name', user?.last_name)
      setValue('email', user?.email)
      setValue('phone_number', user?.phone_number)
      setValue('password', user?.password)

      let roles = user?.roles;
      roles = roles ? Object.keys(roles) : [];

      setRoles(roles)
      setValue('role', roles.length ? roles[0] : '');
    }

    if(auditResult) {
      let audits = [];
      let results = auditResult.results || [];
      results.forEach(row => {
        let audit = {
          info: row.trace,
          time: row.created_at,
          name: row.response?.data?.first_name
        }
        audits.push(audit);
      });

      setAudit(audits);
    }

  }, [isSuccess, user, setValue, dispatch, auditResult])
  
  if(userLoading) return <Spinner/>

  return (
    <>
    <div className='px-3 lg:px-10'>
      <IconBreadcrumbs data={data} />

      <div className='newuser'>
        <div className='titlecontainer mb-4'>
          <PageTitle
            icon={<PersonOutlineIcon className='createusericon' />}
            pagename={`${user?.first_name} ${user?.last_name}`}
          />
        </div>

        <FormProvider  {...methods}>
          <div className='' style={{ pointerEvents: !edit ? 'none' : 'auto' }}>
            <form  onSubmit={handleSubmit(handleSave)} autoComplete="off">

              <div className={`md:flex gap-5 mb-4  ${user?.is_deleted && 'deleted'}`}>
                {/* Information */}
                <div className='lg:w-3/5 bg-white p-3 mb-4'>

                  {/* Name */}
                  <div className='row md:flex gap-5'>
                    <div className='input-and-error mb-4 flex-1'>
                        <Input
                          isDirty={dirtyFields.first_name}
                          label='First Name'
                          type='text'
                          name='first_name'
                          placeholder='Enter your first Name'
                          register={register}
                          borderBottom={true}
                          initialRendering = {!edit}
                          error={checkErrors(errors, 'first_name')}
                          validate={
                            {
                              pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                              minLength: 3,
                              maxLength: 20
                            }
                          } />

                        {errors?.first_name?.required && <p>First Name is required</p>}
                        {errors?.first_name && <p>Please input a valid name</p>}
                    </div>

                    <div className='input-and-error mb-4 flex-1'>
                      <Input
                        isDirty={dirtyFields.last_name}
                        label='Last Name'
                        type='text'
                        name='last_name'
                        register={register}
                        placeholder='Enter your last name'
                        borderBottom={true}
                        initialRendering = {!edit}
                        validate={
                          {
                            pattern: /^[A-Za-zÀ-ÿ ,.'-]+$/,
                            minLength: 3,
                            maxLength: 20
                          }
                        }
                        error={checkErrors(errors, 'last_name')}
                      />
                      {errors?.last_name?.required && <p>Last  Name is required</p>}
                      {errors?.last_name && <p>Please input a valid name</p>}
                    </div>
                  </div>

                  {/* Email */}
                  <div className='md:flex gap-5'>
                    <div className='input-and-error mb-4 flex-1'>
                      <Input
                        isDirty={dirtyFields.email}
                        label='Email Address'
                        type='email'
                        name='email'
                        placeholder='Enter your email address'
                        borderBottom={true}
                        initialRendering = {!edit}
                        register={register}
                        validate={
                          {
                            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                          }
                        }
                        error={checkErrors(errors, 'email')}
                      />
                      {errors?.email?.required && <p>Email is required</p>}
                      {errors?.email && <p>Please input a valid email</p>}
                    </div>

                    <div className='input-and-error mb-4 flex-1'>
                      <Input
                        isDirty={dirtyFields.phone_number}
                        error={checkErrors(errors, 'phone_number')}
                        label='Phone Number'
                        type='text'
                        name='phone_number'
                        register={register}
                        placeholder='+234 8149181923'
                        borderBottom={true}
                        initialRendering = {!edit}
                        validate={
                          {
                            pattern: /^(((\+234\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+234\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+234\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
                            maxLength: 15
                          }
                        } />

                      {errors?.phone_number?.required && <p>Phone number is required</p>}
                      {errors?.phone_number && <p>Please input a valid phone number</p>}
                    </div>
                  </div>

                  {/* Roles */}
                  {/* <SelectRole register={register} setValue={setValue} isDirty={'datarole'} title='Role Profile' /> */}
                  <SelectRole register={register} setValue={setValue} defaults={roles} isDirty={'datarole'} title='User Role' borderBottom={!edit}   />
                </div>

                {/* Image */}
                <div className='lg:w-2/5 mb-4'>
                  {
                      !edit
                      
                        ?
                          <ImageContainer>
                            <img src={getProfile(user?.profile_image)} alt='profile' />
                          </ImageContainer>

                        :
                          <UploadImage accept={{ "image/*": ['.png', '.jpg', '.jpeg', '.gif',]}}
                            name="profile_image"
                            className='items-center'
                            img={getProfile(user?.profile_image)}
                            label="File Upload"
                            icon={FiUser}
                            text={'Upload Photo'}
                            rounded={true}
                          />
                    }
                </div>
              </div>

              {
                edit && <div className='buttoncontainer'>
                  <ButtonContainer>
                    <button type='submit' className='os-btn-blue bg-primary' >
                      {isLoading ? "Submiting..." : 'Save'}
                    </button>
                    <button type='button' className='dt-btn-red text-red-500' onClick={() => setEdit(!edit)}>
                      Cancel
                    </button>
                  </ButtonContainer>
                </div>
              }

            </form>
          </div>
        </FormProvider>

        {/* alow admin to edit user */}

        {
          user?.is_active === false
          ?
            <div className='buttoncontainer'>
              <ButtonContainer>
                <button type='button' className='os-btn-blue bg-primary'  onClick={handleClickOpen}>
                  {'Activate User'}
                </button>
              </ButtonContainer>
            </div> 
          :
          !edit && 
          <div className='buttoncontainer'>
            <ButtonContainer>
              <button type='button' className='os-btn-blue bg-primary' onClick={() => setEdit(!edit)}>
                {'Edit User'}
              </button>
              <button type='button' className='p-4 bg-red-500 text-white' onClick={handleClickOpen}>
                Deactivate user
              </button>
            </ButtonContainer>
          </div>
        }
      </div>

      { 
        <DeactivateDialog deactivateStatus={user?.is_active} setOpen={setOpen}
            isLoading={user?.is_active ? activateLoading : deactivateLoading} {...dialogData} />
      }

      <AuditTrail dataInfo={audit} />

    </div>
      <ToastContainer />
    </>
  )
}

export default User

//create rounded image component
const ImageContainer = styled.div`
  width:12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D9D9D9;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
`
