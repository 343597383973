import React, { useState } from 'react'
import ApiPannelDropdown from './ApiPannelDropdown'
import Input from '../../forms/PassWordInput'
import { useForm, FormProvider } from 'react-hook-form'
import { useImportAirlabsQuery, useImportAviationStacksQuery } from '../../../features/aircraft_template/aircraftApiSlice'
import { toast } from 'react-toastify'
import { handleError } from '../../../utils/error-handler'

const ApiPannel = ({modalToggle}) => {
    
    const methods = useForm()

    const [item, setItem] = React.useState(null)
    const [index, setIndex] = useState(null);

    const {handleSubmit, formState: { dirtyFields } } = methods
    const dropdowns = ["Airlabs Aircraft API", "Aviation Stack"]

    // Reqests
    const [airlabSkip, setAirlabSkip] = useState(true)
    const [aviationSkip, setAviationSkip] = useState(true)
    
    const {data: airlabs, error: airlabError} = useImportAirlabsQuery({
        skip: airlabSkip
    })

    const {data: aviations, error: aviationError} = useImportAviationStacksQuery({
        skip: aviationSkip
    })

    const onSelect = (item) => {
        setIndex(item)
        setItem(dropdowns[item])
    }

    const onSubmit = (e) => {
        if(index == 0) { // airlab
            setAirlabSkip(false)
            handleResponse(airlabs, airlabError)
        }
        else { // aviation
            setAviationSkip(false)
            handleResponse(aviations, aviationError)
        }
    }

    const handleResponse = (data, error) => {
        if(error) handleError(error)
        else {
            let message = data?.length ? data[0].message : ''
            if(message) toast.success(message)
        }

        modalToggle() // close modal
    }

    return (
        <>
        <div className='select-api'>
            <div className='api-pannel-header'>
                <h4>Select API</h4>
            </div>
            
            <div className='api-pannel-body'>

                <ApiPannelDropdown 
                    data={dropdowns} setItem={onSelect} item={item} />

                <div className='api-pannel-body-input'>

                    {
                        index === 0 && <Input
                            name="airlabs"
                            register={methods.register}
                            label="Airline code"
                            isDirty={dirtyFields["airlab"]}
                            placeholder="Airline code"
                        />
                    }

                </div>

                <div className='api-pannel-body-input'>
                    {
                        index === 1 && <Input
                            name="aviation"
                            register={methods.register}
                            label="Aircraft Model Data"
                            isDirty={dirtyFields["aviation"]}
                            placeholder="Enter the number of pages"
                        />
                    }
                    { 
                        index === 1 
                            && <p>Note: A page contains 1000 aircraft data model </p>
                    }

                </div>
            </div>
                    
            {
                index === 1 
                    ? <button className='os-btn-blue bg-primary' type='button' onClick={onSubmit}>Continue</button> 
                    : null
            }

            {
                index === 0
                    ? <button className='os-btn-blue bg-primary' type='button' onClick={onSubmit}>Import</button> 
                    : null
            }
                
        </div>

        {/* <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className='hidden'>
                <div className='select-api'>
                    <div className='api-pannel-header'>
                        <h4>Select API</h4>
                    </div>
                    
                    <div className='api-pannel-body'>
                        <ApiPannelDropdown data={["Airlabs Aircraft API", "Aviation Stack"]}
                            setItem={setItem} item={item} />

                        <div className='api-pannel-body-input'>

                            {item === "Airlabs Aircraft API" && <Input
                                name="airlabs"
                                register={methods.register}
                                label="Airline code"
                                isDirty={dirtyFields["airlab"]}
                                placeholder="Airline code"
                            />
                            }

                        </div>
                        <div className='api-pannel-body-input'>
                            {item === "Aviation Stack" && <Input
                                name="aviation"
                                register={methods.register}
                                label="Aircraft Model Data"
                                isDirty={dirtyFields["aviation"]}
                                placeholder="Enter the number of pages"
                            />
                            }
                            { item === "Aviation Stack" && <p>Note: A page contains 1000 aircraft data model </p>}

                        </div>
                    </div>
                    
                    {
                        item === "Aviation Stack" 
                            ? <button className='os-btn-blue bg-primary'>Continue</button> 
                            : null
                    }

                    {
                        item === "Airlabs Aircraft API" 
                            ? <button className='os-btn-blue bg-primary'>Import</button> 
                            : null
                    }
                
                </div>
            </form>
        </FormProvider> */}
        </>
    )
}

export default ApiPannel
