import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import IconButton from './IconButton'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ModelTable from './ModelTable';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Heading from './Heading';
import ImportModal from '../Modals/ImportModal';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import usePagination from '../../utils/Pagination';
import LongMenu from '../menu/LongMenu';
import { Link } from 'react-router-dom';
import '../aircraftmanager/styles/index.scss';
import Spinner from '../Spinner/Spinner';
import Paginate from '../Paginate/Paginate';
import { FileUploadOutlined } from '@mui/icons-material';
import { FiUser } from "react-icons/fi";

function CreateModel({ headings, icon, title, options, rows, PerPage, setPage, 
    setPerPage, handleOpen, handleClose, page, open2, h1, h2, p1, span1, span2, 
    setProgress, setDownload, progress, download, doFilter}) {

    let _rows = usePagination(rows, PerPage);
    const count = Math.ceil(rows.length / parseInt(PerPage));

    // to page
    const handleChange = (e, value) => {
        setPage(value);
        _rows.jump(value);
    };

    const _export = { options, setValue: setPerPage, handleChange };
    let _exp = { setProgress, setDownload, progress, download };

    return (
        <>
            <div className='mb-4'>

                <div className='mb-6 flex justify-between items-center'>
                    <Heading title={title} icon={icon} />
                    {
                        span2 && 
                            <IconButton className='icon-button' handleClick={handleOpen}>
                                <span>{span2}</span>
                                <FileUploadOutlined />
                            </IconButton>
                    }
                </div>

                {/* Header */}
                <header className='md:flex justify-between items-center mb-4'>
                    <div className='header-box bg-primary mb-4 rounded'>
                        <div className=''>
                            <div className='text-info'>
                                <h4 className='text-white font-bold'>{h1}</h4>
                                <p className='text-white font-bold' style={{color: '#CFE0FF'}}>{p1}</p>
                            </div>

                            <Link to={'create'} className='mx-auto'>
                                <IconButton className='icon-button'>
                                    <span className='mr-4'>{span1}</span>
                                    <AddIcon />
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                </header>

                {/* Search bar */}
                <div className='create-aircraft-bottom header bg-primary flex justify-end md:justify-between p-4 lg:px-11 text-white items-center'>

                    <div className='create-aircraft-heading-left flex items-center'>
                        <FiUser />
                        <h5 className='text-base font-bold'>{h2}</h5>
                    </div>

                    <div className='create-aircraft-heading-right flex gap-5 items-center'>
                        <div className='create-aircraft-heading-input'>
                            <SearchOutlinedIcon />
                            <input placeholder='Search input text' onChange={(e) => doFilter(e.target.value)} />
                        </div>
                        
                        {/* dynamic data */}
                        <p>{`${_rows.begin + 1} - ${parseInt(_rows.end)} of ${rows.length}`}</p>
                        <LongMenu {..._export} />
                    </div>
                </div>

                {/* Content  */}
                {
                    _rows?.currentData().length < 0 
                        ? <Spinner /> 
                        : <ModelTable flight={_rows} reports={_rows} page={page} headings={headings} />
                }

                {/* Pagination  */}
                <div className='bg-white'>
                    <hr className='' />
                    <Paginate count={count} page={page} onChange={handleChange} />
                </div>
            </div>

            <section className='create-aircraft-model hidden'>
                <div className='create-aircraft-top'>
                    <div className='create-aircraft-top-left'>
                        <Heading title={title} icon={icon} />
                        <div className='catl-container'>
                            <div className='text-info'>
                                <h4>{h1}</h4>
                                <p>{p1}</p>
                            </div>
                            <Link to={'create'}>
                                <IconButton className='icon-button'>
                                    <span>{span1}</span>
                                    <AddIcon />
                                </IconButton>
                            </Link>
                        </div>

                    </div>
                    {span2 && <IconButton className='icon-button' handleClick={handleOpen}>
                        <span>{span2}</span>
                        <FileDownloadOutlinedIcon />
                    </IconButton>}

                </div>
                <div className='create-aircraft-bottom'>

                    <div className='create-aircraft-heading'>
                        {/* blue heading with a search bar */}
                        <div className='create-aircraft-heading-left'>
                            <PersonOutlinedIcon />
                            <h5>{h2}</h5>
                        </div>
                        <div className='create-aircraft-heading-right'>
                            <div className='create-aircraft-heading-input'>
                                <SearchOutlinedIcon />
                                <input placeholder='Search input text' />
                            </div>
                            {/* dynamic data */}
                            <p>{`${_rows.begin + 1} - ${parseInt(_rows.end)} of ${rows.length}`}</p>
                            <LongMenu {..._export} />
                        </div>
                    </div>

                    <div className='create-aircraft-table lg:px-11'>
                        <Stack spacing={3} direction={'column'} sx={{ padding: '0' }}>
                            {_rows?.currentData().length < 0 ? <Spinner /> :<ModelTable flight={_rows} reports={_rows} page={page} headings={headings} />}
                            
                            <Pagination count={count} page={page} onChange={handleChange}
                                sx={{
                                    '& .css-1ddq0ly-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected ': {
                                        borderBlockEnd: '2px solid #0138a1',
                                        background: 'transparent',
                                        borderRadius: 0,
                                        fontSize: '16px'
                                    },
                                    background: '#fff',
                                    alignSelf: 'flex-end'
                                }} />
                        </Stack>

                    </div>

                </div>

            </section>
            
            <ImportModal handleClose={handleClose} open={open2} {..._exp} />
        </>

    );
}

export default CreateModel