import React, { useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useFormContext } from "react-hook-form"
//import x icon
import { AiOutlineClose } from "react-icons/ai"

/**
 *
 * @param {object} props {accept:{object} name:{string} label:{string} icon:{jsx} text:{string} rounded:{bool} }
 * @example  <UploadImage  accept={{"image/*":['.png','.jpg']}}
                        name="photo"
                        label="File Upload"
                        icon={PersonOutlineIcon}
                        text={'Upload Photo'}
                        rounded={true}
                         />
 */

const UploadImage = props => {
    
    const {name, icon, text, rounded, className} = props
    const Icon = icon

    // Create an instance of useForm context and destructure
    const { register, unregister, setValue, watch } = useFormContext()
    const files = watch(name)

    const onDrop = useCallback(
        droppedFiles => {
            setValue(name, droppedFiles, { shouldValidate: true }) // set value for the registered input
        },
        [setValue, name]
    )

    // Create an instance of useDropezone and destructure
    const { getRootProps, getInputProps, isDragActive , fileRejections } = useDropzone({
        onDrop, accept: props.accept, multiple: false, maxSize: 500000 //accepts just 1 image and max size is 500kb
    })

    useEffect(() => {
        register(name)  //register name "photo" into useForm
        return () => {
            unregister(name) // clear registration
        }
    }, [register, unregister, name])


    const handleRemove = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setValue(name, null, { shouldValidate: true }) // set value for the registered input
    }
 
    return (
        <>
            <div className={`${isDragActive && 'active'} image-upload-container ${className}`}
                type="file"
                aria-label="File Upload"
                id={name} >

                <div className={`image-upload-display w-full  flex justify-center items-center   ${rounded && 'rounded-full'}`}>
                    {
                        Array.isArray(files)
                            ?
                                files?.map((file, index) => {
                                    return (
                                        <>
                                            <img key={index}
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className={`w-full h-full ${rounded && 'rounded-full'}`}
                                            />
                                        </>
                                    )
                                })
                            : 
                            props.img ? <img src={props.img} alt="profile" className={`w-full h-full ${rounded && 'rounded-full m-3'}`} /> : <Icon />
                    }
                </div>

                <input {...props} {...getInputProps()} />

                <div className='image-upload-info'>
                    {isDragActive ?
                     <p>Drop file here!</p>: <>
                     <h4>{fileRejections[0]?.errors ?'File Rejected' : text}</h4>
                    
                    <p className={`${fileRejections[0]?.errors && 'error'} message`} >
                        Max file size is 500kb. Supported file types are .png and .jpg</p>

                    {/* To be removed */}
                    <button  {...getRootProps()} type="button" className="bg-primary py-2 px-5 text-white">Upload Photo</button></>}

                    {
                        Array.isArray(files) && 
                            <div onClick={handleRemove} className="image-upload-info-name">
                                <p> {(files[0].name)}</p> 
                                <p><AiOutlineClose /></p>
                            </div>
                    }
                </div>

            </div>
        </>
    )
}

export default UploadImage