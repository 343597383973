import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: endpointBuilder => ({
        /**
         *@description fetchBaseQuery endpoint for login. It is a mutated endpoint because of the body parameter. Else it will be a query
         */

         //login mutation
        login: endpointBuilder.mutation({
            query: body => ({ // body will be passed in when "useLoginMutation.login" is called
                url: 'xauth/signin/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Profile']
        }),

        // log out mutation
        logOut:endpointBuilder.mutation({
            query: body => ({
                url:'user/logout/',
                method:'POST',
                body
            }),
        }),

        //reset mutation
        reset: endpointBuilder.mutation({
            query: body  =>({
                url:'user/password_reset/',
                method:'POST',
                body
            })
        }),

        //reset confirm mutation
        changePassword: endpointBuilder.mutation({
            query: body =>({
                url: `user/password_reset_complete/?token=${body.token}`,
                method: 'POST',
                body: body
            }),
        }),

        //
        firstTimePasswordChange: endpointBuilder.mutation({
            query: body =>({
                url: `user/change_password_first_time/`,
                method: 'POST',
                body: body
            }),
        }),

    })
})

// Export the hooks generated by the slice
export const {
    useLoginMutation, useLogOutMutation, useResetMutation,
    useChangePasswordMutation, useFirstTimePasswordChangeMutation} = authApiSlice