import { apiSlice } from "../../app/api/apiSlice";
import { tags } from "../../utils/constants";

const occurrenceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        postDataSet: builder.mutation({
            query: (data) => {
                const { url, ...body } = data
                return ({ 
                    url: `${url}/`,
                    method: 'POST',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
            invalidatesTags: [tags.dataset]
        }),

        updateOccurrenceDataSet: builder.mutation({
            query: (data) => {
                const { url, ...body } = data
                return ({ 
                    url: `${url}/`,
                    method: 'PUT',
                    body,
                    headers: {superadmin: 'superadmin'}
                })
            },
            invalidatesTags: [tags.dataset]
        }),

        loadOccurences: builder.query({
            query: () => ({ 
                url: 'settings/occurrence/type/',
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.dataset]
        }),

        activateDataset: builder.query({
            query: (url) => ({ 
                url: `${url}/`,
                headers: {superadmin: 'superadmin'}
            }),
            invalidatesTags: [tags.dataset]
        }),

        loadData: builder.query({
            query: (url) => ({ 
                url: url,
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: [tags.dataset]
        }),

        loadAircraftData: builder.query({
            query: (url) => ({ 
                url: url,
                headers: {superadmin: 'superadmin'}
            }),
            providesTags: [tags.dataset]
        }),

        postDataSetCSV: builder.mutation({
            query: (data) => {
                const { url, form } = data
                return ({ 
                    url: `${url}/`,
                    method: 'POST',
                    body: form,
                    headers: {superadmin: 'superadmin'}
                })
            },
        }),
    })
})

// Hooks
export const {usePostDataSetMutation, 
    useLoadOccurencesMutation, useLoadDataQuery,
    useUpdateOccurrenceDataSetMutation, usePostDataSetCSVMutation,
    useActivateDatasetQuery, useLoadAircraftDataQuery} = occurrenceApiSlice

