import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useFormContext } from 'react-hook-form';

const FileUpload = ({getInputProps, isDragActive, fileRejections, getRootProps, error, name}) => {

    const {register, watch, reset} = useFormContext()
    const formValues = watch()
    const file = formValues.file

    const removeFile = (e) => {
        e.stopPropagation();
        reset()
    }

    return (
        <>
        <div {...getRootProps()} className='modal-content modal-content'>
            <input {...getInputProps()} />

            {
                !file && <button className='os-btn-blue bg-primary' type='button'>Add file</button>
            }

            {
                file?.name &&
                    <div style={{display:"flex", flexDirection:"column" , justifyContent:"center", alignItems:"center"}}>
                        <div className='modal-content-error'  style={{maxWidth:"max-content"}}>
                            <FileUploadOutlinedIcon />
                            <div>
                                <h5>{file.name}</h5>
                                <p className='small-text'> {` ${(file.size / 1000).toFixed(0)}kb`}</p>
                            </div>
                            <div className='modal-content-error-button cursor-pointer' onClick={removeFile}>
                                <CloseOutlinedIcon />
                            </div>
                        </div>
                        {
                            error && 
                                <p className='small-text-error'>
                                    Opps, error with the selected file format, ensure your file is *.csv format
                                </p>
                        }

                    </div>
            }
        </div>

       </>
    )
}
export default FileUpload