import React from 'react'

import './styles/index.scss'
import Input from '../forms/PassWordInput'
import FormDropdown from '../forms/FormDropdown'
import { useForm, FormProvider } from 'react-hook-form'
import UploadImage from '../uploadphoto/UploadImage'
import './styles/aircraft_form.scss'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import FlightIcon from '@mui/icons-material/Flight'
// import { useNavigate } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from 'react-toastify'
import { useUpdateTemplateImageMutation, useUpdateTemplateMutation } from '../../features/aircraft_template/aircraftApiSlice'
import {AiOutlineHome} from 'react-icons/ai'
import IconBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { HeaderContainer } from '../header/Header.style'
import ButtonContainer from '../ButtonContainer/ButtonContainer'
import { handleError } from '../../utils/error-handler'
import { getProfile } from '../../utils/profile-url'
import { useNavigate } from 'react-router-dom'
import { useUpdateProfileImageMutation } from '../../features/user/userApiSlice'


/**
 * mock data
 * @type {object}
 */
const lists_data = {
    // manufacturer_company_full_name: "Manufacturers' Company Full Name",
    manufacturer: "Manufacturer",
    aircraft_model: 'Model',
    icao_code: 'ICAO Code',
    year_manufactured: 'Year',
    aircraft_type: ['Land Plane', 'Cargo Plane', 'Private Jet'],
    engine_type: ['Turbojet Engine', 'Gama Engine', 'Apha Engine'],
    type: "Type",
    engine_count: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    iata: 'IATA Code',
    // registration_number: 'Registration Number',
    manufacturer_line: "Manufacturer Line Number",
    wave_turbulence_category: ['H(Heavy)', 'M(Medium)', 'L(Light)'],
    description: 'Description'
}

const listKeys = Object.keys(lists_data) //returns an array of lists_data keys

/**
Aircraft creation form
@function
@return {object } jsx
 */

const EditAircraftTemplate = ({ data, setOpen }) => {

    const navigate = useNavigate();

    //initialize post
    const [updateTemplate, { isLoading }] = useUpdateTemplateMutation()
    const [uploadImage, {isLoading: isUploading}] = useUpdateTemplateImageMutation();

    // set initial values for useForm instance
    const initialValues = {
        aircraft_model: '',
        icao_code: '',
        year_manufactured: '',
        aircraft_type: '',
        engine_type: '',
        engine_count: '',
        iata: '',
        registration_number: '',
        wtc: '',
        description: '',
        manufacturer: '',
        manufacturer_line: '',
    }

    //
    const methods = useForm();

    // destructure some methods from useForm hook
    const { register, formState: { errors, dirtyFields }, handleSubmit, setValue } = methods

    // watch for errors and return a boolean for a given input
    const checkErrors = (errs, name) => {
        const errors = Object.keys(errs)
        return errors.find(er => name === er)
    }

    /**
      on Submit function
    @function onSubmit - passed inside handleSubmit() function as a parameter
    @param {object} data - Returned data after validations
     */
    const onSubmit = async (_formData) => {

        try {

            let response;
            if (_formData.model_3d_image) { // upload
                const form = new FormData()
                form.append('model_3d_image', _formData.model_3d_image[0]) 
                response = await uploadImage({ id: data.id, data: form }).unwrap()

                //
                _formData.model_3d_image = response.data.model_3d_image;
            }

            response = await updateTemplate({ id: data.id, data: _formData }).unwrap() 
            toast.success(response.message)
            navigate(-1);
        } 
        catch (error) {
            handleError(error);
        }
    }      
    
    //
    const _data = [
        { name: 'Dashboard', link: '/home', Icon: AiOutlineHome },
        { name: 'Aircraft Template Manager', link: '/aircraft_template', Icon: FlightIcon },
        { name: data.aircraft_model, link: '/', Icon: "" },
    ]

    return (
        <div className='px-3 lg:px-10'>
            <IconBreadcrumbs data={_data} />
            {/* <Heading icon={FlightIcon} title={'Create Aircraft Data'} /> */}
            <HeaderContainer>
                <FlightIcon /> <h2>{`Edit ${data.aircraft_model}`}</h2>
            </HeaderContainer>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='aircraft-form md:flex gap-5'>
                        <section className='aircraft-form-left lg:w-3/5 mb-4'>
                            {listKeys.map(
                                (type, index) => {
                                    return typeof lists_data[type] !== 'object' ? // check if list_data type is not array object
                                        <div key={index} className='form-and-input error'>
                                            <Input
                                                isDirty={dirtyFields[type]}
                                                label={lists_data[type]}
                                                type='text'
                                                name={type}
                                                placeholder={`${type === "aircraft_model" ? "Aircraft Model Number" :
                                                        type === "year_manufactured" ? "Aircraft Manufactured year" :
                                                            "Aircraft" + " " + lists_data[type]}`}
                                                register={register}
                                                error={checkErrors(errors, type)}
                                                defaultValue={data[type]}
                                                borderBottom={data[type] ? true : false}
                                                editActive={true}
                                            />

                                            {
                                                errors ? errors[type] ? <p className='error'>This field is required</p> : '' : ''
                                            }
                                        </div>
                                        :
                                        <div className='form-and-input error' key={index}>
                                            <FormDropdown
                                                isDirty={dirtyFields[type]}
                                                //use type as label; if name is combined with an underscore ("_")
                                                //e.g type = "engine_count"
                                                //split "engine_count"and map the array = engine count
                                                label={type === "wave_turbulence_category" ? "Wave Turbulence Category (WTC)":
                                                type === "type" ? "Aircraft Type" :
                                                type.replaceAll('_', " ")}
                                                type='text'
                                                name={type === "wave_turbulence_category" ? "wtc" : type}
                                                placeholder='Choose an option'
                                                register={register}
                                                error={checkErrors(errors, type)}
                                                getValue={setValue}
                                                data={lists_data[type]}
                                                defaultValue={type === "wave_turbulence_category" ? data.wtc : data[type]}
                                                borderBottom={data[type] ? true : false}
                                                editActive={true}
                                            />
                                            {
                                                errors ? errors[type] ? <p className='error'>This field is required</p> : '' : ''
                                            }
                                        </div>
                                }
                            )}
                        </section>

                        <section className='upload-photo lg:w-2/5 mb-4 ml-auto'>
                            <h4 className='mb-4'>3D Aircraft Model</h4>
                            <UploadImage accept={{ "image/*": ['.png', '.jpg'] }}
                                name="model_3d_image"
                                label="File Upload"
                                className='items-start'
                                icon={InsertPhotoOutlinedIcon}
                                text={'Upload 3D Aircraft Model'}
                                img={getProfile(data?.model_3d_image) || ''}
                            />
                            {
                                errors?.photo && <p className='error'>Please upload a photo</p>
                            }
                        </section>
                    </div>

                    <ButtonContainer>
                        <button type='submit' className='bg-primary px-6 py-3 text-white'>{isLoading ? "Please wait..." : "Save"}</button>
                        <button type='button' className='dt-btn-red text-red-500' onClick={() => setOpen(false)}>Cancel</button>
                    </ButtonContainer>
                </form>
            </FormProvider>
            <ToastContainer />
        </div>
    )
}

export default EditAircraftTemplate