
import image from '../../assets/Airbus_A320_Neo_06 1.png'
import React from 'react'
import FlightIcon from '@mui/icons-material/Flight'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {
    useActivateTemplateModelQuery, useDeactivateTemplateModelQuery,
    useGetAircraftTemplateQuery
} from '../../features/aircraft_template/aircraftApiSlice';
import { setAircraft } from '../../features/aircraft_template/aircraftSlice';
import { Box, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import IconBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { HeaderContainer } from '../header/Header.style'
import ButtonContainer from '../ButtonContainer/ButtonContainer'
import { toast } from 'react-toastify'
import DeactivateDialog from '../Dialog/DataSettings/DeactivateDialog'
import EditAircraftTemplate from './EditAircraftTemplate'
import { handleError } from '../../utils/error-handler'
import { getProfile } from '../../utils/profile-url'

const DisplayAircraftData = () => {

    const { id } = useParams()
    const dispatch = useDispatch()

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)
    const handleClickOpen = () => { setOpen(true) };

    const aircraftData = useSelector(state => state.aircraft.aircraft);
    console.log(aircraftData);

    const { data, isSuccess, isLoading, isError } =
        useGetAircraftTemplateQuery(id, { refetchOnMountOrArgChange: true })

    // set state
    if (isSuccess) dispatch(setAircraft(data?.data))

    const obj = {
        manufacturer: aircraftData?.manufacturer || 'NA',
        aircraft_model: aircraftData?.aircraft_model || 'NA',
        aircraft_type: aircraftData?.type || '',
        year_manufactured: aircraftData?.year_manufactured || 'NA',
        description: aircraftData?.description || 'NA',
        engine_count: aircraftData?.engine_count || 'NA',
        engine_type: aircraftData?.engine_type || 'NA',
        iata: aircraftData?.iata || 'NA',
        icao_code: aircraftData?.icao_code || 'NA',
        //manufacturer_number: aircraftData?.manufacturer_serial_number || "",
        //manufacturer_serial_number: aircraftData?.manufacturer_serial_number || "",
        //manufacturer_line_number: aircraftData?.manufacturer_line_number || "",
        wave_turbulence_category: aircraftData?.wtc || 'NA',
    }

    // Breadcrumbs
    const _data = [
        { name: 'Dashboard', link: '/home', Icon: AiOutlineHome },
        { name: 'Aircraft Template Manager', link: '/aircraft_template', Icon: FlightIcon },
        { name: `${obj.aircraft_model}`, link: '/', Icon: "" },
    ]

    // 
    const [skipActivate, setSkipActivate] = useState(true);
    const [skipDeativate, setSkipDeactivate] = useState(true);

    const { data: activateResponse, error: activateError,
        isLoading: isLoadingActivate } = useActivateTemplateModelQuery(aircraftData?.id, { skip: skipActivate })

    const { data: deactivateResponse, error: deactiveError,
        isLoading: isLoadingDeactivate } = useDeactivateTemplateModelQuery(aircraftData?.id, { skip: skipDeativate })

    // handle deactivate user
    const handleDeactivate = () => {
        setSkipDeactivate(false);
        setOpen(false)
    };

    // handle activate user
    const handleActivate = async () => {
        setSkipActivate(false);
        setOpen(false);
    }

    const _obj = {
        handleClose: handleDeactivate, isLoading: isLoadingDeactivate, open, setOpen,
        title: `Deactivate ${obj?.aircraft_model}`, question: 'Are you sure you want to deactivate this aircraft template data?'
    }
    const _obj2 = {
        handleClose: handleActivate, isLoading: isLoadingActivate,
        open, setOpen, title: `Activate ${obj?.aircraft_model}`, question: 'Are you sure you want to activate this aircraft template data?'
    }

    useEffect(() => {

        // Response
        if (deactiveError) handleError(deactiveError);
        if (deactivateResponse) {
            navigate(-1)
            toast.success(deactivateResponse.message)
        }

        // Response
        if (activateError) handleError(activateError);
        if (activateResponse) {
            navigate(-1);
            toast.success(activateResponse.message);
        }

    }, [activateResponse, deactivateResponse, activateError, deactiveError, navigate])

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-around', height: 400 }}>
                <CircularProgress />
            </Box>
        )
    }

    return (

        open2
            ? <EditAircraftTemplate data={aircraftData} setOpen={setOpen2} />

            : <>

                {
                    aircraftData?.is_active
                        ? <DeactivateDialog {..._obj} deactivateStatus={true} />
                        : <DeactivateDialog {..._obj2} />
                }

                {
                    isSuccess && <div className='px-3 lg:px-10'>
                        <IconBreadcrumbs data={_data} />
                        <HeaderContainer>
                            <FlightIcon />
                            <h2>{obj?.aircraft_model}</h2>
                        </HeaderContainer>

                        <div className='display-aircraft-data'>
                            <div className='display-aircraft-data-wrapper'>

                                <div className='max-w-[800px] mx-auto w-[672px] h-[378px]'>
                                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                    <img src={getProfile(aircraftData?.model_3d_image) || image} alt="aircraft data display image" className='w-full h-full'/>
                                </div>

                                <div className={`text-wrapper ${aircraftData?.is_active ? '' : 'deleted'}`}>
                                    {/** map through the keys of lists_data */}
                                    {Object.keys(obj).map((item, index) => {

                                        // replace all underscores with spaces
                                        let title = item.replaceAll('_', " ")
                                        let cl = "title"
                                        if (title === "iata") {
                                            title = "IATA"
                                            cl = ''
                                        }
                                        else if (title === "icao code") {
                                            title = "ICAO Code"
                                            cl = ''
                                        }
                                        return (
                                            <div className='info' key={index}>
                                                <h3 className={cl}>{title}</h3> <p>{obj[item]}</p>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                        {
                            aircraftData?.is_active
                                ? <ButtonContainer>
                                    <button className='os-btn-blue py-3 px-5' onClick={() => setOpen2(true)}>Edit</button>
                                    <button className='dt-btn-red py-3 px-5' onClick={handleClickOpen}>Deactivate</button>
                                </ButtonContainer>

                                : <ButtonContainer>
                                    <button className='os-btn-blue' onClick={handleClickOpen}>Activate</button>
                                </ButtonContainer>
                        }
                    </div>
                }

                {
                    isError && <div className='m-4 bg-white p-3 shadow-sm'>
                        Error loading model.
                    </div>
                }
            </>
    )
}

export default DisplayAircraftData