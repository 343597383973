import { useState } from 'react'

/**
@function
@description : Helps modify your data by adjusting the list of display data per page
@param {array} data  - Array to be sliced
@param {integer} ipage  - Number of items to be displayed
@returns {object} -  next , prev, currentData, currentPage, maxPage, jump, begin, end
 */
const usePagination = (data, ipage) => {
    const itemsPerPage = parseInt(ipage)
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data?.length / itemsPerPage);


    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
/**
 * @function
 * @description returns sliced data
 * @returns {array}
 */
    const currentData = () => {
        return data?.slice(begin, end);
    }
/**
 * @function
 */
    const next = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }
/**
 * @function
 */
    const prev = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }
/**
 * @function
 */
    const jump = page => {
        const pageNumber = Math.max(1, page);
        setCurrentPage( ()=> Math.min(pageNumber, maxPage));

    }

    return {
        next, prev, currentData, currentPage, maxPage, jump, begin, end
    }
}

export default usePagination;






