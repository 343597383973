
import BackgroundImg from '../../../assets/reset_bg.svg'
import { toast, ToastContainer } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Input from '../../../components/forms/PassWordInput'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import './reset.scss'
import '../login/login.scss'
import ArgueBlue2 from '../../../assets/Logo/ArgueBlue2.svg'
import { useChangePasswordMutation, useFirstTimePasswordChangeMutation } from '../../../features/authentication/authApiSlice'
import { useEffect, useState } from 'react'
import { Background, ErrorMessage, LogoWrapper } from '../login/Login'
import { handleError } from '../../../utils/error-handler'

const ResetPassword = () => {

    //const[ params]= useSearchParams();
  
    const search = useLocation().search;
    const params = new URLSearchParams(search);

    const isChange = params.get('change');

    const [values, setValues] = useState({
        token: '',
        password: '',
    })

    useEffect(() => {
        // const params = new URLSearchParams(window.location.href);
        // console.log(params);
        // const arr = url.split('?')
        // const token = arr[arr.length - 1];
        // console.log(token.token);
        // setValues(prev => ({...prev, token}))
    }, [])

    const navigate = useNavigate()
    const [changePassword, {error, isLoading }] = useChangePasswordMutation()
    const [firstTimeChange, {isLoading: isChanging}] = useFirstTimePasswordChangeMutation();

    const { register, handleSubmit, watch, formState: {errors, dirtyFields} } = useForm({ defaultValues: {
            password: '',
            confirm_password: '',
            old_password: '', // for first time change of password
        }
    });

    // watch password field value
    const watchPassword = watch('password');

    const onSubmit = async (e) => {
        const { password, old_password } = e
        
        try {
            let response;
            if(isChange) {
                response = await firstTimeChange({
                    old_password,
                    new_password: password,
                    email: params.get('email')}).unwrap()
            }
            else {
                response = await changePassword({
                    password,
                    re_password: password,
                    token: params.get('token')}).unwrap()
            }

            toast.success(response.message);
            setTimeout(() => { navigate('/')}, 2000)
        }
         catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='form-container reset'>
                <div className='form-image'>
                    <Background src={BackgroundImg}></Background>
                </div>

                <div className='form-entry'>

                    <LogoWrapper>
                        <img src={ArgueBlue2} alt='Argue Logo' />
                    </LogoWrapper>

                    <div className='wrapper' >
                        <div className='form-wrapper'>
                            <div className='form-wrapper2'>
                                <div className='form-header'>
                                    <h2 className=''>{isChange ? 'Change Password' : 'Reset Password'}</h2>
                                </div>

                                <form className='form-group' onSubmit={handleSubmit(onSubmit)}>

                                    {
                                        isChange &&
                                            <div className='login-input'>
                                                <Input
                                                    label='Old Password'
                                                    type='password'
                                                    name='old_password'
                                                    register={register}
                                                    placeholder='Old Password'
                                                    isDirty={dirtyFields.old_password}
                                                    error={errors?.old_password ? errors?.old_password : error?.status}
                                                />
                                            </div>
                                    }

                                    <div className='login-input'>
                                        <Input
                                            label='New Password'
                                            type='password'
                                            name='password'
                                            register={register}
                                            placeholder='Password'
                                            isDirty={dirtyFields.password}
                                            error={errors?.password ? errors?.password : error?.status}

                                            validate={
                                                {
                                                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
                                                    required: true,
                                                }
                                            }

                                        />
                                        {errors.password && (
                                            <ErrorMessage>
                                                Error with the password, ensure you type a strong password
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <div className='login-input'>
                                        <Input
                                            errors={errors}
                                            label='Confirm Password'
                                            type='password'
                                            name='confirmpassword'
                                            register={register}
                                            placeholder='Confirm Password'
                                            isDirty={dirtyFields.password}
                                            error={errors?.password ? errors?.password : error?.status}

                                            validate={
                                                {
                                                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/,
                                                    required: true,
                                                }
                                            }

                                        />
                                        {/* confirm password */}
                                        {watchPassword !== '' && watchPassword !== watch('confirmpassword') && (
                                            <ErrorMessage>
                                                Error with the password, ensure your new password is the same
                                            </ErrorMessage>
                                        )}

                                    </div>

                                    <div className='login-action'>
                                        <button className='btn-blue' type='submit'>
                                            {isLoading ? "Submitting...":"Confirm"}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )


}

export default ResetPassword